import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { api } from 'lib';

interface Props {
  value: string;
  label?: string;
  onSelect?: (value: string) => void;
};

export const DefaultPriceSelector: React.FC<Props> = ({ value, onSelect, label = '' }: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    if (onSelect) {
      onSelect(event.target.value);
    }
  };

  return (
    <FormControl fullWidth>
      {label !== '' &&
        <InputLabel id="default-price-select-label">{label}</InputLabel>
      }
      <Select
        labelId="default-price-select-label"
        id="default_price"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value="zero">Do not use a default value ($0)</MenuItem>
        <MenuItem value="bricklink_avg">Bricklink Average Price</MenuItem>
        <MenuItem value="bricklink_min">Bricklink Min Price</MenuItem>
        <MenuItem value="bricklink_max">Bricklink Max Price</MenuItem>
      </Select>
    </FormControl>
  );
};

import * as React from 'react';

export enum ForgotPasswordState {
  idle = 1,
  request,
  verify,
  reset,
}

export type TForgotPasswordModalContext = {
  email: string;
  state: ForgotPasswordState;
  verificationCode: string;
  message: string;
  isOpen: boolean;
  hideForgotPassword: (message?: string) => void;
  showForgotPassword: () => void;
  changeState: (state: ForgotPasswordState) => void;
  updateEmail: (email: string) => void;
  updateVerificationCode: (verificationCode: string) => void;
};

interface ProviderProps {
  children: React.ReactNode;
}

export const ForgotPasswordContext = React.createContext<TForgotPasswordModalContext | null>(null);

export const ForgotPasswordProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const [email, setEmail] = React.useState<string>("");
  const [state, setState] = React.useState<ForgotPasswordState>(ForgotPasswordState.idle);
  const [message, setMessage] = React.useState<string>("");
  const [verificationCode, setVerificationCode] = React.useState<string>("");
  const isOpen = React.useMemo(() => state !== ForgotPasswordState.idle, [state]);
  const updateEmail = React.useCallback((email: string) => setEmail(email), []);
  const updateVerificationCode = React.useCallback((verificationCode: string) => setVerificationCode(verificationCode), []);
  const changeState = React.useCallback((newState: ForgotPasswordState) => setState(newState), []);
  const showForgotPassword = React.useCallback(() => setState(ForgotPasswordState.request), []);
  const hideForgotPassword = React.useCallback((msg: string = "") => {
    setEmail("");
    setMessage(msg);
    setState(ForgotPasswordState.idle);
  }, []);

  return (
    <ForgotPasswordContext.Provider value={{
      email: email,
      state: state,
      message: message,
      verificationCode: verificationCode,
      hideForgotPassword: hideForgotPassword,
      showForgotPassword: showForgotPassword,
      changeState: changeState,
      updateEmail: updateEmail,
      updateVerificationCode: updateVerificationCode,
      isOpen: isOpen,
    }}>
      {children}
    </ForgotPasswordContext.Provider>
  );
};
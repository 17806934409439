import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    textAlign: 'right',
    margin: theme.spacing(1, 0),
  },
  show: {
    margin: theme.spacing(2, 0, 16, 0),
    textAlign: 'left',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
  },
  list: {
    '& .externalLink': {
      verticalAlign: 'middle',
    },
  },
  profile: {
    flexGrow: 1,
    marginRight: theme.spacing(4),
  },
  sidebar: {
    minWidth: 200,
    marginLeft: 2,
  },
  input: {
    margin: theme.spacing(3, 0),
  },
  add: {
    margin: theme.spacing(2, 0),
    textAlign: 'left',
    width: '80%',
  },
}));

export default useStyles;
import { StatusCodes } from 'http-status-codes';
import { SessionModel } from 'model';

export type Body = Record<string, any>;

export interface Result<T> {
  status: number;
  body: T;
}

const idle: string = "idle";
const loading: string = "loading";
const success: string = "success";
const error: string = "error";

const logout = async (): Promise<boolean> => {
  const resp = await fetch('/api/users/sign_out', {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id'),
    }
  });
  return resp.ok;
}

const auth = async (email: string, password: string): Promise<SessionModel | null> => {
  const payload = {
    email: email,
    password: password,
  }
  const resp = await fetch('/api/users/sign_in', {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (resp.ok) {
    const token = resp.headers.get('Access-Token');
    const refreshToken = resp.headers.get('Refresh-Token');
    if (!token || !refreshToken) {
      return null;
    }
    const body = await resp.json();
    const user = body.data;
    const payload = JSON.stringify(user);
    return {
      token: token,
      refresh: refreshToken,
      user: payload,
    }
  }
  return null;
}

const get = async (path: string, params: Record<string, string> = {}): Promise<Result<any>> => {
  const url = new URL(window.location.origin + path);
  Object.keys(params).forEach((key: string) => url.searchParams.set(key, params[key]));
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('id'),
    }
  });
  if (resp.ok) {
    return {
      status: resp.status,
      body: await resp.json(),
    }
  } else {
    return parseError(resp);
  }
};

const post = async (path: string, body: Body): Promise<Result<any>> => {
  const resp = await fetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('id'),
    }
  });
  if (resp.ok) {
    return {
      status: resp.status,
      body: await resp.json(),
    }
  } else {
    return parseError(resp);
  }
};

const put = async (path: string, body: Body): Promise<Result<any>> => {
  const resp = await fetch(path, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('id'),
    }
  });
  if (resp.ok) {
    return {
      status: resp.status,
      body: await resp.json(),
    }
  } else {
    return parseError(resp);
  }
};

const patch = async (path: string, body: Body): Promise<Result<any>> => {
  const resp = await fetch(path, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('id'),
    }
  });
  if (resp.ok) {
    return {
      status: resp.status,
      body: await resp.json(),
    }
  } else {
    return parseError(resp);
  }
};

const del = async (path: string): Promise<Result<any>> => {
  const resp = await fetch(path, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('id'),
    }
  });
  if (resp.ok) {
    return {
      status: resp.status,
      body: await resp.json(),
    }
  } else {
    return parseError(resp);
  }
};

const parseError = async (resp: Response): Promise<Result<string>> => {
  if (resp.status === StatusCodes.INTERNAL_SERVER_ERROR) {
    const body = await resp.json();
    return {
      status: StatusCodes.INTERNAL_SERVER_ERROR,
      body: body? body : 'Sorry! An error occurred on our end, please try again.',
    }
  } else if (resp.status === StatusCodes.UNAUTHORIZED) {
    return {
      status: StatusCodes.UNAUTHORIZED,
      body: 'Sorry! This request was not authorized. Please make sure you are logged in correctly!',
    }
  } else if (resp.status === StatusCodes.NOT_FOUND) {
    return {
      status: StatusCodes.NOT_FOUND,
      body: 'Not Found',
    }
  }
  return {
    status: resp.status,
    body: await resp.json()
  }
};

export const api = {
  auth: auth,
  logout: logout,
  get: get,
  post: post,
  put: put,
  patch: patch,
  delete: del,
  idle: idle,
  success: success,
  loading: loading,
  error: error,
};

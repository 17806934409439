import * as React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SeriesMinifigModel } from 'model';
import { TCell } from 'components/tcell';
import { formatPrice } from 'lib';
import { EbayCMFLink } from 'components/ebay_links';

interface Props {
  minifig: SeriesMinifigModel;
}

export const CMFBricklinkPrices: React.FC<Props> = ({ minifig }) => {
  return (
    <>
      <Typography variant='body1' sx={{ mb: 2 }}>
        <strong>Price Guide</strong>
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TCell />
              <TCell align='center' colSpan={2}>
                <strong>eBay (Sold)</strong>
              </TCell>
              <TCell align='center' colSpan={2}>
                <strong>Bricklink (Sold)</strong>
              </TCell>
              <TCell align='center' colSpan={2}>
                <strong>BAM (Cost)</strong>
              </TCell>
            </TableRow>
            <TableRow>
              <TCell align='right'></TCell>
              <TCell align='right'>
                <strong>New</strong>
              </TCell>
              <TCell align='right'>
                <strong>Used</strong>
              </TCell>
              <TCell align='right'>
                <strong>New</strong>
              </TCell>
              <TCell align='right'>
                <strong>Used</strong>
              </TCell>
              <TCell align='right'>
                <strong>New</strong>
              </TCell>
              <TCell align='right'>
                <strong>Used</strong>
              </TCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TCell align='left'>Avg</TCell>
              <TCell align='right'>
                <EbayCMFLink item={minifig} text='New' condition='new' />
              </TCell>
              <TCell align='right'>
                <EbayCMFLink item={minifig} text='Used' condition='used' />
              </TCell>
              <TCell align='right'>
                {formatPrice(minifig.new_bricklink_prices.avg)}
              </TCell>
              <TCell align='right'>
                {formatPrice(minifig.used_bricklink_prices.avg)}
              </TCell>
              <TCell align='right'>N/A</TCell>
              <TCell align='right'>N/A</TCell>
            </TableRow>
            <TableRow>
              <TCell align='left'>Min</TCell>
              <TCell></TCell>
              <TCell></TCell>
              <TCell align='right'>
                {formatPrice(minifig.new_bricklink_prices.min)}
              </TCell>
              <TCell align='right'>
                {formatPrice(minifig.used_bricklink_prices.min)}
              </TCell>
              <TCell align='right'>N/A</TCell>
              <TCell align='right'>N/A</TCell>
            </TableRow>
            <TableRow>
              <TCell align='left'>Max</TCell>
              <TCell></TCell>
              <TCell></TCell>
              <TCell align='right'>
                {formatPrice(minifig.new_bricklink_prices.min)}
              </TCell>
              <TCell align='right'>
                {formatPrice(minifig.used_bricklink_prices.min)}
              </TCell>
              <TCell align='right'>N/A</TCell>
              <TCell align='right'>N/A</TCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

import { createTheme } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import "@mui/styles";
import type { } from '@mui/x-data-grid/themeAugmentation';


declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme { }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#00a3da',
      light: '#ffffff',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#DA1027',
      light: '#ffffff',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#000000',
    }
  },
  typography: {
    fontFamily: 'Arial'
    ,
    h4: {
      "@media print": {
        fontSize: "0.75rem",
      }
    },
    h5: {
      "@media print": {
        fontSize: "0.60rem",
      }
    },
    h6: {
      "@media print": {
        fontSize: "0.60rem",
      }
    },
    body1: {
      "@media print": {
        fontSize: "0.60rem",
      }
    },
    body2: {
      "@media print": {
        fontSize: "0.60rem",
      }
    }
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          }
        },
      }
    },
  }

});

export default theme;
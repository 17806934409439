import * as React from 'react';
import { Grid, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

interface Props {
  page: { index: number; total: number };
  total: number;
  prevPage: () => void;
  nextPage: () => void;
  showTotal: boolean;
}

export const Pagination: React.FC<Props> = ({ page, total, prevPage, nextPage, showTotal }) => {
  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{ textAlign: 'right', fontSize: '0.9em', marginBottom: 0 }}
      >
        <IconButton aria-label="previous" onClick={prevPage} disabled={page.index === 0}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton aria-label="next" onClick={nextPage} disabled={page.index >= page.total - 1}>
          <KeyboardArrowRight />
        </IconButton>
        {page.total > 1 && (
          <>page {page.index + 1} of {page.total}</>
        )}
        {showTotal && (
          <> (Total {total})</>
        )}
      </Grid>
    </>
  );
};


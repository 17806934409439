import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { ErrorBar } from 'components';
import { api } from 'lib';
import { useGetAudit } from 'api/audit';
import { AuditDashboard } from './components';

export const Audit: React.FC = () => {
  const { id } = useParams();
  const { status, error, audit, get } = useGetAudit();

  React.useEffect(() => {
    if (id) {
      get(id);
    }
  }, [id, get]);

  // The Audit Dashboard should be wrapped in a Audit Context that handles the api requests to avoid prop-drilling
  if (status === api.success && audit) {
    return <AuditDashboard audit={audit} />;
  } else if (status === api.loading) {
    return <LinearProgress />;
  } else if (status === api.error) {
    return <ErrorBar error={error} />;
  } else {
    return <></>;
  }
};

import * as React from 'react';
import { Box } from '@mui/material';
import { CMFBricklinkPrices, CMFRecentBuys } from 'components';
import { CMFContext, TCMFContext } from '../context';

export const PriceGuide: React.FC = () => {
  const { minifig } = React.useContext(CMFContext) as TCMFContext;

  return (
    <Box>
      <Box sx={{ mb: 4, width: '80%', mx: 'auto', textAlign: 'left' }}>
        <CMFBricklinkPrices minifig={minifig} />
      </Box>
      <Box sx={{ mb: 4, width: '80%', mx: 'auto', textAlign: 'left' }}>
        <CMFRecentBuys minifig={minifig} maxHeight={800} />
      </Box>
    </Box>
  );
};

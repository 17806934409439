import * as React from "react";
import { api } from "lib";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  msg: string;
  vendors: any[];
  total: number;
}

interface Props {
  getTradeVendors: State & { list: (id: number) => void; };
}

const defaultState = {
  status: api.idle,
  msg: '',
  vendors: [],
  total: 0,
};

export const useGetTradeVendors = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const list = React.useCallback(async (id: number) => {
    setState(prevState => ({ ...prevState, status: api.loading }));
    const resp = await api.get(`/api/heartlands/${id}/vendors/trade`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, msg: '', vendors: resp.body.vendors, total: resp.body.total });
    } else {
      setState({ status: api.error, msg: resp.body, total: 0, vendors: [] });
    }
  }, []);

  return {
    getTradeVendors: {
      ...state,
      list,
    }
  };
};


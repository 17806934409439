import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip,
  DialogContent,
} from '@mui/material';

import { BuyContext, TBuyContext } from 'contexts';
import { BuyModel } from 'model';
import { MakeEditSection, ReviewEditSection } from '../edit';
import { useSaveBuy } from 'api';

interface Props {
  open: boolean;
  onClose: (resetBuy: BuyModel) => void;
}

const sanatizeField = (
  key: string,
  value: string | number
): string | number => {
  if (key === 'cash_paid' || key === 'credit_paid') {
    return `$${value}`;
  }
  if (key === 'buy_type' && value === 'trade') {
    return 'store credit';
  } else {
    return value;
  }
};

const createEditNote = (
  previousBuy: BuyModel,
  currentBuy: BuyModel,
  editComment: string,
  editHistory: object
) => {
  const noteList: string[] = [];
  Object.keys(editHistory).forEach((key) => {
    const typedKey = key as keyof BuyModel;

    if (previousBuy[typedKey] !== currentBuy[typedKey]) {
      const keyString = key.replaceAll('_', ' ');
      noteList.push(
        `${keyString} from ${sanatizeField(typedKey, previousBuy[typedKey] as string | number)} to ${sanatizeField(typedKey, currentBuy[typedKey] as string | number)}`
      );
    }
  });

  return `${editComment}; Changed fields: ${noteList.join(', ')}.`;
};

export const EditBuyModal: React.FC<Props> = (props: Props) => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;
  const { editComplete } = useSaveBuy();

  const [prevBuy, setPrevBuy] = React.useState<BuyModel>(buy);
  const [editComment, setEditComment] = React.useState<string>('');
  const [editHistory, setEditHistory] = React.useState({});
  const [review, setReview] = React.useState<boolean>(false);

  React.useEffect(() => {
    setPrevBuy(structuredClone(buy));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const closeModal = () => {
    props.onClose(prevBuy);
    setReview(false);
    setEditHistory({});
    setEditComment('');
  };

  const saveChanges = async () => {
    const note: string = createEditNote(prevBuy, buy, editComment, editHistory);
    await editComplete(buy, note);
    window.location.reload();
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={closeModal}
        maxWidth={'lg'}
        fullWidth
        disableRestoreFocus
        scroll='paper'
      >
        <DialogTitle>Edit Buy</DialogTitle>
        <DialogContent>
          {!review ? (
            <MakeEditSection
              prevBuy={prevBuy}
              editReason={editComment}
              setEditReason={setEditComment}
              editTracker={editHistory}
              setEditTracker={setEditHistory}
            />
          ) : (
            <ReviewEditSection prevBuy={prevBuy} editedBuy={buy} />
          )}
        </DialogContent>
        <DialogActions>
          {!review ? (
            <Tooltip
              title={
                !(
                  Object.keys(editHistory).length !== 0 &&
                  editComment.length !== 0
                )
                  ? 'Please edit a field and enter a reason for change.'
                  : 'Review changes to buy.'
              }
            >
              <span>
                <Button
                  variant='contained'
                  disabled={
                    !(
                      Object.keys(editHistory).length !== 0 &&
                      editComment.length !== 0
                    )
                  }
                  onClick={() => {
                    setReview(true);
                  }}
                >
                  Review Changes
                </Button>
              </span>
            </Tooltip>
          ) : (
            <>
              <Button variant='contained' onClick={() => setReview(false)}>
                Back
              </Button>
              <Button variant='contained' onClick={saveChanges}>
                Save
              </Button>
            </>
          )}
          <Button variant='contained' onClick={closeModal}>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import * as React from 'react';
import { api } from 'lib';
import { RewardsSignupFormModel } from 'model';
import { StatusCodes } from 'http-status-codes';

interface Props {
  status: string;
  error: string;
  rewardsSignupForm: RewardsSignupFormModel | null;
  update: (rewardsSignupForm: RewardsSignupFormModel) => void;
}

export const useUpdateRewardsSignupForm = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [rewardsSignupForm, setRewardsSignupForm] =
    React.useState<RewardsSignupFormModel | null>(null);

  const update = React.useCallback(
    async (updatedRewardsSignupForm: RewardsSignupFormModel) => {
      setStatus(api.loading);
      const { id, ...payloadHash } = updatedRewardsSignupForm;
      console.log(payloadHash);
      const payload = {
        rewards_signup_form: payloadHash,
      };
      let resp = await api.put(
        `/api/rewards_signup_forms/${updatedRewardsSignupForm.id}`,
        payload
      );
      if (id === 0) {
        resp = await api.post(`/api/rewards_signup_forms/`, payload);
      }
      if (resp.status === StatusCodes.OK) {
        setRewardsSignupForm(resp.body);
        setStatus(api.success);
      } else {
        setRewardsSignupForm(null);
        setError(resp.body.error);
        setStatus(api.error);
      }
    },
    []
  );

  return {
    status,
    error,
    rewardsSignupForm,
    update,
  };
};

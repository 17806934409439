import * as React from "react";
import { api } from "lib";
import { StatusCodes } from "http-status-codes";
import { UnitsHeartlandLocationModel } from "model";

interface State {
  status: string;
  error: string;
}

interface Props {
  updateHeartlandLocations: State & {
    update: (heartlandId: number, locations: UnitsHeartlandLocationModel[]) => void,
  }
}

const defaultState = {
  status: api.idle,
  error: '',
}

export const useUpdateHeartlandLocations = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const update = React.useCallback(async (heartlandId: number, locations: UnitsHeartlandLocationModel[]) => {
    setState({ status: api.loading, error: '' });
    const payload = {
      locations: locations.map((l) => ({
        id: l.id,
        unit_id: l.unit_id
      }))
    };
    const resp = await api.put(`/api/heartlands/${heartlandId}/locations`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, error: '' });
    } else {
      setState({ status: api.error, error: resp.body });
    }
  }, []);

  return {
    updateHeartlandLocations: {
      ...state,
      update
    }
  };
};
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Box, Divider, Paper } from '@mui/material';

import { BuyContext, TBuyContext } from 'contexts';

const ItemWrapper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
}));

interface ItemProps {
  qty: number;
  description: string;
}

const Item: React.FC<ItemProps> = ({ qty, description }: ItemProps) => {
  return (
    <ItemWrapper>
      <Grid container spacing={1}>
        <Grid item xs={1.5}>
          {qty}x
        </Grid>
        <Grid item xs={10.5} sx={{ textAlign: 'left' }}>
          {description}
        </Grid>
      </Grid>
    </ItemWrapper>
  );
};

export const ItemsList: React.FC = () => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ my: 1 }}>
            {' '}
            New Sets
          </Typography>
        </Grid>
        {buy.new_set_buy_lines.map((line) => (
          <Grid item xs={4} key={line.id}>
            <Item
              qty={line.quantity}
              description={`${line.lego_set.set_number} ${line.lego_set.name}`}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box sx={{ px: 2, textAlign: 'right' }}>
            <strong>Total Items:</strong> {buy.total_nib_qty}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ my: 1 }}>
            {' '}
            Used Sets
          </Typography>
        </Grid>
        {buy.used_set_buy_lines.map((line) => (
          <Grid item xs={4} key={line.id}>
            <Item
              qty={line.quantity}
              description={`${line.lego_set.set_number} ${line.lego_set.name}`}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box sx={{ px: 2, textAlign: 'right' }}>
            <strong>Total Items:</strong> {buy.total_used_qty}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ my: 1 }}>
            Minifigs
          </Typography>
        </Grid>
        {buy.minifig_buy_lines.map((line) => (
          <Grid item xs={4} key={line.id}>
            <Item
              qty={line.quantity}
              description={`${line.description} (${line.condition})`}
            />
          </Grid>
        ))}
        {buy.series_minifig_buy_lines.map((line) => (
          <Grid item xs={4} key={line.id}>
            <Item
              qty={line.quantity}
              description={`${line.series_minifig.bricklink_id}: ${line.series_minifig.name} (${line.condition})`}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box sx={{ px: 2, textAlign: 'right' }}>
            <strong>Total Items:</strong>{' '}
            {buy.total_minifig_qty + buy.total_cmf_qty}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
};

import * as React from 'react';
import { debounce } from "lodash";
import { TextField } from '@mui/material';

interface Props {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  required?: boolean;
}

export const NotesInput: React.FC<Props> = (props: Props) => {
  const [value, setValue] = React.useState(props.value);
  const doUpdate = React.useMemo(() => debounce(props.onChange, 500), [props.onChange]);

  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    doUpdate(e.target.value);
  };

  return (
    <>
      <TextField
        required={props.required}
        fullWidth
        sx={{ width: 300 }}
        size="small"
        value={value}
        label={props.label ? props.label : "Notes"}
        onChange={handleUpdate}
      />
    </>
  );
};
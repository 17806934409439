import * as React from 'react';

import { useUpdateMinifig, useAddMinifigImage } from 'api';
import { api } from 'lib';
import { MinifigModel } from 'model';

interface State {
  minifig: MinifigModel;
  updateStatus: { status: string; msg: string };
}

export type TMinifigContext = State & {
  update: (key: string, value: string | number | boolean) => void;
  save: () => void;
  imgUpdate: (url: string, minifigId: number, type: string) => void;
};

interface MinifigContextProps {
  children: React.ReactNode;
  minifig: MinifigModel;
  onSave?: (success: boolean, msg: string) => void;
}

export const MinifigContext = React.createContext<TMinifigContext | null>(null);

export const MinifigProvider: React.FC<MinifigContextProps> = (props) => {
  const [state, setState] = React.useState<State>({
    minifig: props.minifig,
    updateStatus: { status: api.idle, msg: '' },
  });
  const {
    status: updateStatus,
    minifig: updatedMinifig,
    message: updateMessage,
    updateMinifig,
  } = useUpdateMinifig();

  const {
    status: imgStatus,
    image: newImageUrl,
    updateImage,
  } = useAddMinifigImage();

  const update = (key: string, value: string | number | boolean) => {
    setState({ ...state, minifig: { ...state.minifig, [key]: value } });
  };

  const save = () => {
    updateMinifig(state.minifig);
  };

  React.useEffect(() => {
    if (updateStatus === api.success && updatedMinifig) {
      setState({
        updateStatus: { status: api.success, msg: '' },
        minifig: updatedMinifig,
      });
      if (props.onSave) {
        props.onSave(true, 'Saved');
      }
    } else if (updateStatus === api.error) {
      setState({
        ...state,
        updateStatus: { status: api.error, msg: updateMessage },
      });
      if (props.onSave) {
        props.onSave(false, updateMessage);
      }
    }
  }, [updatedMinifig]);

  const imgUpdate = (imageUrl: string, id: number, type: string) => {
    setState({
      updateStatus: state.updateStatus,
      minifig: { ...state.minifig, rebrickable_image_url: imageUrl },
    });
    updateImage(imageUrl, id, type);
  };

  React.useEffect(() => {
    if (imgStatus == api.success && newImageUrl) {
      setState({
        updateStatus: { status: api.success, msg: '' },
        minifig: { ...state.minifig, rebrickable_image_url: newImageUrl },
      });
      if (props.onSave) {
        props.onSave(true, 'Saved');
      }
    } else if (updateStatus === api.error) {
      setState({
        ...state,
        updateStatus: { status: api.error, msg: updateMessage },
      });
      if (props.onSave) {
        props.onSave(false, updateMessage);
      }
    }
  }, [newImageUrl]);

  return (
    <MinifigContext.Provider
      value={{
        minifig: state.minifig,
        updateStatus: state.updateStatus,
        update,
        save,
        imgUpdate,
      }}
    >
      {props.children}
    </MinifigContext.Provider>
  );
};

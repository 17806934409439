import * as React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { BulkPriceBand } from 'model';

export interface BulkPriceBandEntryProps {
  priceBand: BulkPriceBand;
  updatePriceBand(property: string, value: number | string | boolean): void;
}

export const BulkPriceBandEntry: React.FC<BulkPriceBandEntryProps> = ({ priceBand, updatePriceBand }: BulkPriceBandEntryProps) => {
  const update = (prop: string, value: number | string | boolean) => {
    updatePriceBand(prop, value);
  };

  return (
    <>
      <Grid item xs={4.3}>
        <TextField
          size="small"
          fullWidth
          value={priceBand.name}
          onChange={(e) => update('name', e.target.value)} />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Gallons"
          size="small"
          fullWidth
          value={priceBand.volume}
          onChange={(e) => update('volume', e.target.value)} />
      </Grid>
      <Box width="100%" />
    </>
  );
}; 

import * as React from 'react';
import { Button, ButtonGroup, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { BulkPriceBand } from 'model';
import { QuantityInput } from 'components';

interface Props {
  bands: BulkPriceBand[];
  onAddLoose: (gallons: number) => void;
  onAddBand: (band: BulkPriceBand) => void;
  onCancel: () => void;
}

export const BulkSelector: React.FC<Props> = ({ bands, onAddBand, onAddLoose, onCancel }: Props) => {

  const [gallons, setGallons] = React.useState<number>(0);

  const addLoose = () => {
    onAddLoose(gallons);
  };

  const selectBand = (band: BulkPriceBand) => {
    onAddBand(band);
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12} md="auto">
        <h4>Add New:</h4>
      </Grid>
      <Grid item xs={12} md="auto">
        <QuantityInput label="Gallons" width={90} onChange={setGallons} quantity={gallons} decimal /> gal
        <Button onClick={addLoose}>Add</Button>
      </Grid>
      <Grid item xs={12} md="auto">
        <ButtonGroup variant="contained" aria-label="bulk selector">
          {bands.map((band) =>
            <Button key={band.id} onClick={() => selectBand(band)}>{band.name} ({band.volume}gal)</Button>
          )}
        </ButtonGroup>
      </Grid>
      <Grid item xs={1} md={1}>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
import * as React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props {
  value: string;
  onSelect?: (value: string) => void;
}

export const CompletenessSelector: React.FC<Props> = ({ value, onSelect }: Props) => {

  const handleChange = (event: SelectChangeEvent) => {
    if (onSelect) {
      onSelect(event.target.value);
    }
  };

  return (
    <FormControl sx={{ minWidth: 125 }} size="small">
      <Select
        id="completeness"
        value={value}
        onChange={handleChange}
        sx={{ padding: 0, margin: 0, fontSize: '0.8rem' }}
      >
        <MenuItem value="default">Default</MenuItem>
        <MenuItem value="ready_to_sell">Ready to sell</MenuItem>
        <MenuItem value="minor_work_needed">Minor work</MenuItem>
        <MenuItem value="partial_build">Partial build</MenuItem>
        <MenuItem value="unbuilt">Unbuilt</MenuItem>
      </Select>
    </FormControl>
  );
};

import * as React from 'react';
import { Box } from "@mui/material"
import { ReactNode } from "react"

interface Props {
    children: ReactNode
}

export const StrikeThrough: React.FC<Props> = (props: Props) => {
  return (
    <Box component="span" sx={{color: 'red', pl: .5, textDecoration: 'line-through'}}> 
      {props.children}
    </Box>
  )
}
import * as React from "react";
import { api } from "lib";
import { LegoSetModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  message: string;
  legoSet: LegoSetModel | null;
}

interface Props {
  updateLegoSet: State & { update: (legoSet: LegoSetModel) => void };
}

const defaultState = {
  status: api.idle,
  message: "",
  legoSet: null,
}

export const useUpdateLegoSet = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const update = React.useCallback(async (legoSet: LegoSetModel) => {
    setState({ status: api.loading, message: '', legoSet: null });
    const payload = {
      lego_set: {
        description: legoSet.description,
        pieces: legoSet.num_parts,
        num_minifigs: legoSet.num_minifigs,
        release_year: legoSet.release_year,
        retirement_year: legoSet.retirement_year,
        retired: legoSet.retired,
        bricklink_id: legoSet.bricklink_id,
        brickset_id: legoSet.brickset_id,
        brickowl_id: legoSet.brickowl_id,
        rebrickable_id: legoSet.rebrickable_id,
      }
    };
    const resp = await api.put(`/api/lego_sets/${legoSet.id}`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, legoSet: resp.body, message: '' });
    } else {
      setState({ status: api.error, legoSet: null, message: resp.body });
    }
  }, []);

  return {
    updateLegoSet: { ...state, update }
  };
};


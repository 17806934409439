import {
  accountFormDescriptionStyle,
  accountFormRowStyle,
  accountFormStyles,
  useAccountFormStyles
} from "../../components/styles";

export const style = accountFormStyles;

export const rowStyle = accountFormRowStyle;

export const descriptionStyle = accountFormDescriptionStyle;

export const useStyles = useAccountFormStyles;

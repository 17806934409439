import * as React from "react";
import { FormControlLabel, Typography, Checkbox as MUICheckbox } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

interface Props {
  label: string;
  variant?: Variant;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

export const Checkbox = ({ label, onChange, checked, variant = "caption" }: Props) => {
  return (<FormControlLabel
    label={<Typography variant={variant} sx={{ pl: 0.5 }} color={variant === "caption" ? "textSecondary" : "textPrimary"} > {label}</ Typography>}
    sx={{ pl: 1 }}
    control={<MUICheckbox checked={checked} size="small" sx={{ p: 0 }} onChange={onChange} />}
  />);

}
import * as React from "react";

import { LegoSetImageModel, LegoSetModel } from "model";
import { useUpdateLegoSet } from "api";
import { api } from "lib";

interface State {
  legoSet: LegoSetModel;
  updateStatus: { status: string, msg: string };
}

export type TLegoSetContext = State & {
  update: (key: string, value: string | number | boolean) => void,
  save: () => void;
  addImage: (legoSetImage: LegoSetImageModel) => void
}

interface LegoSetContextProps {
  children: React.ReactNode;
  legoSet: LegoSetModel;
  onSave?: (success: boolean, msg: string) => void;
  onAddImage?: (success: boolean, msg: string) => void;
}

export const LegoSetContext = React.createContext<TLegoSetContext | null>(null);

export const LegoSetProvider: React.FC<LegoSetContextProps> = (props: LegoSetContextProps) => {
  const [state, setState] = React.useState<State>({ legoSet: props.legoSet, updateStatus: { status: api.idle, msg: '' } });
  const { updateLegoSet } = useUpdateLegoSet();

  const update = (key: string, value: string | number | boolean) => {
    setState({ ...state, legoSet: { ...state.legoSet, [key]: value } });
  };

  const save = () => {
    updateLegoSet.update(state.legoSet);
  };

  React.useEffect(() => {
    if (updateLegoSet.status === api.success && updateLegoSet.legoSet) {
      setState({ updateStatus: { status: api.success, msg: '' }, legoSet: updateLegoSet.legoSet });
      if (props.onSave) {
        props.onSave(true, "Saved");
      }
    } else if (updateLegoSet.status === api.error) {
      setState({ ...state, updateStatus: { status: api.error, msg: updateLegoSet.message } });
      if (props.onSave) {
        props.onSave(false, updateLegoSet.message);
      }
    }
  }, [updateLegoSet]);

  const addImage = (legoSetImage: LegoSetImageModel) => {
    const currentLegoSet = { ...state.legoSet };
    if (legoSetImage.condition === "new") {
      setState({ ...state, legoSet: { ...currentLegoSet, new_images: [...currentLegoSet.new_images, legoSetImage] } });
    } else {
      setState({ ...state, legoSet: { ...currentLegoSet, used_images: [...currentLegoSet.used_images, legoSetImage] } });
    }
  };

  return (
    <LegoSetContext.Provider value={{
      legoSet: state.legoSet,
      updateStatus: state.updateStatus,
      update,
      save,
      addImage,
    }}>
      {props.children}
    </LegoSetContext.Provider >
  );
};
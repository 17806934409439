import * as React from "react";
import { api } from "lib";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  sync: (heartlandId: number) => void;
}
export const useSyncCustomFields = (): Props => {
  const [status, setStatus] = React.useState<string>(api.idle);
  const [error, setError] = React.useState<string>("");

  const sync = React.useCallback(async (heartlandId: number) => {
    setStatus(api.loading);
    const resp = await api.get(`/api/heartlands/${heartlandId}/sync_custom_fields`);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
    } else {
      setStatus(api.error);
      setError(resp.body);
    }
  }, []);

  return {
    status,
    error,
    sync
  };
};
import * as React from 'react';
import {
  Grid,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  LinearProgress,
} from '@mui/material';

import { CurrencyInput, ErrorBar } from 'components';
import {
  formatCurrency,
  calcMargin,
  formatDate,
  SessionContext,
  TSessionContext,
  api,
} from 'lib';
import { BuyContext, TBuyContext } from 'contexts';
import { BuyModel } from 'model';
import { useLoadBuySettings } from 'api';

interface DataFieldProps {
  children: React.ReactNode;
  label: string;
}

const DataField: React.FC<DataFieldProps> = ({ label, children }) => {
  return (
    <>
      <Box component='span' sx={{ fontWeight: 700 }}>
        {label}
      </Box>{' '}
      <br /> {children}
    </>
  );
};

interface Props {
  editReason: string;
  setEditReason: (editReason: string) => void;
  editTracker: object;
  setEditTracker: (editTracker: object) => void;
  prevBuy: BuyModel;
}

export const MakeEditSection: React.FC<Props> = (props) => {
  const { buy, updateCashType, updateBuyType, updatePaidValue } =
    React.useContext(BuyContext) as TBuyContext;
  const { load, settings, status, error } = useLoadBuySettings();
  const { isHeartlandLive, currentUnit, currentUser } = React.useContext(
    SessionContext
  ) as TSessionContext;

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    }
  }, [currentUnit, currentUser, load]);

  if (status === api.success && currentUser && currentUnit) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container sx={{ ml: 2 }}>
            <Grid item xs={3}>
              <DataField label='ID:'> #{buy.id}</DataField>
            </Grid>
            <Grid item xs={3}>
              <DataField label='Completed On:'>
                {buy.updated_at && formatDate(buy.updated_at)}
              </DataField>
            </Grid>
            <Grid item xs={3}>
              <DataField label='By:'>
                {buy.creator.first_name} {buy.creator.last_name}
              </DataField>
            </Grid>
            <Grid item xs={3}>
              <DataField label='Customer:'>
                {buy.customer?.first_name} {buy.customer?.last_name}
              </DataField>
            </Grid>
          </Grid>
          <Grid sx={{ my: 2 }}>
            <Divider />
          </Grid>
          <Grid sx={{ fontStyle: 'italic', mb: 1 }}>Change From:</Grid>
          <Grid container sx={{ ml: 2 }}>
            <Grid item xs={3}>
              <DataField label='Buy Type:'>
                {props.prevBuy.buy_type === 'trade' ? 'Store Credit' : 'Cash'}
              </DataField>
            </Grid>
            <Grid item xs={3}>
              <DataField label='Amount Paid:'>
                {props.prevBuy.buy_type === 'trade'
                  ? formatCurrency(props.prevBuy.credit_paid)
                  : formatCurrency(props.prevBuy.cash_paid)}
              </DataField>
            </Grid>
            {props.prevBuy.buy_type === 'cash' && (
              <>
                <Grid item xs={3}>
                  <DataField label='Cash Type:'>
                    {props.prevBuy.cash_type}
                  </DataField>
                </Grid>
                <Grid item xs={3}>
                  <DataField label='Cash Reference:'>
                    {props.prevBuy.cash_reference}
                  </DataField>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid xs={12} sx={{ my: 2 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ fontStyle: 'italic', mb: 1 }}>
          Change To:
        </Grid>
        <Grid item xs={3} sx={{ ml: 2 }}>
          <FormControl sx={{ width: 240 }}>
            <InputLabel id='buy-type-label'>Buy Type</InputLabel>
            <Select
              labelId='buy-type-label'
              id='buy-type'
              value={buy.buy_type}
              label='Buy Type'
              onChange={(e) => {
                updateBuyType(e.target.value);
                props.setEditTracker({
                  ...props.editTracker,
                  buy_type: e.target.value,
                });
              }}
            >
              {isHeartlandLive() && (
                <MenuItem value='trade'>Store Credit</MenuItem>
              )}
              <MenuItem value='cash'>Cash</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          {buy.buy_type === 'cash' && (
            <CurrencyInput
              label='Amount Paid'
              size='medium'
              width={240}
              value={buy.cash_paid}
              helperText={`Non-Bulk:${calcMargin(
                buy.cash_paid - buy.bulk_cash_paid,
                buy.total_retail
              )} Bulk:${calcMargin(buy.bulk_cash_paid, buy.total_bulk_value)}`}
              onChange={(e) => {
                updatePaidValue('cash', parseFloat(e.target.value));
                props.setEditTracker({
                  ...props.editTracker,
                  cash_paid: e.target.value,
                });
              }}
            />
          )}
          {buy.buy_type === 'trade' && (
            <CurrencyInput
              label='Amount Paid'
              size='medium'
              width={240}
              value={buy.credit_paid}
              helperText={`Non-Bulk:${calcMargin(
                buy.credit_paid - buy.bulk_credit_paid,
                buy.total_retail
              )} Bulk:${calcMargin(
                buy.bulk_credit_paid,
                buy.total_bulk_value
              )}`}
              onChange={(e) => {
                updatePaidValue('trade', parseFloat(e.target.value));
                props.setEditTracker({
                  ...props.editTracker,
                  credit_paid: e.target.value,
                });
              }}
            />
          )}
        </Grid>
        {settings.settings && buy.buy_type === 'cash' && (
          <>
            <Grid item xs={3}>
              <FormControl sx={{ width: 240 }}>
                <InputLabel id='payment-type-select-label'>
                  Payment Type
                </InputLabel>
                <Select
                  labelId='payment-type-select-label'
                  id='payment-type'
                  value={buy.cash_type}
                  label='Payment Type'
                  onChange={(e) => {
                    updateCashType(e.target.value, buy.cash_reference);
                    props.setEditTracker({
                      ...props.editTracker,
                      buy_type: e.target.value,
                    });
                  }}
                >
                  {settings.settings &&
                    settings.settings.cash_types
                      .split(',')
                      .map((cashType: string) => (
                        <MenuItem key={cashType} value={cashType}>
                          {cashType}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label='Payment Reference'
                value={buy.cash_reference}
                onChange={(e) => {
                  updateCashType(buy.cash_type, e.target.value);
                  props.setEditTracker({
                    ...props.editTracker,
                    cash_type: e.target.value,
                  });
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label='Reason For Change'
            value={props.editReason}
            multiline
            onChange={(e) => props.setEditReason(e.target.value)}
          />
        </Grid>
      </Grid>
    );
  } else if (status === api.loading || status === api.idle) {
    return <LinearProgress />;
  } else if (status === api.error) {
    return <ErrorBar error={error} />;
  } else {
    if (buy) {
      return <>Sorry this buy is currently unavailable to view</>;
    }
    return <>Sorry you do not have access to create a new buy</>;
  }
};

import * as React from 'react';
import { debounce } from 'lodash';
import { Grid, LinearProgress } from '@mui/material';
import { useCMFSearch } from 'api';
import { api } from 'lib';
import { SearchThumbnail, CardModal, Pagination } from 'components';
import { SeriesMinifigModel } from 'model';
import { CMFCard } from '../cmf_card';

interface Props {
  query: string;
  onClick?: (payload: SeriesMinifigModel) => void;
  onCheck?: (payload: SeriesMinifigModel, checked: boolean) => void;
  size?: number;
  maxResults?: number;
}

export const CMFSearch: React.FC<Props> = ({
  query,
  onClick,
  onCheck,
  maxResults,
  size,
}: Props) => {
  const { search, status, total, minifigs } = useCMFSearch();
  const [page, setPage] = React.useState({ index: 0, total: 0 });
  const [preview, setPreview] = React.useState<SeriesMinifigModel | null>(null);
  const handleOpen = (minifig: SeriesMinifigModel) => setPreview(minifig);
  const handleClose = () => setPreview(null);
  const doSearch = React.useMemo(() => debounce(search, 500), [search]);
  const finalMaxResults  = React.useMemo(
    () => (maxResults ? maxResults : 18),
    [maxResults]
  );

  React.useEffect(() => {
    setPage((prevPage) => ({ ...prevPage, index: 0 }));
  }, [query]);

  React.useEffect(() => {
    if (query.length >= 3) {
      doSearch(query, page.index, finalMaxResults );

    }
  }, [query, page.index, doSearch, finalMaxResults]);

  // Only update page total when total or maxResults changes
  React.useEffect(() => {
    setPage((prevPage) => ({ ...prevPage, total: Math.ceil(total / (finalMaxResults) ) }));
  }, [total, finalMaxResults]);

  const nextPage = () => {
    const newIndex = page.index + 1;
    setPage({
      index: newIndex > page.total - 1 ? page.total - 1 : newIndex,
      total: page.total,
    });
  };
  const prevPage = () => {
    const newIndex = page.index - 1;
    setPage({ index: newIndex < 0 ? 0 : newIndex, total: page.total });
  };

  if (status === api.loading) {
    return (
      <div style={{ margin: '32px 0'}}>
        <LinearProgress />
      </div>
    );
  } else if (status === api.success) {
    return (
      <>
        <Grid container alignItems='center' spacing={1}>
          <Pagination
            page={page}
            total={page.total}
            prevPage={prevPage}
            nextPage={nextPage}
            showTotal={true}
          />
          {minifigs &&
            minifigs.map((minifig) => (
              <Grid item xs={12} md={2} key={minifig.id}>
                <div onClick={() => handleOpen(minifig)}>
                  <SearchThumbnail
                    imageUrl={minifig.rebrickable_image_url}
                    imageTitle={minifig.name}
                    payload={minifig}
                    onClick={onClick}
                    onCheck={onCheck}
                    height={size ? size : 120}
                    width={size ? size : 120}
                  >
                    {minifig.bricklink_id} - {minifig.name}
                  </SearchThumbnail>
                </div>
              </Grid>
            ))}
        </Grid>
        <Grid container alignItems='center' spacing={1} sx={{ marginTop: 1 }}>
          <Pagination
            page={page}
            total={page.total}
            prevPage={prevPage}
            nextPage={nextPage}
            showTotal={true}
          />
        </Grid>
        <CardModal open={preview !== null} onClose={handleClose}>
          <CMFCard minifig={preview} />
        </CardModal>
      </>
    );
  } else {
    return <></>;
  }
};

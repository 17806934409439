import * as React from "react";
import { api } from "../../lib";
import { StatusCodes } from "http-status-codes";

export type OnboardLocationInput = {
  user: {
    id?: number;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
  };
  heartland: {
    id?: number;
    domain: string;
    api_key: string;
  };
  rewards_signup_form: {
    id?: number;
    short_code: string;
  };
  unit: {
    id?: number;
    name: string;
    franchise_id: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
    phone: string;
    email: string;
  }
};

export type OnboardLocationFormGroup = keyof OnboardLocationInput;

export type OnboardLocationFormField = {
  [K in OnboardLocationFormGroup]: keyof OnboardLocationInput[K];
}[OnboardLocationFormGroup];


export type OnboardLocationError = { user: string[], heartland: string[], unit: string[], sync: string[] };

interface State {
  status: string;
  error: OnboardLocationError;
  onboardingData: OnboardLocationInput | null;
  message?: string;
}

interface Props {
  onboardLocation: State;
  add: (onboardingData: OnboardLocationInput) => void;
}

const ONBOARDING_EMPTY_ERROR = {
  user: [],
  heartland: [],
  unit: [],
  sync: []
};


const idleState = {
  status: api.idle,
  error: ONBOARDING_EMPTY_ERROR,
  onboardingData: null,
};


const loadingState = {
  status: api.idle,
  error: ONBOARDING_EMPTY_ERROR,
  onboardingData: null,
};

export const useOnboardLocation = (): Props => {
  const [state, setState] = React.useState<State>(idleState);

  const add = React.useCallback(async (onboardingData: OnboardLocationInput) => {
    setState(loadingState);
    const resp = await api.post(`/api/onboard`, onboardingData);
    // This implementation is a little different because of the possible flows.
    // This pulls the four data points and all their validations out of the response in one go so the user doesn't have
    // fail one validation at a time.
    //
    // A successful request means all data validated and saved. But the sync may fail so we still have errors to return.
    // An failed request means the the data did _not_ validate or did not save. The sync will not have run.
    // If the data all validates the chance the data will not save is very low. But it's still possible.
    const status = resp.status === StatusCodes.OK ? api.success : api.error;
    const { errors = ONBOARDING_EMPTY_ERROR, message, ...data } = resp.body;
    setState({ status, onboardingData: data, error: errors, message });
  }, []);

  return {
    onboardLocation: state,
    add,
  };
}
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  Grid,
} from '@mui/material';

import { api } from 'lib';
import { InfoBar } from 'components';
import { useBuysList } from 'api/buy';
import { BuyColumns, AllUnitSelector } from 'components';
import { useUnitsList } from 'api';
import { UnitModel } from 'model';
import { useSearchParams } from 'react-router-dom';

interface Props {
  status: string;
  unitFilter: UnitModel | null;
}

const BuysTable = ({ status, unitFilter }: Props) => {
  const { buysList, listBuys } = useBuysList();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  React.useEffect(() => {
    listBuys(
      status,
      paginationModel.page,
      paginationModel.pageSize,
      unitFilter,
      status === 'completed' ? 'completed_at' : 'updated_at'
    );
  }, [paginationModel, listBuys, status, unitFilter]);

  if (buysList.status === api.error) {
    return <InfoBar status='error' message={buysList.error} />;
  }
  return (
    <DataGrid
      rows={buysList.buys}
      columns={BuyColumns(status)}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 25 },
        },
      }}
      loading={buysList.status === api.loading}
      slots={{
        loadingOverlay: LinearProgress,
      }}
      rowCount={buysList.total}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      paginationMode='server'
    />
  );
};

export const BuysList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = React.useState<string>(() => searchParams.get('status') || 'active');

  const [selectedUnit, setSelectedUnit] = React.useState<UnitModel | null>(
    null
  );
  const { units, list: getUnits } = useUnitsList();

  React.useEffect(()=> {
    setStatus(searchParams.get('status') || 'active');
  },[searchParams]);


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchParams({ status: newValue});
  };

  React.useEffect(() => {
    getUnits();
  }, [getUnits]);

  return (
    <Box sx={{ width: '95%', pt: 2, fontSize: '0.9rem' }}>
      <Grid container gap={4} alignItems='center' flexDirection='row'>
        <Grid item>
          <Typography variant='h5' sx={{ mb: 1 }}>
            Buys
          </Typography>
        </Grid>
        {units.length > 1 && (
          <Grid item>
            <AllUnitSelector
              units={units}
              onSelect={setSelectedUnit}
              selectedUnit={selectedUnit}
            />
          </Grid>
        )}
      </Grid>
      <Tabs
        onChange={handleChange}
        value={status}
        aria-label='Buys'
        sx={{ mb: 2 }}
      >
        <Tab label='Active' value='active' sx={{ px: 8 }} />
        <Tab label='Pending' value='pending' sx={{ px: 8 }} />
        <Tab label='Complete' value='completed' sx={{ px: 8 }} />
        <Tab label='Cancelled' value='cancelled' sx={{ px: 8 }} />
      </Tabs>
      <BuysTable status={status} unitFilter={selectedUnit} />
    </Box>
  );
};

import * as React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { HeartlandCustomFieldModel } from 'model';

interface Props {
  field: HeartlandCustomFieldModel
  mapped: string | null;
  value: string;
  onUpdate?: (key: string, value: string) => void;
}

const parseOptions = (str: string) => {
  const options = JSON.parse(str);
  return options;
};

export const CustomField: React.FC<Props> = ({ field, mapped, value, onUpdate }: Props) => {

  const [selectedValue, setSelectedValue] = React.useState(value);

  const doUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
    if (onUpdate) {
      onUpdate(field.key, e.target.value);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value: string = event.target.value;
    setSelectedValue(value);
    if (onUpdate) {
      onUpdate(field.key, value);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={2}>
        {field.name}
      </Grid>
      <Grid item xs={2}>
        {field.required && <strong>required</strong>}
      </Grid>
      <Grid item xs={6}>
        {mapped !== null ? <em style={{ color: '#666' }}>Mapped to: {mapped}</em> : <>
          {field.validation_type === 'list' &&
            <FormControl fullWidth>
              <InputLabel id="heartland-select-label">Value</InputLabel>
              <Select
                labelId="heartland-select-label"
                label="Value"
                value={selectedValue}
                onChange={handleChange}
              >
                {parseOptions(field.validation_options).map((value: string) =>
                  <MenuItem key={value} value={`${value}`}>{value}</MenuItem>
                )}
              </Select>
            </FormControl>
          }
          {field.validation_type === 'text' &&
            <TextField
              label="Value"
              variant="outlined"
              fullWidth
              value={value}
              onChange={doUpdate}
            />
          }
        </>
        }
      </Grid>
    </Grid>
  );
};
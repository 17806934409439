import * as React from "react";
import { api } from "lib";
import { LegoSetImageModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  message: string;
  image: LegoSetImageModel | null;
}

interface Props {
  addLegoSetImage: State & { add: (url: string, legoSetId: number, type: string) => void, reset: () => void; };
}

const defaultState = {
  status: api.idle,
  message: "",
  image: null,
}

export const useAddLegoSetImage = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const add = React.useCallback(async (url: string, legoSetId: number, type: string) => {
    setState({ status: api.loading, message: '', image: null });
    const resp = await api.post(`/api/lego_sets/${legoSetId}/images/upload`, { url: url, type: type });
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, image: resp.body, message: '' });
    } else {
      setState({ status: api.error, image: null, message: resp.body });
    }
  }, []);

  const reset = React.useCallback(() => {
    setState({ ...defaultState });
  }, []);

  return {
    addLegoSetImage: { ...state, add, reset }
  };
};


import * as React from "react";
import { api } from "lib";
import { AnimalModel, LegoSetModel, MinifigModel, SeriesMinifigModel } from "model";
import { StatusCodes } from "http-status-codes";
import { useHeartlandLocations } from "api/heartland";

interface State {
  status: string;
  error: string;
  items: any[];
  total: number;
}

interface Props {
  heartlandItems: State & {
    getItem: (heartland_id: number, item: LegoSetModel | AnimalModel | MinifigModel | SeriesMinifigModel) => void
  }
}

const defaultState: State = {
  status: api.idle,
  error: '',
  items: [],
  total: 0,
};

export const useHeartlandItems = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);
  const { heartlandLocations } = useHeartlandLocations()

  const getItem = React.useCallback(async (heartland_id: number, item: AnimalModel | LegoSetModel | MinifigModel | SeriesMinifigModel) => {
    setState({ status: api.loading, total: 0, items: [], error: '' });
    const resp = await api.get('/api/lego_sets/heartland_items', { heartland_id: heartland_id.toString(), query: item.bricklink_id });
    if (resp.status === StatusCodes.OK) {
      const items = resp.body.results;
      const inventory = items.map((item: any) => {
        let newItem = { ...item };
        let total_available = 0;
        let total_on_po = 0;
        newItem.inventory = item.inventory.map((inventory: any) => {
          const location = heartlandLocations.lookup(inventory.location_id);
          total_available += inventory.qty_available;
          total_on_po += inventory.qty_on_po;
          return {
            qty_available: inventory.qty_available,
            qty_on_po: inventory.qty_on_po,
            location_id: location ? location.location_id : inventory.location_id,
            location_name: location ? location.location_name : 'unknown',
          };
        });
        newItem.total_available = total_available;
        newItem.total_on_po = total_on_po;
        return newItem;
      });
      setState({ status: api.success, total: resp.body.total, items: inventory, error: '' });
    } else {
      setState({ status: api.error, total: 0, items: [], error: resp.body });
    }
  }, []);

  return {
    heartlandItems: {
      ...state,
      getItem,
    }
  };
};


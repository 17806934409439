import * as React from 'react';
import { api } from 'lib';
import { DailyBuy, UnitModel } from 'model';
import { StatusCodes } from 'http-status-codes';

interface Props {
  dailyBuysList: {
    status: string;
    error: string;
    buys: DailyBuy[];
  };
  getDailyBuysList: (
    startDate: string,
    endDate: string,
    UnitModel?: UnitModel | null
  ) => void;
  resetDailyBuysList: () => void;
}

interface State {
  status: string;
  error: string;
  buys: DailyBuy[];
}

const defaultState: State = {
  status: api.idle,
  error: '',
  buys: [],
};

export const useDailyBuysList = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = React.useCallback(
    async (
      startDate: string,
      endDate: string,
      unit: UnitModel | null = null
    ) => {
      setState((prevState) => ({ ...prevState, status: api.loading }));
      const options: Record<string, string> = {
        start_date: startDate,
        end_date: endDate,
      };

      if (unit) {
        options.unit = unit.id.toString();
      }

      const resp = await api.get('/api/reports/buys/daily', options);
      if (resp.status === StatusCodes.OK) {
        setState({ status: api.success, buys: resp.body.buys, error: '' });
      } else {
        setState({ status: api.error, buys: [], error: resp.body });
      }
    },
    [setState]
  );

  const reset = () => {
    setState(defaultState);
  };

  return {
    dailyBuysList: {
      ...state,
    },
    getDailyBuysList: get,
    resetDailyBuysList: reset,
  };
};

import * as React from 'react';
import { Grid } from '@mui/material';

import { HeartlandLocationsList, UnitsList, } from 'components';
import { HeartlandModel } from 'model';

interface Props {
  heartland: HeartlandModel;
}

export const UnitsTab: React.FC<Props> = ({ heartland }: Props) => {

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <h4>Locations / Units</h4>
        {heartland.units && <UnitsList units={heartland.units} />}
      </Grid>
      <Grid item xs={12} md={12}>
        <HeartlandLocationsList heartland={heartland} />
      </Grid>
    </Grid>
  );
};
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { HeartlandCustomFieldModel } from 'model';
import * as React from 'react';

interface Props {
  label: string;
  value?: string;
  fields: HeartlandCustomFieldModel[];
  onChange?: (field: string) => void;
};
export const HeartlandCustomFieldSelector: React.FC<Props> = ({ fields, value, label, onChange }: Props) => {
  const [selectedField, setSelectedField] = React.useState<string>(value ? value : "");

  const handleChange = (event: SelectChangeEvent) => {
    let value: string = event.target.value;
    const customField = fields.find(u => u.uuid === value);
    if (!customField) {
      value = "";
    }
    setSelectedField(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="custom-field-selector-label">{label}</InputLabel>
      <Select
        labelId="custom-field-selector-label"
        id="custom-field-selector"
        value={selectedField}
        label={label}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {fields.map((field: HeartlandCustomFieldModel) =>
          <MenuItem key={field.id} value={`${field.uuid}`}>{field.name}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
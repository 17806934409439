import * as React from 'react';
import { Grid } from '@mui/material';

import { LinkButton } from 'components';
import { MinifigModel } from 'model';

interface Props {
  minifig: MinifigModel;
  displayDetails?: boolean;
}

export const MinifigLinks: React.FC<Props> = ({
  minifig,
  displayDetails,
}: Props) => {
  return (
    <>
      <Grid container alignItems='center' sx={{ mt: 1, mb: 2 }} spacing={1}>
        {displayDetails && (
          <Grid item>
            <LinkButton
              url={`/minifigs/${minifig.bricklink_id}`}
              target='_pa'
              label='Details'
            />
          </Grid>
        )}
        {minifig.bricklink_id && (
          <Grid item>
            <LinkButton
              url={`https://www.bricklink.com/v2/catalog/catalogitem.page?M=${minifig.bricklink_id}`}
              target='_bl'
              label={`Bricklink: ${minifig.bricklink_id}`}
            />
          </Grid>
        )}
        {minifig.rebrickable_id && (
          <Grid item>
            <LinkButton
              url={`https://rebrickable.com/minifigs/${minifig.rebrickable_id}`}
              target='_rb'
              label={`Rebrickable: ${minifig.rebrickable_id}`}
            />
          </Grid>
        )}
        {minifig.brickowl_id && (
          <Grid item>
            <LinkButton
              url={`https://brickowl.com/catalog/${minifig.brickowl_id}`}
              target='_bo'
              label={`Brick Owl: ${minifig.brickowl_id}`}
            />
          </Grid>
        )}
        <Grid item>
          <LinkButton
            url={`https://brickset.com/minifigs/${minifig.brickset_id}`}
            target='_bo'
            label='Brickset'
          />
        </Grid>
      </Grid>
    </>
  );
};

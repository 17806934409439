import * as React from 'react';
import { Box, Link } from '@mui/material';

import { api } from 'lib';
import ReactCodeInput from 'components/verification_input';

import { rowStyle, } from './styles';
import { useVerifyToken } from './api/verifytoken';
import { ForgotPasswordContext, ForgotPasswordState, TForgotPasswordModalContext } from './forgotpassword_context';

export const VerifyCodeForm: React.FC = () => {
  const { email, changeState, updateVerificationCode } = React.useContext(ForgotPasswordContext) as TForgotPasswordModalContext;
  const { status: verifyStatus, verifyToken, resetStatus: resetVerifyStatus } = useVerifyToken();

  const goToRequest = () => {
    changeState(ForgotPasswordState.request);
    resetVerifyStatus();
  };

  const checkVerificationCode = (code: string) => {
    updateVerificationCode(code);
    verifyToken(email, code);
  };

  React.useEffect(() => {
    if (verifyStatus === api.success) {
      changeState(ForgotPasswordState.reset);
    }
    return (() => {
      resetVerifyStatus();
    });
  }, [verifyStatus, changeState, resetVerifyStatus]);

  return (
    <>
      <Box sx={rowStyle}>
        <h2>Verification</h2>
        <p>Check your email for your one time code to reset your password.</p>
        <ReactCodeInput type="text" fields={6} onComplete={checkVerificationCode} className="codeInput" />
        {verifyStatus === api.error &&
          <Box sx={rowStyle} style={{ width: '60%' }}>
            <div className="error">Your code is not valid or expired, please try again or <Link href="#" onClick={goToRequest}>request</Link> a new code.</div>
          </Box>
        }
      </Box>
    </>
  );
};
import * as React from 'react';
import { Box, Grid, LinearProgress, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { api } from 'lib';
import { useLegoSetsSearch } from 'api';
import { LegoSetModel } from 'model';
import { SearchThumbnail, LegoSetCard, CardModal, Pagination } from 'components';

interface Props {
  query: string;
  onClick?: (legoSet: LegoSetModel) => void;
  maxResults?: number;
  size?: number;
}

export const SetSearch: React.FC<Props> = (props: Props) => {
  const { search, status, total, sets } = useLegoSetsSearch();
  const [page, setPage] = React.useState({ index: 0, total: 0 });
  const [selected, setSelected] = React.useState<LegoSetModel | null>(null);
  const handleOpen = (legoSet: LegoSetModel) => setSelected(legoSet);
  const handleClose = () => setSelected(null);
  const doSearch = React.useMemo(() => debounce(search, 500), [search]);
  const maxResults = React.useMemo(
    () => (props.maxResults ? props.maxResults : 18),
    [props.maxResults]
  );

  React.useEffect(() => {
    setPage((prevPage) => ({ ...prevPage, index: 0 }));
  }, [props.query]);

  React.useEffect(() => {
    if (props.query.length > 3 && props.query.indexOf(',') < 0) {
      doSearch(props.query, page.index, maxResults);

    }
  }, [props.query, page.index, doSearch, maxResults]); 

  // Only update page total when total or maxResults changes
  React.useEffect(() => {
    setPage((prevPage) => ({ ...prevPage, total: Math.ceil(total / maxResults) }));
  }, [total, maxResults]);

  const nextPage = () => {
    const newIndex = page.index + 1;
    setPage({
      index: newIndex > page.total - 1 ? page.total - 1 : newIndex,
      total: page.total,
    });
  };
  const prevPage = () => {
    const newIndex = page.index - 1;
    setPage({ index: newIndex < 0 ? 0 : newIndex, total: page.total });
  };

  if (status === api.loading) {
    return (
      <div style={{ margin: '32px 0'}}>
        <LinearProgress />
      </div>
    );
  } else if (status === api.success) {
    return (
      <>
        <Grid container alignItems='center' spacing={1}>
          <Pagination
            page={page}
            total={total}
            prevPage={prevPage}
            nextPage={nextPage}
            showTotal={true}
          />
          {sets &&
            sets.map((set) => (
              <Grid item xs={12} md={2} key={set.id}>
                <div onClick={() => handleOpen(set)}>
                  <Tooltip
                    enterDelay={500}
                    title={
                      <Box sx={{ fontSize: '8pt' }}>
                        <strong>{set.name} ({set.set_number})</strong><br />
                        <strong>Year:</strong> {set.release_year} {set.retired && "(Retired)"}<br />
                        <strong>Parts:</strong> {set.num_parts}<br />
                        <strong>Minifigs:</strong> {set.num_minifigs}<br />
                        <strong>Category:</strong> {set.category}
                      </Box>
                    }>
                    <Box>
                      <SearchThumbnail
                        imageUrl={set.rebrickable_image_url}
                        imageTitle={set.name}
                        payload={set}
                        onClick={props.onClick}
                        height={props.size ? props.size : 120}
                        width={props.size ? props.size : 120}
                      >
                        {set.set_number} - {set.name}
                      </SearchThumbnail>
                    </Box>
                  </Tooltip>
                </div>
              </Grid>
            ))}
          <Pagination
            page={page}
            total={total}
            prevPage={prevPage}
            nextPage={nextPage}
            showTotal={true}
          />
        </Grid>
        <CardModal open={selected !== null} onClose={handleClose}>
          <LegoSetCard legoSet={selected} />
        </CardModal>
      </>
    );
  } else {
    return <></>;
  }
};

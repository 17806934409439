import * as React from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useHeartlandItems } from 'api';
import { SessionContext, TSessionContext, api } from 'lib';
import { LegoSetModel, MinifigModel, SeriesMinifigModel } from 'model';


interface Props {
  legoSet: LegoSetModel | SeriesMinifigModel | MinifigModel;
  department: string;
  onUpdate: (value: number) => void;
}

const PriceButton: React.FC<Props> = ({ legoSet, department, onUpdate }: Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { heartlandItems } = useHeartlandItems();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (legoSet && currentHeartland) {
      heartlandItems.getItem(currentHeartland.id, legoSet);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const update = (value: number) => {
    onUpdate(value);
    setAnchorEl(null);
  };

  const getHLPrice = React.useMemo(() => {
    if (heartlandItems.status === api.success && heartlandItems.items) {
      const item = heartlandItems.items.find((i) => i.custom.department === department);
      if (item) {
        return Math.round(item.price);
      }
    }
    return 0;
  }, [heartlandItems.items, heartlandItems.status]);

  const getBLPrice = React.useMemo(() => {
    return department === 'Used Sets' ? Math.round(legoSet.used_bricklink_prices.avg) : Math.round(legoSet.new_bricklink_prices.avg);
  }, [legoSet]);

  return (
    <>
      <IconButton
        id="price-button"
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="price-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'price-button',
        }}
      >
        <MenuItem onClick={() => update(getHLPrice)}>Use Store Price</MenuItem>
        <MenuItem onClick={() => update(getBLPrice)}>Use Bricklink Price</MenuItem>
      </Menu>
    </>
  );
};

export default PriceButton;

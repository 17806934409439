import * as React from "react";
import { api } from "lib";
import { UserModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  error: string;
  user: UserModel | null;
}

interface Props {
  getUser: State;
  get: (user_id: number) => void;
}

const idleState = {
  status: api.idle,
  user: null,
  error: "",
};

const loadingState = {
  status: api.loading,
  user: null,
  error: "",
};

const errorState = (error: string) => ({
  status: api.error,
  user: null,
  error: error
});

export const useGetUser = (): Props => {
  const [state, setState] = React.useState<State>(idleState);

  const get = React.useCallback(async (user_id: number) => {
    setState(idleState);
    const resp = await api.get(`/api/users/${user_id}`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, user: resp.body, error: "" });
    } else {
      setState(errorState(resp.body));
    }
  }, []);

  return {
    getUser: state,
    get,
  };
};

import * as React from "react";
import { api } from "lib";
import { CustomerModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  customers: CustomerModel[];
  total: number;
  list: (query: string, page: number, size: number) => void;
}

export const useCustomersList = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [total, setTotal] = React.useState<number>(0);
  const [customers, setCustomers] = React.useState<CustomerModel[]>([]);

  const list = React.useCallback(async (query: string, page: number, size: number) => {
    setStatus(api.loading);
    const options = {
      query: query,
      page: page.toString(),
      size: size.toString(),
    }
    const resp = await api.get('/api/customers/search', options);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setCustomers(resp.body.customers);
      setTotal(resp.body.total);
    } else {
      setStatus(api.error);
      setError(resp.body);
    }
  }, []);

  return {
    status,
    error,
    customers,
    total,
    list,
  };
};

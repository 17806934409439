import { AuditStatus, AuditType } from 'model';
import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { displayType } from 'lib';

interface Props {
  value?: AuditStatus | null;
  onSelect?: (audit_type: AuditStatus | null) => void;
}

export const AuditStatusSelector: React.FC<Props> = ({
  value,
  onSelect,
}: Props) => {
  const auditTypes = ['unresolved', 'resolved'].sort((a, b) =>
    a.localeCompare(b)
  ); // Fetch all audit types

  const [selectedType, setSelectedType] = React.useState(value);

  const select = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value as AuditStatus);
    if (onSelect) {
      onSelect(event.target.value as AuditStatus);
    }
  };

  return (
    <FormControl>
      <InputLabel id='audit-status-label'>Audit Status</InputLabel>
      <Select
        labelId='audit-status-label'
        value={selectedType ? selectedType : 'any'}
        id='audit-status'
        onChange={select}
        label='Audit Status'
        sx={{ width: 150 }}
      >
        <MenuItem value={'any'}>Any Status</MenuItem>
        {auditTypes.map((auditType) => (
          <MenuItem key={auditType} value={auditType}>
            {displayType(auditType)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { AuditSearchList } from 'components';

export const Audits: React.FC = () => {
  return (
    <Box sx={{ width: '80%' }}>
      <Typography variant='h5' sx={{ mb: 2 }}>
        Audits
      </Typography>
      <AuditSearchList resultCount={10} />
    </Box>
  );
};

import * as React from 'react';
import { Chip, Grid } from '@mui/material';

import { SeriesMinifigModel } from 'model';

interface Props {
  minifig: SeriesMinifigModel;
}

export const CMFSummary: React.FC<Props> = ({ minifig }: Props) => {
  return (
    <>
      <Grid container alignItems='center' sx={{ mt: 1 }} spacing={1}>
        {minifig.bricklink_category !== null && (
          <Grid item>
            <Chip
              color='primary'
              variant='outlined'
              label={`${minifig.bricklink_category}`}
            />
          </Grid>
        )}
        {minifig.series !== null && (
          <Grid item>
            <Chip color='info' variant='outlined' label={`${minifig.series}`} />
          </Grid>
        )}
        {minifig.release_year !== null && (
          <Grid item>
            <Chip
              color='info'
              variant='outlined'
              label={`Released: ${minifig.release_year}`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

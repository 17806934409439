import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, LinearProgress, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import useStyles from './styles';
import { CustomerImportModel } from 'model';
import { useImportSearch } from '../../api/customer';
import { api } from 'lib';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    flex: 1,
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'phone_number',
    headerName: 'Phone',
    flex: 1,
  },
  {
    field: 'active?',
    headerName: 'Active',
    minWidth: 100,
  },
  {
    field: '',
    headerName: 'Actions',
    renderCell: (params: GridRenderCellParams<any, Date>) => (
      <strong>
        <IconButton color="primary" aria-label="Import Customer">
          <ImportExportIcon />
        </IconButton>
      </strong>
    ),
    width: 150
  }
];

interface Props {
  customers: CustomerImportModel[];
  total: number;
  page: number
}

const Table: React.FC<Props> = (props) => {
  return (
    <DataGrid
      rows={props.customers}
      columns={columns}
      rowCount={props.total}
      paginationMode='server'
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      disableRowSelectionOnClick
      pageSizeOptions={[10, 25]}
    />
  );
};

export const CustomerImport: React.FC = () => {
  const classes = useStyles();
  const [query, setQuery] = React.useState<string>("");
  const { search, customers, status, total, page } = useImportSearch();

  const updateQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const doSearch = () => {
    search(query);
  };

  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      doSearch();
    }
  }

  return (
    <div className={classes.import} >
      <h2>Customers :: Import From Heartland</h2>
      <div className="searchBar">
        <TextField
          variant="outlined"
          label="Search"
          className="searchField"
          value={query}
          placeholder='email, phone or name'
          onChange={updateQuery}
          onKeyDown={keyPress}
        />
        <IconButton aria-label="search" className="searchButton" onClick={doSearch}>
          <SearchIcon />
        </IconButton>
      </div>
      <div className="searchResults">
        {status === "loading" &&
          <LinearProgress />
        }
        {status === api.success &&
          <Table customers={customers} total={total} page={page} />
        }
      </div>
    </div >
  );
};
import * as React from 'react';
import { Box, Button, Grid, Checkbox } from '@mui/material';

type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

interface Props<T> {
  imageUrl: string | null;
  children: React.ReactNode;
  imageTitle?: string;
  height?: number | string;
  width?: number;
  maxImHeight?: number;
  quantity?: number;
  payload?: T;
  imageFit?: ObjectFit;
  onClick?: (payload: T) => void;
  onCheck?: (payload: T, checked: boolean) => void;
}

export const SearchThumbnail = <T,>(props: Props<T>): JSX.Element => {
  const doClick = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onClick && props.payload) {
      props.onClick(props.payload);
    }
    e.stopPropagation();
  };

  const doCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onCheck && props.payload) {
      props.onCheck(props.payload, e.target.checked);
    }
  };

  return (
    <Grid
      container
      spacing={0}
      alignItems='center'
      sx={{
        border: '1px solid #eee',
        background: 'white',
        borderRadius: 1,
        transition: 'box-shadow 0.2s ease-in-out',
        '&:hover': {
          cursor: 'pointer',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
        },
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            textAlign: 'center',
            margin: '0 auto',
            pt: 1,
            height: 'auto',
            width: 'auto',
            overflow: 'hidden',
          }}
        >
          <img
            alt={props.imageTitle ? props.imageTitle : 'Display Card'}
            src={props.imageUrl ? `${props.imageUrl}` : '/unavailable.png'}
            style={{
              width: props.height,
              height: props.width,
              objectFit: props.imageFit ? props.imageFit : 'contain',
              objectPosition: 'center',
            }}
            onError={(e) => (e.currentTarget.src = '/unavailable.png')}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            fontSize: 12,
            fontWeight: 700,
            textAlign: 'center',
            mx: 'auto',
            my: 1,
            px: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {props.children}
        </Box>
      </Grid>
      {props.onClick && (
        <Grid item xs={12}>
          <Box
            sx={{
              fontSize: 12,
              fontWeight: 700,
              textAlign: 'center',
              mx: 'auto',
              my: 1,
            }}
          >
            {props.onCheck && (
              <Checkbox
                onClick={(e) => e.stopPropagation()}
                onChange={doCheck}
              />
            )}
            <Button onClick={doClick} variant='contained' size='small'>
              Select
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

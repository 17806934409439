import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props {
  value: string;
  onSelect?: (value: string) => void;
}

export const CountrySelector: React.FC<Props> = ({ value, onSelect }: Props) => {
  const [selectedCountry, setSelectedCountry] = React.useState<string>("US");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCountry(event.target.value);
  };

  React.useEffect(() => {
    if (onSelect) {
      onSelect(selectedCountry);
    }
  }, [selectedCountry, onSelect]);

  return (
    <FormControl fullWidth>
      <InputLabel id="country-select-label">Country</InputLabel>
      <Select
        labelId="counry-select-label"
        id="Country"
        value={selectedCountry}
        onChange={handleChange}
        label="Country"
      >
        <MenuItem value={"US"}>United States</MenuItem>
        <MenuItem value="CA">Canada</MenuItem>
      </Select>
    </FormControl>
  );
};

import * as React from "react";
import { api } from "lib";
import { HeartlandModel, RewardsSignupFormModel, UserModel } from "model";
import { StatusCodes } from "http-status-codes";

export type HeartlandInput = {
  domain: string;
  api_key: string;
  user: UserModel;
};

export type RewardsSignupFormInput = {
  short_code: string;
  message: string;
}

interface Props {
  status: string;
  error: string;
  heartland: HeartlandModel | null;
  rewardsSignupForm: RewardsSignupFormModel | null;
  add: (heartland: HeartlandInput, rewardsShortCode: RewardsSignupFormInput) => void;
}

export const useAddHeartland = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [heartland, setHeartland] = React.useState<HeartlandModel | null>(null);
  const [rewardsSignupForm, setRewaradsSignupForm] = React.useState<RewardsSignupFormModel | null>(null);

  const add = React.useCallback(async (heartlandParams: HeartlandInput, rewardsSignupFormParams: RewardsSignupFormInput) => {
    setStatus(api.loading);
    const ownerId = heartlandParams.user.id;
    const { user, ...newInstance } = heartlandParams;
    const payload = {
      heartland: { ...newInstance, user_id: ownerId, active: true },
      rewards_signup_form: { short_code: rewardsSignupFormParams.short_code },
    };
    const resp = await api.post(`/api/heartlands/`, payload);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setHeartland(resp.body.heartland);
      setRewaradsSignupForm(resp.body.rewards_signup_form);
    } else {
      setStatus(api.error);
      setHeartland(null)
      setError(resp.body.error);
    }
  }, []);

  return {
    status,
    error,
    heartland,
    rewardsSignupForm,
    add,
  };
};

import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';

import { api } from 'lib';
import { useListAudits } from 'api';
import { AuditColumns } from 'components';
import { AuditStatus, AuditType } from 'model';

interface Props {
  displayHeartland: boolean;
  resultCount: number;
  heartland_id?: number | null;
  audit_type?: AuditType | null;
  audit_status?: AuditStatus | null;
}

export const AuditSearchTable: React.FC<Props> = ({
  heartland_id,
  audit_type,
  displayHeartland,
  audit_status,
  resultCount,
}) => {
  const { listAudits } = useListAudits();
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: resultCount,
    page: parseInt(searchParams.get('pageNum') || '0', 10),
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    listAudits.list(
      paginationModel.page,
      paginationModel.pageSize,
      heartland_id,
      audit_type,
      audit_status
    );

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('pageNum', paginationModel.page.toString());
    setSearchParams(newSearchParams);
  }, [paginationModel, audit_type, audit_status, heartland_id]);

  const rowClick = (params: GridRowParams<any>) => {
    navigate(`/audits/${params.id}`);
  };

  if (listAudits.status === api.error) {
    console.log(listAudits.error);
    return <div>Unable to load audit logs</div>;
  } else {
    return (
      <>
        <DataGrid
          rows={listAudits.audits}
          columns={AuditColumns(displayHeartland)}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          rowCount={listAudits.count}
          paginationMode='server'
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          onRowClick={rowClick}
          getRowClassName={() => 'clickable-row'}
          sx={{
            '.clickable-row:hover': {
              cursor: 'pointer',
            },
          }}
        />
      </>
    );
  }
};

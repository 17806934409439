import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import ReactToPrint from 'react-to-print';

import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { BuyModel, InfoBarModel } from 'model';
import { BuyContext, BuyProvider, TBuyContext } from 'contexts';
import { ConfirmationDialog, InfoBar } from 'components';
import { CustomerSection, Signatures } from './components';

const sectionStyle = { m: 1, p: 2 };

interface Props {
  buy?: BuyModel;
}

const BuyFormContents = () => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;

  return (
    <Container maxWidth='xl' sx={{ py: 2 }}>
      <Box sx={{ mx: 'auto', my: 1, textAlign: 'center' }}>
        <img src='/logo_straight.png' width={200} alt='Bricks & Minifigs' />
        <Typography variant='h4' sx={{ fontWeight: 700 }}>
          Store Buy & Declaration of Proof of Ownership
        </Typography>
      </Box>
      <Paper sx={sectionStyle}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Typography variant='body1'>
              <span style={{ paddingRight: 24 }}>
                <strong>ID:</strong> {buy.id}
              </span>
              <br />
              <strong>Date:</strong>{' '}
              {new Date(buy.updated_at || '').toDateString()}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' sx={{ textAlign: 'right', my: 2 }}>
              <strong>Employee:</strong> {buy.creator?.first_name}{' '}
              {buy.creator.last_name}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={sectionStyle}>
        <CustomerSection />
        <Typography variant='body2' sx={{ my: 2 }}>
          There are many variables that go into what we can offer on your LEGO®
          products. As a result, prices quoted are only valid the day they are
          offered. Store credit is used like cash and does not cover any
          additional taxes that may apply. Unused store credit is saved and can
          be used at a future date.
        </Typography>
      </Paper>
      <Paper sx={sectionStyle}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' sx={{ my: 1 }}>
              {' '}
              Summary
            </Typography>
            <Typography variant='body1'>{buy.summary}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              sx={{ textAlign: 'right', fontWeight: 700 }}
            >
              <span style={{ paddingRight: 32 }}>
                Type: {buy.buy_type === 'cash' ? 'Cash' : 'Store Credit'}
              </span>
              Amount:{' '}
              {formatCurrency(
                buy.buy_type === 'cash' ? buy.cash_paid : buy.credit_paid
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Signatures />
    </Container>
  );
};

const BuyFormDialog: React.FC = () => {
  const { buy, cancel, complete, revert, saveStatus } = React.useContext(
    BuyContext
  ) as TBuyContext;
  const componentRef = React.useRef<HTMLDivElement>(null);
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
  const [cancelNote, setCancelNote] = React.useState<string>('');

  const clearInfoBar = () => setInfoBar(null);

  const checkCreatorSignatures = (): boolean => {
    if (!buy) {
      return false;
    }
    return (
      (
        buy.creator.first_name.trim() +
        ' ' +
        buy.creator.last_name.trim()
      ).toLowerCase() === buy.employee_signature?.toLowerCase().trim()
    );
  };

  const checkSellerSignatures = (): boolean => {
    if (!buy) {
      return false;
    }
    return (
      (
        buy.customer?.first_name.trim() +
        ' ' +
        buy.customer?.last_name.trim()
      ).toLowerCase() === buy.customer_signature?.toLowerCase().trim()
    );
  };

  const doComplete = () => {
    if (!checkCreatorSignatures()) {
      setInfoBar({
        message: 'Employee Signature do not match their name!',
        status: 'error',
      });
    } else if (!checkSellerSignatures()) {
      setInfoBar({
        message: 'Customer Signature do not match their name!',
        status: 'error',
      });
    } else {
      complete();
    }
  };

  const handleCancel = () => setShowCancelModal(true);
  const handleCancelConfirm = (value: boolean) => {
    setShowCancelModal(false);
    if (value) {
      cancel(cancelNote);
    }
  };

  const handleRevert = () => {
    revert();
  };

  return (
    <Dialog open={true} fullScreen>
      <Grid container justifyContent='center' sx={{ my: 2 }}>
        <Grid item xs={12} md={12}>
          {infoBar && (
            <InfoBar
              status={infoBar.status}
              message={infoBar.message}
              onClose={clearInfoBar}
            />
          )}
        </Grid>
      </Grid>
      <div ref={componentRef}>
        <BuyFormContents />
      </div>
      {buy.status !== 'completed' && (
        <Grid
          container
          justifyContent='center'
          spacing={3}
          sx={{ mt: 2, mb: 8 }}
        >
          {saveStatus === api.loading && <CircularProgress />}
          <Grid item>
            <Button
              variant='contained'
              onClick={doComplete}
              disabled={saveStatus === api.loading}
            >
              Complete {buy.buy_type === 'trade' && 'and Deposit Store Credit'}
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary' onClick={handleRevert}>
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary' onClick={handleCancel}>
              Cancel Buy
            </Button>
          </Grid>
          {buy.status === 'completed' && (
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                href={`/buys/${buy.id}?view=complete`}
              >
                Close
              </Button>
            </Grid>
          )}
          <ConfirmationDialog
            show={showCancelModal}
            onClose={handleCancelConfirm}
            message='Are you sure you want to cancel this buy? This buy will be closed and can not be edited anymore.'
          >
            <TextField
              label='Note'
              variant='outlined'
              size='small'
              fullWidth
              value={cancelNote}
              onChange={(e) => setCancelNote(e.target.value)}
            />
          </ConfirmationDialog>
        </Grid>
      )}
      {buy.status === 'completed' && (
        <Grid container justifyContent='center' spacing={3} sx={{ mb: 4 }}>
          <Grid item>
            <ReactToPrint
              trigger={() => (
                <Button variant='contained' color='primary'>
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              href={`/buys/${buy.id}?view=complete`}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export const BuyForm: React.FC<Props> = ({ buy }: Props) => {
  const { currentUnit, currentUser } = React.useContext(
    SessionContext
  ) as TSessionContext;

  if (currentUser && currentUnit && buy) {
    return (
      <BuyProvider creator={currentUser} unit={currentUnit} buy={buy}>
        <BuyFormDialog />
      </BuyProvider>
    );
  } else {
    return <>Sorry this buy is currently unavailable to view</>;
  }
};

import { AnimalBuyLineModel } from './animal_buy_line';
import { BulkBuyLineModel } from './bulk_buy_line';
import { BuyNote } from './buy_note';
import { BuyStoreCreditLog } from './buy_store_credit_log';
import { CustomerImportModel } from './customer_import';
import { MinifigBuyLineModel } from './minifig_buy_line';
import { MiscBuyLineModel } from './misc_buy_line';
import { NewSetBuyLineModel } from './new_set_buy_line';
import { SeriesMinifigBuyLineModel } from './series_minifig_buy_line';
import { UnitModel } from './unit';
import { UsedSetBuyLineModel } from './used_set_buy_line';
import { UserModel } from './user';

export const BUY_LINES = [
  'minifig_buy_lines',
  'series_minifig_buy_lines',
  'new_set_buy_lines',
  'used_set_buy_lines',
  'animal_buy_lines',
  'bulk_buy_lines',
  'misc_buy_lines',
] as const;

export type BuyLineType =
  | MinifigBuyLineModel
  | SeriesMinifigBuyLineModel
  | NewSetBuyLineModel
  | UsedSetBuyLineModel
  | AnimalBuyLineModel
  | BulkBuyLineModel
  | MiscBuyLineModel;

export type BuyLineArrayType =
  | MinifigBuyLineModel[]
  | SeriesMinifigBuyLineModel[]
  | NewSetBuyLineModel[]
  | UsedSetBuyLineModel[]
  | AnimalBuyLineModel[]
  | BulkBuyLineModel[]
  | MiscBuyLineModel[];

export type LineSectionType =
  | 'new_set'
  | 'used_set'
  | 'minifig'
  | 'animal'
  | 'bulk'
  | 'misc';

export type AllMinifigType =
  | SeriesMinifigBuyLineModel
  | MinifigBuyLineModel
  | undefined;

export interface BuyModel {
  id: number;
  creator: UserModel;
  unit: UnitModel;
  buy_type: string;
  total_retail: number;
  asking: number;
  credit_offered: number;
  cash_offered: number;
  credit_min: number;
  credit_max: number;
  cash_min: number;
  cash_max: number;
  bulk_cash_offered: number;
  bulk_credit_offered: number;
  cash_paid: number;
  credit_paid: number;
  bulk_cash_paid: number;
  bulk_credit_paid: number;
  cash_type: string;
  cash_reference: string;
  status: string;
  summary: string;
  customer?: CustomerImportModel;
  created_at?: string;
  updated_at?: string;
  completed_at?: string;
  customer_signature?: string;
  employee_signature?: string;
  customer_signature_date?: string;
  employee_signature_date?: string;
  minifig_buy_lines: MinifigBuyLineModel[];
  series_minifig_buy_lines: SeriesMinifigBuyLineModel[];
  animal_buy_lines: AnimalBuyLineModel[];
  new_set_buy_lines: NewSetBuyLineModel[];
  used_set_buy_lines: UsedSetBuyLineModel[];
  bulk_buy_lines: BulkBuyLineModel[];
  misc_buy_lines: MiscBuyLineModel[];
  buy_store_credit_log?: BuyStoreCreditLog;
  notes: BuyNote[];
  //  "cached" values so we don't have to recalculate these all the time
  total_nib_qty: number;
  total_nib_value: number;
  total_used_qty: number;
  total_used_value: number;
  total_minifig_qty: number;
  total_minifig_value: number;
  total_cmf_qty: number;
  total_cmf_value: number;
  total_misc_qty: number;
  total_misc_value: number;
  total_bulk_qty: number;
  total_bulk_value: number;
  total_animal_qty: number;
  total_animal_value: number;
  all_minifig_selected: boolean;
  all_animal_selected: boolean;
  all_new_set_selected: boolean;
  all_used_set_selected: boolean;
  all_bulk_selected: boolean;
  all_misc_selected: boolean;
  numZero: number;
  num_selected: number;
}

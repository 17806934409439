import * as React from 'react';
import { Grid, Link } from '@mui/material';

export const PublicFooter: React.FC = () => {
  return (
    <div style={{ margin: '32px auto', maxWidth: '80%', fontSize: '90%' }}>
      <div style={{ textAlign: 'center', marginBottom: 8 }}>
        &copy; 2024 Bricks & Minifigs&reg;
      </div>
      <Grid container textAlign="center" justifyContent="center">
        <Grid xs={6} md={2}>
          < Link href="/">Privacy Policy</Link>
        </Grid>
      </Grid>
    </div>
  );
};

import * as React from "react";
import { api } from "lib";
import { StatusCodes } from "http-status-codes";
import { UnitsHeartlandLocationModel } from "model";

interface State {
  locations: UnitsHeartlandLocationModel[];
  status: string;
  error: string;
}

interface Props {
  syncHeartlandLocations: State & {
    sync: (heartlandId: number) => void;
  }
}

const defaultState: State = {
  status: api.idle,
  error: '',
  locations: [],
}

export const useSyncHeartlandLocations = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const sync = React.useCallback(async (heartlandId: number) => {
    setState({ status: api.loading, locations: [], error: '' });
    const resp = await api.get(`/api/heartlands/${heartlandId}/locations/sync`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, error: '', locations: resp.body });
      localStorage.setItem('heartland_locations', JSON.stringify(resp.body));
    } else {
      setState({ status: api.error, error: resp.body, locations: [] });
    }
  }, []);

  return {
    syncHeartlandLocations: {
      ...state,
      sync
    }
  };
};
import * as React from "react";
import { api } from "lib";
import { BuyModel } from "model";
import { StatusCodes } from "http-status-codes";
import { addKeys } from "./helpers";

interface Props {
  status: string;
  error: string;
  buy: BuyModel | null;
  get: (buy_id: number) => void;
}

interface State {
  status: string;
  error: string;
  buy: BuyModel | null;
}

const defaultState = {
  status: api.idle,
  error: "",
  buy: null,
}

export const useGetBuy = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = React.useCallback(async (buy_id: number) => {
    setState({ status: api.loading, buy: null, error: "" });
    const resp = await api.get(`/api/buys/${buy_id}`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, buy: addKeys(resp.body), error: "" });
    } else if (resp.status === StatusCodes.NOT_FOUND) {
      setState({ status: api.error, buy: null, error: "Buy not found." });
    } else {
      setState({ status: api.error, buy: null, error: resp.body.error });
    }
  }, []);

  return {
    status: state.status,
    error: state.error,
    buy: state.buy,
    get,
  };
};

import * as React from 'react';
import {
  Alert, AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { CountrySelector, ErrorBar, StateSelector } from 'components'
import { useStyles } from './styles'
import HelpIcon from "@mui/icons-material/Help";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CancelIcon from "@mui/icons-material/Cancel";
import { validateUnit } from "../unit/validate_unit";
import { UnitModel } from "../../model";
import {
  OnboardLocationFormField,
  OnboardLocationFormGroup,
  OnboardLocationInput,
  useOnboardLocation
} from "../../api/onboard/add";
import { api } from "../../lib";
import { Person, PointOfSale, Storefront } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";

const initialFormValue: OnboardLocationInput = {
  user: {
    first_name: '',
    last_name: '',
    username: '',
    email: '',
  },
  heartland: {
    domain: '',
    api_key: '',
  },
  rewards_signup_form: {
    short_code: '',
  },
  unit: {
    name: '',
    franchise_id: '0',
    address: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
    phone: '',
    email: '',
  }
};

export const AddLocation: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [formValue, setFormValue] = React.useState<typeof initialFormValue>(initialFormValue);
  const { onboardLocation, add } = useOnboardLocation();
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);


  const doOnboard = () => {
    setOpen(true);
    add(formValue);
  }

  // open the backdrop
  React.useEffect(() => {
    if (onboardLocation.status !== api.loading) {
      setOpen(false);
    }
  }, [onboardLocation.status]);

  // check for errors

  const hasGeneralMessageError = React.useMemo(() => {
    return !!(onboardLocation.status === api.error && onboardLocation.message);
  }, [onboardLocation.status, onboardLocation.message]);

  const hasUserErrors = React.useMemo(() => {
    return onboardLocation.error.user.length > 0;
  }, [onboardLocation.status, onboardLocation.error.user]);

  const hasHeartlandErrors = React.useMemo(() => {
    return onboardLocation.error.heartland.length > 0;
  }, [onboardLocation.status, onboardLocation.error.heartland]);

  const hasUnitErrors = React.useMemo(() => {
    return onboardLocation.error.unit.length > 0;
  }, [onboardLocation.status, onboardLocation.error.unit]);

  const hasSyncErrors = React.useMemo(() => {
    return onboardLocation.error.sync.length > 0;
  }, [onboardLocation.status, onboardLocation.error.sync]);

  // check if onboarding is complete
  const isOnboardingSuccess = React.useMemo(() => {
    if (!onboardLocation.onboardingData) {
      return false;
    }
    const { user, heartland, unit, rewards_signup_form } = onboardLocation.onboardingData;
    return user?.id && heartland?.id && unit?.id && rewards_signup_form?.id;
  }, [onboardLocation.onboardingData]);

  // show success message and reset the form
  React.useEffect(() => {
    if (onboardLocation.status === api.success && isOnboardingSuccess) {
      setShowSuccess(true);
      setFormValue(initialFormValue);
    } else {
      setShowSuccess(false);
    }
  }, [onboardLocation.status, onboardLocation.onboardingData]);

  const updateFormField = (group: OnboardLocationFormGroup, field: OnboardLocationFormField, value: string | number) => {
    setFormValue({
      ...formValue,
      [group]: { ...formValue[group], [field]: value }
    })
  }

  // minimum client side validation for unit to enable the onboard button
  const hasValidUnitParams = React.useMemo(() => {
    return validateUnit(formValue.unit as UnitModel);
  }, [formValue.unit]);


  return (
    <div className={classes.add}>
      <Grid container spacing={2}>
        {hasGeneralMessageError && <Grid item xs={12}>
          <Alert severity="error" onClose={() => {
            setShowSuccess(false)
          }}>
            <AlertTitle><strong>There was an error onboarding your location!</strong></AlertTitle>
            <p>{onboardLocation.message}</p>
          </Alert>
        </Grid>}
        {showSuccess && <Grid item xs={12}>
          <Alert severity="success" onClose={() => {
            setShowSuccess(false)
          }}>
            <AlertTitle><strong>Onboarding Successful!</strong></AlertTitle>
            <p>You can now view or edit the new owner, heartland, unit, and form.</p>
            <Box sx={{ width: "100%", textAlign: "left" }}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton component="a" target="_blank"
                                  href={`/users/${onboardLocation.onboardingData?.user.id}`}>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={`New Owner: ${onboardLocation.onboardingData?.user.first_name} ${onboardLocation.onboardingData?.user.last_name}`} />
                    <LaunchIcon />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" target="_blank"
                                  href={`/heartlands/${onboardLocation.onboardingData?.heartland.id}`}>
                    <ListItemIcon>
                      <PointOfSale />
                    </ListItemIcon>
                    <ListItemText primary={`New Heartland: ${onboardLocation.onboardingData?.heartland.domain}`} />
                    <LaunchIcon />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" target="_blank"
                                  href={`/units/${onboardLocation.onboardingData?.unit.id}`}>
                    <ListItemIcon>
                      <Storefront />
                    </ListItemIcon>
                    <ListItemText primary={`New Unit: ${onboardLocation.onboardingData?.unit.franchise_id}`} />
                    <LaunchIcon />
                  </ListItemButton>
                </ListItem>
              </List>
              {hasSyncErrors ? (<Box sx={{ mt: 2 }}>
                <Alert severity="error">
                  <AlertTitle>Sync Errors</AlertTitle>
                  <Typography variant="body2">
                    We had a problem syncing your custom fields. Please try again manually.
                  </Typography>
                </Alert>
              </Box>) : (
                <Typography variant="body2">
                  Sync complete!
                </Typography>
              )}
            </Box>

          </Alert>
        </Grid>}

        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>Onboard New Location</h2>
            </Grid>
            <Grid item xs={12}>
              <h3>New Location :: Owner/Franchisee</h3>
            </Grid>
            {hasUserErrors && <Grid item xs={12} md={12}><ErrorBar error={onboardLocation.error.user} /></Grid>}
            <Grid item xs={6}>
              <TextField
                id="first_name"
                label="First Name"
                variant="outlined"
                fullWidth
                value={formValue.user.first_name || ""}
                onChange={(e) => updateFormField('user', 'first_name', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="last_name"
                label="Last Name"
                variant="outlined"
                fullWidth
                value={formValue.user.last_name || ""}
                onChange={(e) => updateFormField('user', 'last_name', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="username"
                label="Username"
                variant="outlined"
                fullWidth
                value={formValue.user.username || ""}
                onChange={(e) => updateFormField('user', 'username', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                value={formValue.user.email || ""}
                onChange={(e) => updateFormField('user', 'email', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <h3>New Location :: Heartland</h3>
            </Grid>

            {hasHeartlandErrors &&
              <Grid item xs={12} md={12}><ErrorBar error={onboardLocation.error.heartland} /></Grid>}

            <Grid item xs={5}>
              <TextField
                id="domain"
                label="Domain"
                variant="outlined"
                fullWidth
                value={formValue.heartland.domain || ""}
                onChange={(e) => updateFormField('heartland', 'domain', e.target.value)}
              />
            </Grid>
            <Grid item xs={1} md={1}>
              <Tooltip
                title={`The heartland domain is the portion of the store's heartland URL after "https://" and before ".retail" It typically is "bam{store name}" or "bam{store name and state}"`}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="api_key"
                label="API Key"
                variant="outlined"
                fullWidth
                value={formValue.heartland.api_key || ""}
                onChange={(e) => updateFormField('heartland', 'api_key', e.target.value)}
              />
            </Grid>
            <Grid item xs={1} md={1}>
              <Tooltip title={<React.Fragment>
            <span>{"The API key can be generated under My Account >> API Tokens. For more help getting the heartland domain or setting up an API key see "}
              <Link color="inherit" underline="always" rel="noreferrer" target="_blank"
                    href="https://docs.google.com/document/d/1ZHNtfSA2MiDIeB_apGlSCssZ3W6KpcnRBv4Fy8ZHF68/edit?tab=t.0">this article</Link>
            </span>
              </React.Fragment>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <h4>
                Rewards Signup Form
              </h4>
            </Grid>
            <Grid item>
              <Grid item xs={1} md={1}>
                <Tooltip title="Enter a unique short code for this instance's customizable rewards sign up page">
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="rewards_short_code"
                label="Short Code"
                variant="outlined"
                // sx={{
                //   width: { xs: '100%', md: '33%' },
                // }}
                value={formValue.rewards_signup_form.short_code || ""}
                onChange={(e) => updateFormField('rewards_signup_form', 'short_code', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <h3>New Location :: Unit</h3>
            </Grid>
            {hasUnitErrors && <Grid item xs={12} md={12}><ErrorBar error={onboardLocation.error.unit} /></Grid>}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                  <TextField
                    id="franchise_id"
                    label="Franchise ID"
                    variant="outlined"
                    onChange={(e) => updateFormField('unit', 'franchise_id', e.target.value)}
                    fullWidth
                    value={formValue.unit.franchise_id !== '0' ? formValue.unit.franchise_id : ""}
                  />
                </Grid>
                <Grid item md={10} xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateFormField('unit', 'name', e.target.value)}
                    value={formValue.unit.name || ""}
                  />
                </Grid>
                <Grid item md={9} xs={12}>
                  <TextField
                    id="address"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    value={formValue.unit.address || ""}
                    onChange={(e) => updateFormField('unit', 'address', e.target.value)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    id="address2"
                    label="Address (Suite/Unit)"
                    variant="outlined"
                    fullWidth
                    value={formValue.unit.address2 || ""}
                    onChange={(e) => updateFormField('unit', 'address2', e.target.value)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    id="city"
                    label="City"
                    variant="outlined"
                    fullWidth
                    value={formValue.unit.city || ""}
                    onChange={(e) => updateFormField('unit', 'city', e.target.value)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <StateSelector
                    value={formValue.unit.state}
                    country={formValue.unit.country}
                    onSelect={(value) => updateFormField('unit', 'state', value)}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    id="postal_code"
                    label="Postal Code"
                    variant="outlined"
                    fullWidth
                    value={formValue.unit.postal_code || ""}
                    onChange={(e) => updateFormField('unit', 'postal_code', e.target.value)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CountrySelector
                    value={formValue.unit.country}
                    onSelect={(value) => updateFormField('unit', 'country', value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="phone"
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={formValue.unit.phone || ""}
                    onChange={(e) => updateFormField('unit', 'phone', e.target.value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="email"
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    value={formValue.unit.email || ""}
                    onChange={(e) => updateFormField('unit', 'email', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2} mt={12}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                aria-label="Add"
                startIcon={<AddBusinessIcon />}
                fullWidth
                disabled={!hasValidUnitParams}
                onClick={doOnboard}
              >
                Onboard new location
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                aria-label="Cancel"
                startIcon={<CancelIcon />}
                href="/onboard/add-location"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
import * as React from 'react';

import { Box, Typography } from '@mui/material';

const style = {
  marginTop: 4,
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  '& img': {
    width: 300
  }
};

interface Props {
  message?: string;
}

export const NotFound: React.FC<Props> = ({ message }:Props) => {
  return (
    <Box sx={style}>
      {process.env.REACT_APP_MODE === "consumer" ?  
      <>
        <Box> 
          <a href="https://bricksandminifigs.com">
            <img src="/logo_straight.png" alt="Bricks & Minifigs"/>
          </a>
        </Box>
        <Typography variant="h4" sx={{mb: 4}}>
        {message ? message : <>Sorry, we could not find what you were looking for!</> }
        </Typography>
        <Box> 
          <a href="https://bricksandminifigs.com">Take me back to Bricks & Minifigs</a>
        </Box>
      </>:
      <img src="/notfound.png" alt="Not Found" />}
      
    </Box>
  );
};
import * as React from 'react';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useHeartlandsList } from "api";
import { HeartlandModel } from 'model';
import { api } from 'lib';

interface Props {
  value: HeartlandModel | undefined;
  onSelect?: (heartland: HeartlandModel) => void;
};

export const HeartlandSelector: React.FC<Props> = ({ value, onSelect }: Props) => {
  const { heartlands, status, list } = useHeartlandsList();
  const [selectedHeartland, setSelectedHeartland] = React.useState<HeartlandModel | undefined>(value);

  const handleChange = (event: SelectChangeEvent) => {
    const heartlandId: number = +event.target.value;
    const heartland = heartlands.find(u => u.id === heartlandId);
    if (heartland) {
      setSelectedHeartland(heartland);
    }
  };

  const callOnSelect = React.useCallback(() => {
    if (onSelect && selectedHeartland) {
      onSelect(selectedHeartland);
    }
  }, [onSelect, selectedHeartland]);

  React.useEffect(() => {
    callOnSelect();
  }, [selectedHeartland, callOnSelect]);

  React.useEffect(() => setSelectedHeartland(value), [value, setSelectedHeartland]);
  React.useEffect(() => {
    list()
  }, [list]);

  if (status === api.error) {
    return (<div>Unable to load heartland instances</div>);
  } else if (status === api.loading) {
    return (<div style={{ width: 800 }}><CircularProgress /></div>);
  } else if (status === api.success && heartlands) {
    return (
      <FormControl fullWidth>
        <InputLabel id="heartland-select-label">Heartland</InputLabel>
        <Select
          labelId="heartland-select-label"
          id="heartland"
          value={`${selectedHeartland ? selectedHeartland.id : 0}`}
          onChange={handleChange}
          label="Heartland"
        >
          <MenuItem value={0}>------------------</MenuItem>
          {heartlands.map((heartland: HeartlandModel) =>
            <MenuItem key={heartland.id} value={`${heartland.id}`}>{heartland.domain}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  } else {
    return (<>{status}</>);
  }
};

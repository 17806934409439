import {
  accountFormDescriptionStyle,
  accountFormRowStyle,
  accountFormStyles,
} from "../../components/styles";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  add: {
    margin: theme.spacing(2),
    textAlign: 'left',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    '& .container': {
      display: 'flex',
      flexGrow: 1,
    },
    '& .main': {
      flexGrow: 1,
      marginRight: theme.spacing(4),
    },
    '& .sidebar': {
      minWidth: 300,
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
    '& .input': {
      margin: theme.spacing(3, 0),
    },
    '& .row': {
      margin: theme.spacing(2, 0),
    },
    '& .importContainer': {
      marginTop: theme.spacing(4),
    },
    '& .importResults': {
      marginTop: theme.spacing(2),
    },
  }})
);
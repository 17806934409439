import * as React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';

import { SeriesMinifigModel } from 'model';
import { RebrickableImage } from 'components';
import { CMFLinks } from './cmf_links';
import { CMFSummary } from './cmf_summary';
import { CMFBricklinkPrices } from './cmf_bricklink_prices';
import { CMFMyStoreInfo } from './cmf_my_store_info';
import { CMFRecentBuys } from './cmf_recent_buys';

interface Props {
  minifig: SeriesMinifigModel | null;
}

export const CMFCard: React.FC<Props> = ({ minifig }: Props) => {
  if (!minifig) {
    return <></>;
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5.5}>
          <Typography
            variant='h6'
            sx={{
              mb: 1,
              textAlign: 'center',
              fontWeight: 700,
            }}
          >
            <Link
              href={`/cmf/${minifig.bricklink_id}`}
              sx={{
                display: 'inline-block',
              }}
            >
              {minifig.bricklink_id} {minifig.name}
            </Link>
          </Typography>
          <CMFSummary minifig={minifig} />
          <CMFLinks minifig={minifig} displayDetails />
          <RebrickableImage
            imageUrl={minifig.rebrickable_image_url}
            imageAlt={minifig.name}
          />
        </Grid>
        <Grid item xs={6.5}>
          <CMFBricklinkPrices minifig={minifig} />
          <CMFMyStoreInfo minifig={minifig} />
          <CMFRecentBuys minifig={minifig} />
        </Grid>
      </Grid>
    </Box>
  );
};

import * as React from 'react';
import { Grid } from '@mui/material';

interface Props {
  imageUrl: string | null;
  imageAlt: string;
}

export const RebrickableImage: React.FC<Props> = ({
  imageUrl,
  imageAlt,
}: Props) => {
  return (
    <Grid item sx={{ mb: 2 }}>
      <Grid container justifyContent='center'>
        <span
          style={{
            display: 'inline-block',
            height: '100%',
            verticalAlign: 'middle',
          }}
        ></span>
        <img
          src={imageUrl ? imageUrl : './unavailable.png'}
          alt={imageAlt}
          style={{ maxWidth: 370, maxHeight: 370, verticalAlign: 'middle' }}
          onError={(e) => (e.currentTarget.src = '/unavailable.png')}
        />
      </Grid>
    </Grid>
  );
};

import { StatusCodes } from 'http-status-codes';
import { api } from 'lib';
import * as React from 'react';

interface Props {
  status: string;
  verifyToken: (email: string, code: string) => void;
  setStatus: (value: boolean) => void;
  resetStatus: () => void;
}

interface State {
  status: string;
}

export const useVerifyToken = (): Props => {
  const [state, setState] = React.useState<State>({ status: api.idle });

  const verify = React.useCallback(async (email: string, token: string) => {
    const payload = { email: email, token: token };
    setState({ status: api.loading });
    const resp = await api.post(`/api/auth/verify`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success });
    } else {
      setState({ status: api.error });
    }
  }, []);

  const setStatus = React.useCallback((value: boolean) => setState({ status: value ? api.success : api.error }), []);
  const resetStatus = React.useCallback(() => setState({ status: api.idle }), []);

  return {
    status: state.status,
    verifyToken: verify,
    setStatus: setStatus,
    resetStatus: resetStatus,
  };
};
import * as React from 'react';
import { api } from 'lib';

export const useRetry = (audit_id: number) => {
  const refresh = React.useCallback(() => {
    api.post(`/api/audits/${audit_id}/retry`, {});
  }, [audit_id]);

  return refresh;
};

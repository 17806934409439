import * as React from 'react';
import { Grid, Typography } from '@mui/material';

interface Props {
  warnings: string[];
}

export const WarningDisplay: React.FC<Props> = ({ warnings }) => {
  return (
    <Grid
      container
      flexDirection='column'
      xs={12}
      sx={{
        bgcolor: '#ff776e',
        my: 2,
        p: 1,
        pl: 3,
        borderRadius: 2,
        color: 'white',
      }}
    >
      {warnings.map((warning, index) => (
        <Grid item key={index}>
          {warning}
        </Grid>
      ))}
    </Grid>
  );
};

import { CircularProgress, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from '@mui/material';
import { useGetTradeVendors } from 'api';
import { api, SessionContext, TSessionContext } from 'lib';
import * as React from 'react';

interface Props {
  vendorId: string;
  onSelect: (vendor:any) => void;
  label?: string;
}

export const VendorSelector:React.FC<Props> = ({vendorId, onSelect, label = ''}:Props) => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { getTradeVendors } = useGetTradeVendors();
  const [selected, setSelected] = React.useState<string>(vendorId);

  React.useEffect(() => {
    if (currentHeartland) {
      getTradeVendors.list(currentHeartland.id);
    }
  }, [currentHeartland]);

  React.useEffect(() => {
    setSelected(vendorId);
  }, [vendorId]);

  const selectVendor = (event: SelectChangeEvent) => {
    const vendor = getTradeVendors.vendors.find((v) => `${v.id}` === event.target.value);
    if (vendor) {
      setSelected(vendor.id);
      if (onSelect) {
        onSelect(vendor);
      } 
    }
  };

  return (
    <>
    {getTradeVendors.status === api.loading &&
      <CircularProgress />
    }
    {getTradeVendors.status === api.success &&
    <FormControl fullWidth>
      <InputLabel id="vendor-select-label">{label}</InputLabel>
      <Select
        labelId="vendor-select-label"
        fullWidth
        id="vendor"
        value={selected}
        label={label}
        onChange={selectVendor}
      >
        <MenuItem value={0}>------------------</MenuItem>
        {getTradeVendors.vendors.map((vendor:any) =>
          <MenuItem key={vendor.id} value={`${vendor.id}`}>{vendor.public_id} - {vendor.name}</MenuItem>
        )}
      </Select>
      </FormControl>
    }
</>
  );
};
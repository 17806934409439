import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Link,
  LinearProgress,
  Box,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';

import { useGetMinifig } from 'api';
import { NotFound } from 'components';
import { api, SessionContext, TSessionContext } from 'lib';
import { Details, PriceGuide, MyStoreInfo } from './components';
import { MinifigProvider } from './context';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export const MinifigShow: React.FC = () => {
  const { isEmployee, isOwner } = React.useContext(
    SessionContext
  ) as TSessionContext;

  const { status, minifig, getMinifig } = useGetMinifig();
  const { bricklink_id } = useParams();

  const [tab, setTab] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newTab: number) =>
    setTab(newTab);

  React.useEffect(() => {
    if (bricklink_id) {
      getMinifig(bricklink_id);
    }
  }, [bricklink_id, getMinifig]);

  if (status === api.success && minifig) {
    return (
      <>
        <Box sx={{ width: '90%', pt: 2, fontSize: '0.9rem' }}>
          <Box sx={{ mb: 1 }}>
            <Link href='/catalog'>Catalog</Link>
          </Box>
          <Typography variant='h4'>
            {minifig.bricklink_id} {minifig.name}{' '}
          </Typography>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}
          >
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label='Heartland Tabs'
            >
              <Tab label='Details' {...a11yProps(0)} />
              <Tab label='Price Guide' {...a11yProps(1)} />
              {(isEmployee() || isOwner()) && (
                <Tab label='@ My Store' {...a11yProps(2)} />
              )}
            </Tabs>
          </Box>
          <MinifigProvider minifig={minifig}>
            <Box hidden={tab !== 0} id='tabpanel-0'>
              <Details />
            </Box>
            <Box hidden={tab !== 1} id='tabpanel-1'>
              <PriceGuide />
            </Box>
            <Box hidden={tab !== 2} id='tabpanel-2'>
              <MyStoreInfo />
            </Box>
          </MinifigProvider>
        </Box>
      </>
    );
  } else if (status === api.error) {
    return (
      <>
        <div style={{ width: 800, margin: '0 auto', textAlign: 'center' }}>
          <NotFound />
          <h4>
            <Link href='/catalog'>Return to Catalog</Link>
          </h4>
        </div>
      </>
    );
  } else {
    return (
      <div style={{ marginTop: 32, width: 800 }}>
        <LinearProgress />
      </div>
    );
  }
};

import * as React from "react";

import { Dialog, DialogContent, DialogContentText, DialogActions, Button, useMediaQuery, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';

interface Props {
  show: boolean;
  message: string;
  children?: React.ReactNode;
  onClose: (value: boolean) => void;
}

export const ConfirmationDialog: React.FC<Props> = ({ message, onClose, show, children }: Props) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleCancel = () => {
    onClose(false);
    setOpen(false);
  };

  const handleConfirm = () => {
    onClose(true);
    setOpen(false);
  };

  React.useEffect(() => setOpen(show), [show]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
          {message}
          {children &&
            <Box sx={{ my: 2 }}>
              {children}
            </Box>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} autoFocus>
          Yes
        </Button>
        <Button autoFocus onClick={handleCancel}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
import * as React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';

import { useItemLocations } from 'api';
import { BuyContext, TBuyContext } from 'contexts';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AssignLocation: React.FC<Props> = ({ open, onClose }) => {
  const [location, setLocation] = React.useState('');
  const { updateLocations, buy } = React.useContext(BuyContext) as TBuyContext;

  const { itemLocations } = useItemLocations(buy.unit.id);

  React.useEffect(() => {
    itemLocations.getLocations();
  }, [itemLocations.getLocations]);

  const save = () => {
    updateLocations(location);
    onClose();
  };

  const handleLocationUpdate = (location: string) => {
    setLocation(location);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      disableRestoreFocus
      scroll='paper'
      sx={{ minHeight: '800px' }}
    >
      <DialogTitle> Assign Location </DialogTitle>
      <DialogContent>
        <Grid container gap={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography>Please add a location:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id='Locations'
              freeSolo
              options={itemLocations.locations}
              fullWidth
              value={location}
              onChange={(_, newValue) => {
                handleLocationUpdate(newValue || '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Enter Location'
                  value={location}
                  onChange={(e) => handleLocationUpdate(e.target.value)}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-end' gap={2}>
          <Button
            variant='contained'
            color='primary'
            onClick={save}
            disabled={location === ''}
          >
            Save Location
          </Button>
          <Button variant='contained' onClick={onClose}>
            Exit
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

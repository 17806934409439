import * as React from 'react';
import { Grid, Tooltip, Box } from '@mui/material';
import { SearchThumbnail } from 'components/search';

interface Props {
  cardTitle: string;
  pricing: string;
  image: string | null;
  imageAlt: string;
  url: string;
  target: string;
}

export const PreviewCard: React.FC<Props> = ({
  cardTitle,
  pricing,
  image,
  imageAlt,
  url,
  target,
}) => {
  return (
    <Grid item xs={2}>
      <Tooltip title={pricing} placement='top'>
        <Box
          component='a'
          href={url}
          target={target}
          sx={{
            display: 'inline-block',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          <SearchThumbnail
            imageUrl={image}
            imageTitle={imageAlt}
            height={80}
            width={80}
            imageFit='contain'
          >
            {cardTitle}
          </SearchThumbnail>
        </Box>
      </Tooltip>
    </Grid>
  );
};

/**
 * Theis file contains styles shared across components in
 * different directories.
 */

/**
 * Login. forgpt password, and update password forms styles
 */

import { makeStyles } from '@mui/styles';

export const accountFormStyles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #666',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export const accountFormRowStyle = {
    width: '100%',
    marginTop: 2,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    '.error': {
        color: 'error.main',
        fontWeight: 700,
    },
    '.success': {
        color: 'success.main',
        fontWeight: 700,
    }
};

export const accountFormDescriptionStyle = {
    width: '100%',
    marginTop: 2,
    color: 'text.secondary',
    fontSize: '0.9rem',
    textAlign: 'left',
    marginLeft: 'auto',
    marginRight: 'auto',
};

export const useAccountFormStyles = makeStyles((theme) => ({
    box: {
        marginBottom: theme.spacing(2),
    },
    error: {
        margin: theme.spacing(2, 0),
        color: '#CC0000',
        fontWeight: 700,
        textAlign: 'center',
    },
    action: {
        textAlign: 'center',
        margin: theme.spacing(0, 'auto'),
    },
    links: {
        textAlign: 'center',
        margin: theme.spacing(2, 'auto'),
    },
}));

import * as React from "react";
import { api } from "lib";
import { HeartlandModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  error: string;
  heartland: HeartlandModel | null;
}

interface Props {
  getHeartland: State & {
    byId: (heartland_id: number) => void,
    current: () => void,
  };
}

export const useGetHeartland = (): Props => {
  const [state, setState] = React.useState<State>({ status: api.idle, heartland: null, error: '' });

  const byId = React.useCallback(async (heartland_id: number) => {
    setState({ status: api.loading, heartland: null, error: '' });
    const resp = await api.get(`/api/heartlands/${heartland_id}`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, heartland: resp.body, error: '' });
    } else {
      setState({ status: api.error, heartland: null, error: resp.body });
    }
  }, []);


  const current = React.useCallback(async () => {
    setState({ status: api.loading, heartland: null, error: '' });
    const resp = await api.get(`/api/heartlands/current`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, heartland: resp.body, error: '' });
    } else {
      setState({ status: api.error, heartland: null, error: resp.body });
    }
  }, []);

  return {
    getHeartland: {
      ...state,
      byId,
      current,
    }
  };
};

import * as React from 'react';
import {
  Alert,
  autocompleteClasses,
  Box,
  Button,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReactToPrint from 'react-to-print';
import { styled } from '@mui/material/styles';

import { useLoadBuySettings } from 'api';
import { ErrorBar, InfoBar } from 'components';
import { BuyProvider } from 'contexts';
import { SessionContext, TSessionContext, api } from 'lib';
import { BuyModel, InfoBarModel } from 'model';

import {
  NewSetSectionInput,
  MinifigSectionInput,
  CMFSectionInput,
  UsedSetSectionInput,
  TopBar,
  BulkSectionInput,
  MiscSectionInput,
  AnimalSectionInput,
} from './components';

const sectionStyle = { width: "100%", mb: 4 };
interface Props {
  buy?: BuyModel;
}

export const BuyInput: React.FC<Props> = ({ buy }: Props) => {
  const { currentUnit, currentUser } = React.useContext(
    SessionContext
  ) as TSessionContext;
  const navigate = useNavigate();
  const { load, settings, status, error } = useLoadBuySettings();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const flags = useFlags();

  const componentRef = React.useRef<HTMLDivElement>(null);

  const StyleOnPrint = styled('div')({
    '@media print': {
      margin: '48px 36px 0 36px',
    },
  });

  const onSave = (status: boolean, message: string) => {
    if (status) {
      setInfoBar({ status: 'success', message: 'Saved' });
    } else {
      setInfoBar({ status: 'error', message: message });
    }
  };

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    } else {
      navigate('/login');
    }
  }, [currentUnit, currentUser, load, navigate]);

  const clearInfoBar = () => setInfoBar(null);

  if (status === api.success && currentUser && currentUnit && settings) {
    return (
      <BuyProvider creator={currentUser} unit={currentUnit} buy={buy} onSave={onSave}>
        {infoBar &&
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        }
        <TopBar settings={settings} />
        <Box sx={{ width: "100%" }}>
          <div ref={componentRef}>
            <StyleOnPrint >
              <Box sx={sectionStyle}>
                <MinifigSectionInput />
              </Box>
              <Box sx={sectionStyle}>
                <CMFSectionInput />
              </Box>
              <Box sx={sectionStyle}>
                <NewSetSectionInput />
              </Box>
              <Box sx={sectionStyle}>
                <UsedSetSectionInput />
              </Box>
              <Box sx={sectionStyle}>
                <AnimalSectionInput />
              </Box>
              <Box sx={sectionStyle}>
                <BulkSectionInput />
              </Box>
              <Box sx={sectionStyle}>
                <MiscSectionInput />
              </Box>
            </StyleOnPrint>
          </div>
        </Box>
        <Box sx={{ mb: 36 }}>
          <ReactToPrint
            trigger={() => (
              <Tooltip title='Prints set info without pricing'>
                <Button
                  variant='contained'
                  size='small'
                  fullWidth
                  color='primary'
                >
                  Print
                </Button>
              </Tooltip>
            )}
            content={() => componentRef.current}
          />
        </Box>
      </BuyProvider>
    );
  } else if (status === api.loading || status === api.idle) {
    return <LinearProgress />;
  } else if (status === api.error) {
    return <ErrorBar error={error} />;
  } else {
    if (buy) {
      return <>Sorry this buy is currently unavailable to view</>;
    }
    return <>Sorry you do not have access to create a new buy</>;
  }
};

import * as React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';

interface CurrencyInputProps {
  label: string;
  value: number;
  defaultValue?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium' | undefined;
  fullWidth?: boolean;
  width?: number;
  helperText?: string | undefined;
  disabled?: boolean;
  error?: boolean;
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  width,
  defaultValue,
  helperText,
  label,
  value,
  onChange,
  size = 'small',
  fullWidth = false,
  disabled = false,
  error = false,
}: CurrencyInputProps) => {
  const [state, setState] = React.useState(value);
  const doUpdate = React.useMemo(() => debounce(onChange, 500), [onChange]);

  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(parseFloat(e.target.value) || 0);
    doUpdate(e);
  };

  React.useEffect(() => {
    if (state !== value) {
      setState(value);
    }
  }, [value]);

  const sx = React.useMemo(() => {
    if (fullWidth) {
      return { p: 0, m: 0 };
    } else {
      return { width: width ? width : 80, p: 0, m: 0 };
    }
  }, [width, fullWidth]);

  return (
    <TextField
      label={label}
      value={state}
      defaultValue={defaultValue}
      type='number'
      onChange={handleUpdate}
      fullWidth={fullWidth}
      size={size}
      onFocus={(event) => event.target.select()}
      disabled={disabled}
      error={error}
      inputProps={{
        step: '1',
      }}
      InputProps={{
        style: { fontSize: '0.9rem' },
        startAdornment: (
          <InputAdornment position='start' sx={{ padding: 0, margin: 0 }}>
            $
          </InputAdornment>
        ),
      }}
      sx={sx}
      helperText={helperText}
    />
  );
};

import * as React from 'react';
import { AuditModel } from 'model';
import { api } from 'lib';
import { StatusCodes } from 'http-status-codes';

interface AuditProps {
  status: string;
  error: string;
  audit: AuditModel | null;
  get: (buy_id: string) => void;
}

const defaultState = {
  status: api.idle,
  error: '',
  audit: null,
};

export const useGetAudit = (): AuditProps => {
  const [state, setState] = React.useState(defaultState);

  const getAudit = React.useCallback(async (buy_id: string) => {
    setState({ status: api.loading, audit: null, error: '' });
    const resp = await api.get(`/api/audits/${buy_id}`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, audit: resp.body, error: '' });
    } else if (resp.status === StatusCodes.NOT_FOUND) {
      setState({ status: api.error, audit: null, error: 'Buy not found.' });
    } else {
      setState({ status: api.error, audit: null, error: resp.body.error });
    }
  }, []);

  return {
    status: state.status,
    error: state.error,
    audit: state.audit,
    get: getAudit,
  };
};

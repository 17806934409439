import * as React from 'react';
import { Button, Grid, Paper, TextField, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useLoadBuySettings } from 'api';
import { SessionContext, TSessionContext, api } from 'lib';
import { InfoBar } from 'components';
import { BuySettingModel, InfoBarModel } from 'model';
import { useSaveBuySettings } from 'api/buy/save_settings';

export const GeneralBuySettingsTab: React.FC = () => {
  const { currentUnit, currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { load, settings: s, status } = useLoadBuySettings();
  const { saveBuySettings } = useSaveBuySettings();
  const [settings, setSettings] = React.useState<BuySettingModel | undefined>();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    }
  }, [currentUnit, currentUser, load]);

  React.useEffect(() => {
    if (status === api.success && s?.settings) {
      setSettings(s.settings);
    }
  }, [s, status]);

  React.useEffect(() => {
    if (saveBuySettings.status === api.success && saveBuySettings.settings?.settings) {
      setSettings(saveBuySettings.settings.settings);
    }
  }, [saveBuySettings]);


  const updateSetting = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: value };
      setSettings(newSettings);
    }
  };

  React.useEffect(() => {
    if (saveBuySettings.status === api.success) {
      setInfoBar({ status: "success", message: saveBuySettings.msg });
    } else if (saveBuySettings.status === api.error) {
      setInfoBar({ status: "error", message: saveBuySettings.msg });
    } else {
      setInfoBar(null);
    }
  }, [saveBuySettings.status, saveBuySettings.msg, setInfoBar]);

  const resetSettings = () => {
    if (currentUnit) {
      saveBuySettings.resetSettings(currentUnit.id, 'misc');
    }
  };

  const saveSettings = () => {
    if (settings) {
      saveBuySettings.save({ ...s, settings: settings });
    }
  };

  if (settings) {
    return (
      <Paper sx={{ p: 2, height: "100%" }}>
        <Grid container alignItems="top" spacing={1}>
          {infoBar &&
            <Grid item xs={12} md={12}>
              <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
            </Grid>
          }
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={1.5}>
              <Grid item xs={0.30}>
                <Tooltip title={`List of cash payment methods your  store supports. Enter a comma separated list`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Cash Payment Methods</Grid>
              <Grid item xs={6}>
                <TextField
                  label=""
                  fullWidth
                  value={settings.cash_types}
                  onChange={(e) => updateSetting('cash_types', e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} sx={{ pl: 2 }}>
              <Grid item xs={12} md={12}>
                <Button variant="contained" fullWidth onClick={saveSettings}>Save</Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button fullWidth onClick={resetSettings}>Reset to default settings</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper >
    );
  } else {
    return (<></>);
  }
};
import * as React from 'react';
import { api } from 'lib';
import { StatusCodes } from 'http-status-codes';

interface State {
  status: string;
  error: string;
  locations: string[];
}

interface Props {
  itemLocations: State & {
    getLocations: () => void;
  };
}

const defaultState = {
  status: api.idle,
  error: '',
  locations: [],
};

export const useItemLocations = (unit_id: number): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const getLocations = React.useCallback(async () => {
    const params = {
      unit_id: unit_id.toString(),
    };

    const response = await api.get('/api/buys/item_locations', params);

    if (response.status === StatusCodes.OK) {
      setState({
        status: api.success,
        locations: response.body.locations,
        error: '',
      });
    } else {
      setState({
        status: api.error,
        locations: [],
        error: response.body.error,
      });
    }
  }, [unit_id]);

  return {
    itemLocations: {
      ...state,
      getLocations,
    },
  };
};

import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import {
  AuditSearchParamInputType,
  AuditSearchParamType,
  AuditSearchFilter,
} from './types';
import { AuditTypeSelector, AuditStatusSelector } from 'components';
import { HeartlandAutocomplete } from 'components/heartland_autocomplete';

interface Props {
  displayHeartlandFilter: boolean;
  filters: AuditSearchFilter;
  updateFilter: (
    paramKey: AuditSearchParamType,
    paramValue: AuditSearchParamInputType
  ) => void;
}

export const AuditSearchHeader: React.FC<Props> = ({
  displayHeartlandFilter,
  filters,
  updateFilter,
}) => {
  return (
    <Grid container flexDirection='row' alignItems='center' gap={3}>
      {displayHeartlandFilter && (
        <Grid item>
          <HeartlandAutocomplete
            value={filters.heartland_id}
            onSelect={(heartland_id) =>
              updateFilter('heartland_id', heartland_id)
            }
          />
        </Grid>
      )}
      <Grid item>
        <AuditTypeSelector
          value={filters.audit_type}
          onSelect={(audit_type) => updateFilter('audit_type', audit_type)}
        />
      </Grid>
      <Grid item>
        <AuditStatusSelector
          value={filters.audit_status}
          onSelect={(audit_status) =>
            updateFilter('audit_status', audit_status)
          }
        />
      </Grid>
    </Grid>
  );
};

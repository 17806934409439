import * as React from 'react';
import { api } from 'lib';
import { MonthlyBuy, UnitModel } from 'model';
import { StatusCodes } from 'http-status-codes';

interface Props {
  monthlyBuysList: {
    status: string;
    error: string;
    buys: MonthlyBuy[];
  };
  getMonthlyBuysList: (unit: UnitModel | null, year?: number) => void;
}

interface State {
  status: string;
  error: string;
  buys: MonthlyBuy[];
}

const defaultState: State = {
  status: api.idle,
  error: '',
  buys: [],
};

export const useMonthlyBuysList = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = React.useCallback(
    async (unit: UnitModel | null = null, year = new Date().getFullYear()) => {
      setState((prevState) => ({ ...prevState, status: api.loading }));
      const options: Record<string, string> = {
        year: `${year}`,
      };

      if (unit) {
        options.unit = unit.id.toString();
      }

      const resp = await api.get('/api/reports/buys/monthly', options);
      if (resp.status === StatusCodes.OK) {
        setState({ status: api.success, buys: resp.body.buys, error: '' });
      } else {
        setState({ status: api.error, buys: [], error: resp.body });
      }
    },
    [setState]
  );

  return {
    monthlyBuysList: {
      ...state,
    },
    getMonthlyBuysList: get,
  };
};

import * as React from 'react';
import { Grid, TextField, Box, Button, Tooltip } from '@mui/material';

import {
  RebrickableImage,
  MinifigLinks,
  MinifigSummary,
  PreviewLayout,
  PreviewCard,
} from 'components';
import { SessionContext, TSessionContext, formatCurrency } from 'lib';
import { MinifigContext, TMinifigContext } from '../context';

export const Details: React.FC = () => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const { minifig, update, save, imgUpdate } = React.useContext(
    MinifigContext
  ) as TMinifigContext;

  const [newImageUrl, setNewImageUrl] = React.useState('');

  const clickUpdateImage = () => {
    imgUpdate(newImageUrl, minifig.id, 'new');
    setNewImageUrl('');
  };

  return (
    <>
      <Grid container spacing={4} sx={{ mb: 8 }}>
        <Grid item xs={4}>
          <Grid container gap={1} justifyContent='flex-end'>
            <Grid item xs={12}>
              <RebrickableImage
                imageUrl={minifig.rebrickable_image_url}
                imageAlt={minifig.name}
              />
            </Grid>
            {isAdmin() && (
              <>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    label='New Image URL'
                    value={newImageUrl}
                    onChange={(e) => setNewImageUrl(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Tooltip title='This will replace the existing image.'>
                    <Button variant='contained' onClick={clickUpdateImage}>
                      Update Image
                    </Button>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {isAdmin() ? (
            <Grid container sx={{ mb: 4 }} spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label='Release Year'
                  value={minifig.release_year}
                  onChange={(e) => update('release_year', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label='Num Parts'
                  value={minifig.num_parts}
                  onChange={(e) => update('num_parts', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label='Bricklink ID'
                  value={minifig.bricklink_id}
                  onChange={(e) => update('bricklink_id', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label='Brickset ID'
                  value={minifig.brickset_id}
                  onChange={(e) => update('brickset_id', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label='Brick Owl ID'
                  value={minifig.brickowl_id}
                  onChange={(e) => update('brickowl_id', e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  fullWidth
                  label='Rebrickable ID'
                  value={minifig.rebrickable_id}
                  onChange={(e) => update('rebrickable_id', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'right' }}>
                  <Button variant='contained' onClick={save}>
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <>
              <MinifigLinks minifig={minifig} />
              <MinifigSummary minifig={minifig} />
            </>
          )}
          {minifig.sets.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <PreviewLayout headerText='Associated Sets'>
                {minifig.sets.map((set) => (
                  <PreviewCard
                    cardTitle={set.bricklink_id}
                    pricing={`Avg: ${formatCurrency(
                      set.used_bricklink_prices.avg
                    )}; Min: ${formatCurrency(
                      set.used_bricklink_prices.min
                    )}; Max: ${formatCurrency(set.used_bricklink_prices.max)}`}
                    image={set.rebrickable_image_url}
                    imageAlt={set.name}
                    url={`/sets/${set.bricklink_id}`}
                    target='_page'
                  />
                ))}
              </PreviewLayout>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

import {
  accountFormDescriptionStyle,
  accountFormRowStyle,
  accountFormStyles,
  useAccountFormStyles
} from "../../components/styles";

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    textAlign: 'right',
    margin: theme.spacing(1, 0),
  },
  show: {
    margin: theme.spacing(2, 0),
    textAlign: 'left',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
  },
  profile: {
    flexGrow: 1,
    marginRight: theme.spacing(4),
  },
  sidebar: {
    minWidth: 200,
    marginLeft: 2,
  },
  input: {
    margin: theme.spacing(3, 0),
  },
  add: {
    margin: theme.spacing(2),
    textAlign: 'left',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    '& .container': {
      display: 'flex',
      flexGrow: 1,
    },
    '& .main': {
      flexGrow: 1,
      marginRight: theme.spacing(4),
    },
    '& .sidebar': {
      minWidth: 300,
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
    '& .input': {
      margin: theme.spacing(3, 0),
    },
    '& .row': {
      margin: theme.spacing(2, 0),
    },
    '& .importContainer': {
      marginTop: theme.spacing(4),
    },
    '& .importResults': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const formStyles = accountFormStyles;

export const formRowStyle = accountFormRowStyle;

export const formDescriptionStyle = accountFormDescriptionStyle;

export const useFormStyles = useAccountFormStyles;


export default useStyles;
import * as React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { descriptionStyle, rowStyle, } from './styles';
import { useResetPassword } from './api/savepassword';

export const ResetPasswordForm: React.FC = () => {

    const {
        password,
        confirmPassword,
        passwordsMatch,
        validPassword,
        disable,
        passwordError,
        updatePassword,
        updateConfirmPassword,
        doSaveNewPassword,
        saveMessage,
        saveError
    } = useResetPassword();

  return (
    <Box sx={rowStyle}>
      <h2>New Password</h2>
      <Box sx={descriptionStyle}>
        Password must contain at least 7 characters and include at least 1 lower-case, 1 upper-case and 1 numeric character.
      </Box>
      <Box sx={rowStyle}>
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          value={password}
          onChange={updatePassword}
        />
      </Box>
      <Box sx={rowStyle}>
        <TextField
          id="confirmPassword"
          label="Confirm Password"
          variant="outlined"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={updateConfirmPassword}
        />
      </Box>
      <Box sx={rowStyle}>
        {!validPassword && <div className="error">{passwordError}</div>}
        {!passwordsMatch && <div className="error">Passwords do not match!</div>}
        {saveError && <div className="error">{saveMessage} Please try again.</div>}
      </Box>
      <Box sx={rowStyle}>
        <Button variant="contained" onClick={doSaveNewPassword} disabled={disable}>RESET PASSWORD</Button>
      </Box>
    </Box>
  );
};

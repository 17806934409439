import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';

interface Props {
  date: string;
  onChange?: (newValue: string) => void;
}

export const DatePicker = (props: Props) => {

  const value = React.useMemo<Dayjs | null>(() => dayjs(props.date), [props.date]);

  const handleChange = (value: Dayjs | null) => {
    if (props.onChange && value) {
      props.onChange(dayjs(value).format('YYYY-MM-DD'));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDatePicker value={value} onChange={handleChange} />
    </LocalizationProvider>
  );
};
import * as React from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Checkbox,
  Checkbox as MUICheckbox,
  Link,
  Menu,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { SeriesMinifigBuyLineModel } from 'model';
import {
  CMFCard,
  CardModal,
  ConditionSelector,
  CurrencyInput,
  EbayCMFLink,
  HideOnPrint,
  HideOnScreen,
  NotesInput,
  QuantityInput,
} from 'components';
import { SessionContext, TSessionContext, formatCurrency } from 'lib';
import { BuyContext, TBuyContext } from 'contexts';
import PriceButton from '../price_button';

interface Props {
  row: SeriesMinifigBuyLineModel;
}

export const CMFBuyEntryRow: React.FC<Props> = ({ row }: Props) => {
  const { deleteCMF, updateCMF } = React.useContext(BuyContext) as TBuyContext;
  const { currentHeartland } = React.useContext(
    SessionContext
  ) as TSessionContext;
  const [showCard, setShowCard] = React.useState<boolean>(false);
  const handleCloseCard = () => setShowCard(false);
  const [checked, setChecked] = React.useState<boolean>(false);

  const update = React.useCallback(
    (prop: string, value: string | number | boolean) => {
      updateCMF({ ...row, [prop]: value });
    },
    [updateCMF, row]
  );

  const remove = React.useCallback((id: number) => deleteCMF(id), [deleteCMF]);

  const adjValue = React.useMemo(
    () => row.value - row.value_discount,
    [row.value, row.value_discount]
  );

  return (
    <TableRow
      key={row.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <img
          src={row.series_minifig.rebrickable_image_url}
          alt={row.series_minifig.name}
          style={{ display: 'block', maxWidth: 64, maxHeight: 64 }}
        />
      </TableCell>
      <TableCell>
        <Box sx={{ mb: 2 }}>
          <Link onClick={() => setShowCard(true)}>
            {row.series_minifig.bricklink_id} - {row.series_minifig.name}
          </Link>
          <CardModal open={showCard} onClose={handleCloseCard}>
            <CMFCard minifig={row.series_minifig} />
          </CardModal>
        </Box>
        <HideOnPrint>
          <NotesInput
            value={row.notes}
            onChange={(value: string) => update('notes', value)}
          />
        </HideOnPrint>
        <HideOnScreen>{row.notes}</HideOnScreen>
      </TableCell>
      <TableCell>
        <EbayCMFLink item={row.series_minifig} />
        <br />
        <Link
          target='_bl'
          href={`https://www.bricklink.com/v2/catalog/catalogitem.page?S=${row.series_minifig.bricklink_id}`}
        >
          Bricklink
        </Link>
      </TableCell>
      <TableCell>
        <ConditionSelector
          value={row.condition}
          onSelect={(value) => update('condition', value)}
        />
      </TableCell>
      <TableCell align='center'>
        <Checkbox
          checked={row.overstock}
          onChange={(e) => update('overstock', e.target.checked)}
        />
      </TableCell>
      <TableCell align='center'>
        <QuantityInput
          quantity={row.quantity}
          onChange={(value) => update('quantity', value)}
        />
      </TableCell>
      <TableCell>
        <HideOnPrint>
          <CurrencyInput
            value={row.value}
            label={''}
            onChange={(e) => update('value', +e.target.value)}
            error={row.value === 0}
          />
          <PriceButton
            legoSet={row.series_minifig}
            onUpdate={(value) => update('value', value)}
            department='Minifigs'
          />
        </HideOnPrint>
      </TableCell>
      <TableCell align='right'>
        <HideOnPrint>{formatCurrency(adjValue, false)}</HideOnPrint>
      </TableCell>
      <TableCell align='right'>
        <HideOnPrint>
          {formatCurrency(row.quantity * adjValue, false)}
        </HideOnPrint>
      </TableCell>
      <TableCell>
        <HideOnPrint>
          <IconButton aria-label='delete' onClick={() => remove(row.key)}>
            {' '}
            <DeleteIcon />
          </IconButton>
          <MUICheckbox onChange={(e) => setChecked(e.target.checked)} />
        </HideOnPrint>
      </TableCell>
    </TableRow>
  );
};

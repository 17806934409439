import { AuditType } from 'model';
import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { displayType } from 'lib';

interface Props {
  value?: AuditType | null;
  onSelect?: (audit_type: AuditType | null) => void;
}

export const AuditTypeSelector: React.FC<Props> = ({
  value,
  onSelect,
}: Props) => {
  const auditTypes = ['department'].sort((a, b) => a.localeCompare(b)); // Determine a better way to retrieve audit types than a hardcoded one value list...
  const [selectedType, setSelectedType] = React.useState(value);

  const select = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value as AuditType);
    if (onSelect) {
      onSelect(event.target.value as AuditType);
    }
  };

  return (
    <FormControl>
      <InputLabel id='audit-type-select-label'>Audit Type</InputLabel>
      <Select
        labelId='audit-type-select-label'
        value={selectedType ? selectedType : 'any'}
        id='audit-types'
        label='Audit Type'
        onChange={select}
        sx={{ width: 150 }}
      >
        <MenuItem value={'any'}>All Types</MenuItem>
        {auditTypes.map((auditType) => (
          <MenuItem key={auditType} value={auditType}>
            {displayType(auditType)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

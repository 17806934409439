import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';

import { AuditModel } from 'model';
import { AuditInfo } from '../info';
import { AuditComments } from '../comments';

interface Props {
  audit: AuditModel;
}

export const AuditDashboard: React.FC<Props> = ({ audit }) => {
  // Eventually this should be refactored into a context so there's no prop drilling.
  const navigate = useNavigate();
  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ mt: 2, ml: 2, mb: 10, width: '90%' }}>
      <Grid container flexDirection='column' spacing={4}>
        <Grid item>
          <Typography
            component='a'
            onClick={onBackClick}
            sx={{
              cursor: 'pointer',
            }}
          >
            {'< Back to Audits'}
          </Typography>
        </Grid>
        <AuditInfo audit={audit} />
        <AuditComments audit={audit} />
      </Grid>
    </Box>
  );
};

import * as React from 'react';
import { debounce } from "lodash";
import { useNavigate } from 'react-router-dom';
import { Backdrop, Button, CircularProgress, Grid, Link, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { SessionContext, TSessionContext, api } from 'lib';
import { ErrorBar } from 'components';
import { CustomerImportModel } from 'model';
import { CustomerInput, useAddCustomer, useImportSearch } from '../../api/customer';
import useStyles from './styles';

interface ImportCheckProps {
  query: string;
  onImport: (customer: CustomerImportModel) => void;
}

const initialCustomer = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
};

const ImportCheck: React.FC<ImportCheckProps> = (props: ImportCheckProps) => {
  const { query, onImport } = props;
  const { status, error, customers, search } = useImportSearch();
  const [customer, setCustomer] = React.useState<CustomerImportModel | null>(null);
  const doSearch = React.useMemo(() => debounce(search, 500), [search]);

  React.useEffect(() => {
    if (query.length > 1) {
      doSearch(query);
    }
  }, [query, doSearch]);

  const doImport = () => {
    if (customer) {
      onImport(customer);
    }
  };

  React.useEffect(() => {
    if (status === api.success && customers.length === 1) {
      setCustomer(customers[0]);
    }
  }, [status, customers]);

  if (status === api.loading) {
    return (<CircularProgress />);
  } else if (customer) {
    return (
      <div className="importContainer">
        <h4>Possible match in Heartland</h4>
        <div className="row">
          <strong>{customer.id} </strong><br />
        </div>
        <div className="row">
          {customer.first_name} {customer.last_name}
        </div>
        <div className="row">
          {customer.email}
        </div>
        <div className="row">
          {customer.phone}
        </div>
        <div className="row">
          <Button
            variant="contained"
            aria-label="Link"
            startIcon={<InsertLinkIcon />}
            onClick={doImport}
            fullWidth
          >
            Link Customer
          </Button>
        </div>
      </div>
    );
  } else {
    return (<>{error}</>);
  }
};

export const CustomerAdd: React.FC = () => {
  const classes = useStyles();
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const [customer, setCustomer] = React.useState<CustomerInput>(initialCustomer);
  const [importFlag, setImportFlag] = React.useState<boolean>(false);
  const [importQuery, setImportQuery] = React.useState<string>('');
  const navigate = useNavigate();
  const { add, customer: newCustomer, status, error } = useAddCustomer();
  const [open, setOpen] = React.useState(false);

  const updatePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({ ...customer, phone: event.target.value });
    if (!isAdmin()) {
      setImportQuery('1' + event.target.value);
    }
  };

  const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({ ...customer, email: event.target.value });
    if (!isAdmin()) {
      setImportQuery(event.target.value);
    }
  };

  const updateFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({ ...customer, first_name: event.target.value });
  };

  const updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({ ...customer, last_name: event.target.value });
  };

  const doAdd = () => {
    setOpen(true);
    add(customer);
  };

  const processImport = (customer: CustomerImportModel) => {
    setCustomer({
      first_name: customer.first_name,
      last_name: customer.last_name,
      phone: customer.phone,
      email: customer.email,
      heartland_id: customer.id,
    });
    setImportFlag(true);
  };

  React.useEffect(() => {
    if (status === api.error) {
      setOpen(false);
    } else if (status === api.success && newCustomer) {
      setOpen(false);
      navigate(`/customers/${newCustomer.id}`, { state: { status: 'info', message: 'Customer sucessfully created.' } });
    }
  }, [status, newCustomer, navigate, setOpen]);

  return (
    <div className={classes.add}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h2> <Link href="/customers" className="nav">Customers</Link> :: New Customer</h2>
        </Grid>
        {status === api.error &&
          <Grid item xs={12} md={12}><ErrorBar error={error} /></Grid>
        }
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="first_name"
                label="First Name"
                variant="outlined"
                fullWidth
                value={customer ? customer.first_name : ""}
                onChange={updateFirstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="last_name"
                label="Last Name"
                variant="outlined"
                fullWidth
                value={customer ? customer.last_name : ""}
                onChange={updateLastName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                value={customer ? customer.email : ""}
                onChange={updateEmail}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="phone"
                label="Phone"
                variant="outlined"
                fullWidth
                value={customer ? customer.phone : ""}
                onChange={updatePhone}
              />
            </Grid>
            {importFlag &&
              <TextField
                label="Heartland ID"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                value={customer ? customer.heartland_id : ""}
              />
            }
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            {importFlag &&
              <Grid item xs={12}>
                <ImportCheck query={importQuery} onImport={processImport} />
              </Grid>
            }
            <Grid item xs={12}>
              <Button
                variant="contained"
                aria-label="Add"
                startIcon={<PersonAddIcon />}
                onClick={doAdd}
                fullWidth
              >
                Add Customer
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                aria-label="Cancel"
                startIcon={<CancelIcon />}
                href="/customers"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  );
};
import * as React from 'react';
import { Box, Typography, CircularProgress, Paper, Table, TableCell, TableContainer, TableRow, TableHead, TableBody, Button, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SyncIcon from '@mui/icons-material/Sync';
import { api, SessionContext, TSessionContext } from 'lib';
import { useSyncHeartlandLocations, useUpdateHeartlandLocations } from 'api';
import { InfoBar, UnitSelector } from 'components';
import { HeartlandModel, InfoBarModel, UnitModel, UnitsHeartlandLocationModel } from 'model';
import { NetworkWifi1BarOutlined } from '@mui/icons-material';

const tableHead = {
  fontWeight: 700,
};

interface Props {
  heartland?: HeartlandModel | null;
}

export const HeartlandLocationsList: React.FC<Props> = (props: Props) => {
  const { currentHeartland, units } = React.useContext(SessionContext) as TSessionContext;
  const { updateHeartlandLocations } = useUpdateHeartlandLocations();
  const { syncHeartlandLocations } = useSyncHeartlandLocations();
  const [locations, setLocations] = React.useState<UnitsHeartlandLocationModel[]>([]);
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);

  const heartland = React.useMemo(() => props.heartland ? props.heartland : currentHeartland, [props.heartland, currentHeartland]);
  const unitsList = React.useMemo(() => heartland?.units ? heartland.units : units, [heartland, units]);

  React.useEffect(() => {
    if (heartland) {
      syncHeartlandLocations.sync(heartland.id);
    }
  }, []);

  React.useEffect(() => {
    if (syncHeartlandLocations.status === api.success) {
      setLocations(syncHeartlandLocations.locations);
    } else {
      setLocations([]);
    }
  }, [syncHeartlandLocations.status, syncHeartlandLocations.locations]);

  const clearInfoBar = () => setInfoBar(null);

  const sync = () => {
    if (heartland) {
      syncHeartlandLocations.sync(heartland.id);
    }
  }

  const save = () => {
    if (heartland) {
      updateHeartlandLocations.update(heartland.id, locations);
    }
  };

  React.useEffect(() => {
    if (updateHeartlandLocations.status === api.success) {
      setInfoBar({ status: "success", message: 'successfully updated.' });
    } if (updateHeartlandLocations.status === api.error) {
      setInfoBar({ status: "error", message: updateHeartlandLocations.error });
    } else if (updateHeartlandLocations.status === api.idle || updateHeartlandLocations.status === api.loading) {
      setInfoBar(null);
    }
  }, [updateHeartlandLocations.status]);

  const unit = (id: number | null): UnitModel | null => {
    const found = unitsList?.find((u) => u.id === id);
    return found ? found : null;
  };

  const updateUnit = (locationId: number, unitId: number) => {
    const updatedLocations = locations.map((l) => {
      if (l.id === locationId) {
        l.unit_id = unitId;
      }
      return l;
    });
    setLocations(updatedLocations);
  };

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h5" sx={{ mb: 1 }}>Heartland Locations</Typography>
      <Grid container spacing={2}>
        {infoBar &&
          <Grid item xs={12} md={12}>
            <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
          </Grid>
        }
        <Grid item xs={9}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Patron requires your Heartland locations to be linked/assigned to one of your store locations in order to display inventory levels correctly.
            In addition, each of your store locations will need to desginate one of the assigned locations as the primary location.
            If you have a Heartland location for online sales or have separate storage locations each of them needs to be assigned to one of your store locations.
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            If you have locations in Heartland that are not showing up below, click on the Sync button to refresh the list.
          </Typography>
          {syncHeartlandLocations.status === api.loading &&
            <CircularProgress />
          }
          {syncHeartlandLocations.status === api.success &&
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table aria-label="Heartland Locations" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableHead}>Heartland ID</TableCell>
                    <TableCell sx={tableHead}>Heartland Name</TableCell>
                    <TableCell sx={{ ...tableHead, width: 320 }}>Assigned to Patron Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locations.map((location) => (
                    <TableRow key={location.id}>
                      <TableCell> {location.location_id}</TableCell>
                      <TableCell>{location.location_name}</TableCell>
                      <TableCell><UnitSelector onSelect={(unit) => updateUnit(location.id, unit.id)} label={false} displayIfSingle={false} value={unit(location.unit_id)} units={unitsList} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button variant="contained" fullWidth onClick={save} startIcon={<SaveIcon />}>Save</Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button variant="contained" fullWidth onClick={sync} startIcon={<SyncIcon />}>Sync</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

import * as React from 'react';

import { SeriesMinifigModel } from 'model';
import { api } from 'lib';
import { StatusCodes } from 'http-status-codes';

interface State {
  minifig: SeriesMinifigModel | null;
  status: string;
  message: string;
}

interface Props extends State {
  updateCMF: (minifig: SeriesMinifigModel) => void;
}

const defaultState = {
  minifig: null,
  status: api.idle,
  message: '',
};

export const useUpdateCMF = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const update = React.useCallback(async (minifig: SeriesMinifigModel) => {
    const payload = {
      minifig: {
        bricklink_id: minifig.bricklink_id,
        brickset_id: minifig.brickset_id,
        brickowl_id: minifig.brickowl_id,
        rebrickable_id: minifig.rebrickable_id,
        rebrickable_image_url: minifig.rebrickable_image_url,
        release_year: minifig.release_year,
      },
    };
    const response = await api.put(
      `/api/series_minifigs/${minifig.id}`,
      payload
    );
    if (response.status === StatusCodes.OK) {
      setState({ status: api.success, minifig: response.body, message: '' });
    } else {
      setState({ status: api.error, minifig: null, message: response.body });
    }
  }, []);

  return {
    minifig: state.minifig,
    status: state.status,
    message: state.message,
    updateCMF: update,
  };
};

import * as React from 'react';
import { Backdrop, Button, CircularProgress, Grid, Link, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import { SessionContext, TSessionContext, api } from 'lib';
import { ErrorBar, UserTypeSelector } from 'components';

import { UserInput, useAddUser } from 'api/user/add';
import useStyles from './styles';

const initialUser = {
  first_name: '',
  last_name: '',
  username: '',
  email: null,
};

export const UserAdd: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAdmin, currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { userAdd, add } = useAddUser();
  const [user, setUser] = React.useState<UserInput>(initialUser);
  const [open, setOpen] = React.useState(false);

  const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, email: event.target.value });
  const updateFirstName = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, first_name: event.target.value });
  const updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, last_name: event.target.value });
  const updateUsername = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, username: event.target.value });
  const updateUserType = (value: string) => setUser({ ...user, user_type: value });

  const doAdd = () => {
    setOpen(true);
    add(user);
  };

  React.useEffect(() => {
    if (currentHeartland) {
      user.primary_heartland_id = currentHeartland.id
    }
  }, [currentHeartland, user]);

  React.useEffect(() => {
    if (userAdd.status !== api.loading) {
      setOpen(false);
    }
  }, [userAdd.status]);

  if (userAdd.status === api.success && userAdd.user) {
    navigate(`/users/${userAdd.user.id}`, { state: { messageType: 'info', message: 'User successfully created' } });
  }
  return (
    <div className={classes.add}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h2> <Link href="/users" className="nav">Users</Link> :: {isAdmin() ? "New User" : "New Employee"}</h2>
        </Grid>
        <Grid item xs={12} md={12}>
          {userAdd.status === api.error && <ErrorBar error={userAdd.error} />}
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isAdmin() &&
                <UserTypeSelector onSelect={updateUserType} />
              }
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="first_name"
                label="First Name"
                variant="outlined"
                fullWidth
                value={user ? user.first_name : ""}
                onChange={updateFirstName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="last_name"
                label="Last Name"
                variant="outlined"
                fullWidth
                value={user ? user.last_name : ""}
                onChange={updateLastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="username"
                label="Username"
                variant="outlined"
                fullWidth
                value={user ? user.username : ""}
                onChange={updateUsername}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                value={user ? user.email : ""}
                onChange={updateEmail}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                aria-label="Add"
                startIcon={<PersonAddIcon />}
                onClick={doAdd}
                fullWidth
              >
                Add User
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                aria-label="Cancel"
                startIcon={<CancelIcon />}
                href="/users"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  );
};

import * as React from "react";
import { api } from "lib";
import { AnimalModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  error: string;
  animals: AnimalModel[];
  total: number;
}

interface Props {
  animalSearch: State,
  doAnimalSearch: (query: string, page?: number, pageSize?: number) => void;
  resetAnimalSearch: () => void;
}

const initialState: State = {
  status: api.idle,
  error: '',
  animals: [],
  total: 0,
}

export const useAnimalsSearch = (): Props => {
  const [state, setState] = React.useState<State>(initialState);

  const search = React.useCallback(async (query: string, page = 0, pageSize = 0) => {
    setState({ ...initialState, status: api.loading });
    const resp = await api.get('/api/animals/search', { query: query, page: page.toString(), size: pageSize.toString() });
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, animals: resp.body.animals, total: resp.body.total, error: '' });
    } else {
      setState({ ...initialState, status: api.error, error: resp.body });
    }
  }, []);

  const reset = React.useCallback(() => setState(initialState), []);

  return {
    animalSearch: {
      ...state
    },
    doAnimalSearch: search,
    resetAnimalSearch: reset,
  };
};


import * as React from 'react';
import { StatusCodes } from 'http-status-codes';

import { api } from 'lib';
import { AuditCommentModel } from 'model/audit_comment';

interface CommentProps {
  status: string;
  error: string;
  currentComments: AuditCommentModel[];
  newComment: string;
  setNewComment: (comment: string) => void;
  submitComment: (newComment: string) => void;
}

const defaultState = {
  status: api.idle,
  error: '',
};

export const useAuditComments = (
  auditId: number,
  comments: AuditCommentModel[]
): CommentProps => {
  const [currentComments, setComments] =
    React.useState<AuditCommentModel[]>(comments);

  const [newComment, setNewComment] = React.useState('');
  const [state, setState] = React.useState(defaultState);

  const submitComment = React.useCallback(
    async (newComment: string) => {
      setState({ status: api.loading, error: '' });
      const resp = await api.post(`/api/audits/${auditId}/comment`, {
        comment: { comment: newComment },
      });

      if (resp.status === StatusCodes.OK) {
        setNewComment('');
        setComments(resp.body.comments);
        setState({ status: api.success, error: '' });
      } else {
        setState({ status: api.error, error: resp.body.error });
      }
    },
    [auditId]
  );

  return {
    status: state.status,
    error: state.error,
    currentComments: currentComments,
    newComment: newComment,
    setNewComment: setNewComment,
    submitComment: submitComment,
  };
};

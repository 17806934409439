import * as React from 'react';
import { api } from 'lib';
import { AuditModel, AuditStatus, AuditType } from 'model';
import { StatusCodes } from 'http-status-codes';

interface State {
  status: string;
  error: string;
  audits: AuditModel[];
  count: number;
}

interface Props {
  listAudits: State & {
    list: (
      page?: number,
      pageSize?: number,
      heartland_id?: number | null,
      auditType?: AuditType | null,
      auditStatus?: AuditStatus | null
    ) => void;
  };
}

const defaultState: State = {
  status: api.idle,
  error: '',
  audits: [],
  count: 0,
};

export const useListAudits = (): Props => {
  const [state, setState] = React.useState(defaultState);

  const list = React.useCallback(
    async (
      page = 0,
      size = 0,
      heartland_id: number | null = null,
      auditType: AuditType | null = null,
      auditStatus: AuditStatus | null = null
    ) => {
      try {
        setState({ ...state, status: api.loading });

        const searchParams: Record<string, string> = {
          page: page.toString(),
          size: size.toString(),
          ...(heartland_id &&
            heartland_id && { heartland_id: heartland_id.toString() }),
          ...(auditType && auditType !== 'any' && { audit_type: auditType }),
          ...(auditStatus &&
            auditStatus !== 'any' && { audit_status: auditStatus }),
        };

        const resp = await api.get(`/api/audits/`, searchParams);
        setState({
          ...state,
          status: resp.status === StatusCodes.OK ? api.success : api.error,
          audits: resp.status === StatusCodes.OK ? resp.body.audits : [],
          count: resp.status === StatusCodes.OK ? resp.body.count : 0,
          error: resp.status !== StatusCodes.OK ? resp.body : '',
        });
      } catch (error) {
        setState({
          ...state,
          status: api.error,
          error: String(error),
        });
      }
    },
    []
  );

  return {
    listAudits: {
      ...state,
      list,
    },
  };
};

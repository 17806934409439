import * as React from 'react';

import { BuyModel, BUY_LINES, BuyLineArrayType } from 'model';

interface BuyWarningProps {
  warnings: string[];
}

export const useBuyWarnings = (buy: BuyModel): BuyWarningProps => {
  const [warnings, setWarnings] = React.useState<string[]>([]);

  React.useEffect(() => {
    let newWarnings: string[] = [];
    if (buy.numZero > 0) {
      let warning =
        buy.numZero === 1
          ? 'There is an item that has a value of zero. '
          : `There are ${buy.numZero} items that have a value of zero. `;

      newWarnings.push(
        warning +
          'It is recommended that every buy item has a value above zero.'
      );
    }

    setWarnings(newWarnings);
  }, [buy]);

  return {
    warnings: warnings,
  };
};

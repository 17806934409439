import * as React from 'react';
import { Navigate } from 'react-router-dom';
import moment from 'moment';

import { SessionContext, TSessionContext } from 'lib/session';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { api, formatCurrency, formatPercentage } from 'lib';
import {
  useBuysDailySummary,
  useBuysMonthlySummary,
  useBuysWeeklySummary,
  useUnitsList,
} from 'api';
import { MonthlyReport } from './monthly_report';
import { UnitModel } from 'model';
import { AllUnitSelector } from 'components';

export const Dashboard = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const [selectedUnit, setSelectedUnit] = React.useState<UnitModel | null>(
    null
  );
  const { units, list: getUnits } = useUnitsList();
  const { dailySummary, getDailySummary } = useBuysDailySummary();
  const { monthlySummary, getMonthlySummary } = useBuysMonthlySummary();
  const { weeklySummary, getWeeklySummary } = useBuysWeeklySummary();

  const container = {
    background: '#f9f9f9',
    minHeight: 180,
    px: 2,
    py: 1,
  };

  React.useEffect(() => {
    getDailySummary(moment().format('L'), selectedUnit);
    getWeeklySummary(selectedUnit);
    getMonthlySummary(selectedUnit);
    getUnits();
  }, [getDailySummary, getWeeklySummary, getMonthlySummary, selectedUnit]);

  if (currentUser) {
    return (
      <Grid container spacing={2} alignItems='center' sx={{ width: '90%' }}>
        <Grid item xs={12}>
          <Grid container sx={{ pt: 4 }} alignItems='center' gap={2}>
            <Typography variant='body1' sx={{ px: 0, fontSize: '1.1em' }}>
              Welcome {currentUser.display_name}! Today is{' '}
              {moment().format('L')}
            </Typography>
            {units.length > 1 && (
              <AllUnitSelector
                units={units}
                onSelect={setSelectedUnit}
                selectedUnit={selectedUnit}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='h5'>Today's Buys</Typography>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Cash
            </Typography>
            {dailySummary.status === api.loading && <CircularProgress />}
            {dailySummary.status === api.success && (
              <>
                <Typography sx={{ mb: 0.5 }} variant='body1'>
                  {dailySummary.cash_count} buys
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(dailySummary.cash_total)} paid for Non-Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(dailySummary.cash_bulk)} paid for Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatPercentage(
                    1 - dailySummary.cash_total / dailySummary.cash_retail
                  )}{' '}
                  margin
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Store Credit
            </Typography>
            {dailySummary.status === api.loading && <CircularProgress />}
            {dailySummary.status === api.success && (
              <>
                <Typography sx={{ mb: 0.5 }} variant='body1'>
                  {dailySummary.credit_count} buys
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(dailySummary.credit_total)} paid for Non-Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(dailySummary.credit_bulk)} paid for Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatPercentage(
                    1 - dailySummary.credit_total / dailySummary.credit_retail
                  )}{' '}
                  margin
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Department Breakdown
            </Typography>
            {dailySummary.status === api.loading && <CircularProgress />}
            {dailySummary.status === api.success && (
              <>
                <Typography variant='body1'>
                  {dailySummary.total_nib_qty} New Sets
                </Typography>
                <Typography variant='body1'>
                  {dailySummary.total_used_qty} Used Sets
                </Typography>
                <Typography variant='body1'>
                  {dailySummary.total_minifig_qty + dailySummary.total_cmf_qty}{' '}
                  Minifigs
                </Typography>
                <Typography variant='body1'>
                  {dailySummary.total_animal_qty} Animals
                </Typography>
                <Typography variant='body1'>
                  {dailySummary.total_bulk_qty || 0} Gal Bulk
                </Typography>
                <Typography variant='body1'>
                  {dailySummary.total_misc_qty} Miscellaneous Items
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Box sx={{ width: '100%', mb: 3 }} />
        <Grid item xs={2}>
          <Typography variant='h5'>This Week's Buys</Typography>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Cash
            </Typography>
            {weeklySummary.status === api.loading && <CircularProgress />}
            {weeklySummary.status === api.success && (
              <>
                <Typography sx={{ mb: 0.5 }} variant='body1'>
                  {weeklySummary.cash_count} buys
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(weeklySummary.cash_total)} paid for Non-Bulk{' '}
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(weeklySummary.cash_bulk)} paid for Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatPercentage(
                    1 - weeklySummary.cash_total / weeklySummary.cash_retail
                  )}{' '}
                  margin
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Store Credit
            </Typography>
            {weeklySummary.status === api.loading && <CircularProgress />}
            {weeklySummary.status === api.success && (
              <>
                <Typography sx={{ mb: 0.5 }} variant='body1'>
                  {weeklySummary.credit_count} buys
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(weeklySummary.credit_total)} paid for Non-Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(weeklySummary.credit_bulk)} paid for Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatPercentage(
                    1 - weeklySummary.credit_total / weeklySummary.credit_retail
                  )}{' '}
                  margin
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Department Breakdown
            </Typography>
            {weeklySummary.status === api.loading && <CircularProgress />}
            {weeklySummary.status === api.success && (
              <>
                <Typography variant='body1'>
                  {weeklySummary.total_nib_qty} New Sets
                </Typography>
                <Typography variant='body1'>
                  {weeklySummary.total_used_qty} Used Sets
                </Typography>
                <Typography variant='body1'>
                  {weeklySummary.total_minifig_qty +
                    weeklySummary.total_cmf_qty}{' '}
                  Minifigs
                </Typography>
                <Typography variant='body1'>
                  {weeklySummary.total_animal_qty} Animals
                </Typography>
                <Typography variant='body1'>
                  {weeklySummary.total_bulk_qty || 0} Gal Bulk
                </Typography>
                <Typography variant='body1'>
                  {weeklySummary.total_misc_qty} Miscellaneous Items
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Box sx={{ width: '100%', mb: 3 }} />
        <Grid item xs={2}>
          <Typography variant='h5'>This Month's Buys</Typography>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Cash
            </Typography>
            {monthlySummary.status === api.loading && <CircularProgress />}
            {monthlySummary.status === api.success && (
              <>
                <Typography sx={{ mb: 0.5 }} variant='body1'>
                  {monthlySummary.cash_count} buys
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(monthlySummary.cash_total)} paid for Non-Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(monthlySummary.cash_bulk)} paid for Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatPercentage(
                    1 - monthlySummary.cash_total / monthlySummary.cash_retail
                  )}{' '}
                  margin
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Store Credit
            </Typography>
            {monthlySummary.status === api.loading && <CircularProgress />}
            {monthlySummary.status === api.success && (
              <>
                <Typography sx={{ mb: 0.5 }} variant='body1'>
                  {monthlySummary.credit_count} buys
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(monthlySummary.credit_total)} paid for
                  Non-Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatCurrency(monthlySummary.credit_bulk)} paid for Bulk
                </Typography>
                <Typography variant='body1'>
                  {formatPercentage(
                    1 -
                      monthlySummary.credit_total / monthlySummary.credit_retail
                  )}{' '}
                  margin
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={container}>
            <Typography sx={{ mb: 2, fontWeight: 700 }} variant='h6'>
              Department Breakdown
            </Typography>
            {monthlySummary.status === api.loading && <CircularProgress />}
            {monthlySummary.status === api.success && (
              <>
                <Typography variant='body1'>
                  {monthlySummary.total_nib_qty} New Sets
                </Typography>
                <Typography variant='body1'>
                  {monthlySummary.total_used_qty} Used Sets
                </Typography>
                <Typography variant='body1'>
                  {monthlySummary.total_minifig_qty +
                    monthlySummary.total_cmf_qty}{' '}
                  Minifigs
                </Typography>
                <Typography variant='body1'>
                  {monthlySummary.total_animal_qty} Animals
                </Typography>
                <Typography variant='body1'>
                  {monthlySummary.total_bulk_qty || 0} Gal Bulk
                </Typography>
                <Typography variant='body1'>
                  {monthlySummary.total_misc_qty} Miscellaneous Items
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='h5'>Year To Date</Typography>
        </Grid>
        <Grid item xs={9}>
          <MonthlyReport unit={selectedUnit} />
        </Grid>
      </Grid>
    );
  } else {
    return <Navigate to='/Login' />;
  }
};

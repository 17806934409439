import * as React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props {
  label?: string;
  helperText?: string;
  onSelect?: (value: string) => void;
};

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const MonthSelector: React.FC<Props> = (props: Props) => {

  const handleSelect = (event: SelectChangeEvent) => {
    const month: string = event.target.value;
    if (props.onSelect) {
      props.onSelect(month);
    }
  };

  const label = React.useMemo(() => props.label ? props.label : 'Month', [props.label]);

  return (
    <FormControl fullWidth>
      <InputLabel id="month-select-label">{label}</InputLabel>
      <Select
        labelId="month-select-label"
        id="month"
        onChange={handleSelect}
        label={label}
      >
        {months.map((m) =>
          <MenuItem key={m} value={m}>{m}</MenuItem>
        )}
      </Select>
      {props.helperText &&
        <FormHelperText>{props.helperText}</FormHelperText>
      }
    </FormControl>
  );
};

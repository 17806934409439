import * as React from 'react';
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useLoadBuySettings } from 'api';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { Checkbox, ConfirmationDialog, CurrencyInput, InfoBar, PercentageInput } from 'components';
import { BulkPriceBand, BuySettingModel, InfoBarModel } from 'model';
import { useSaveBuySettings } from 'api/buy/save_settings';
import { BulkPriceBandEntry } from './bulk_price_band_entry';

export const BulkBuySettingsTab: React.FC = () => {
  const { currentUnit, currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { load, settings: s, status } = useLoadBuySettings();
  const { saveBuySettings } = useSaveBuySettings();
  const [settings, setSettings] = React.useState<BuySettingModel | undefined>();
  const [bulkPriceBands, setBulkPriceBands] = React.useState<BulkPriceBand[]>([]);
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const [premiumPreview, setPremiumPreview] = React.useState<boolean>(false);
  const [dirtyPreview, setDirtyPreview] = React.useState<boolean>(false);
  const [overstockPreview, setOverstockPreview] = React.useState<boolean>(false);
  const [minifigPreview, setMinifigPreview] = React.useState<boolean>(false);
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    }
  }, [currentUnit, currentUser, load]);

  React.useEffect(() => {
    if (status === api.success && s?.settings) {
      setSettings(s.settings);
      setBulkPriceBands(s.bulk_price_bands);
    }
  }, [s, status]);

  React.useEffect(() => {
    if (saveBuySettings.status === api.success && saveBuySettings.settings?.settings) {
      setSettings(saveBuySettings.settings.settings);
    }
  }, [saveBuySettings]);


  const updateSetting = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: +value };
      setSettings(newSettings);
    }
  };

  const updatePriceBand = (id: number, prop: string, value: string | number | boolean) => {
    const updatedBands = bulkPriceBands.map((b) => {
      if (b.id === id) {
        return { ...b, [prop]: value };
      } else {
        return b;
      }
    });
    setBulkPriceBands(updatedBands);
  };

  React.useEffect(() => {
    if (saveBuySettings.status === api.success) {
      setInfoBar({ status: "success", message: saveBuySettings.msg });
    } else if (saveBuySettings.status === api.error) {
      setInfoBar({ status: "error", message: saveBuySettings.msg });
    } else {
      setInfoBar(null);
    }
  }, [saveBuySettings.status, saveBuySettings.msg, setInfoBar]);

  const [showResetConfirm, setShowResetConfirm] = React.useState<boolean>(false);
  const resetSettings = () => setShowResetConfirm(true);
  const handleResetConfirm = (value: boolean) => {
    setShowResetConfirm(false);
    if (value && currentUnit) {
      saveBuySettings.resetSettings(currentUnit.id, 'minifigs');
    }
  };

  const saveSettings = () => {
    if (settings) {
      saveBuySettings.save({ ...s, settings: settings, bulk_price_bands: bulkPriceBands });
    }
  };

  const credit = React.useMemo((): number => {
    let max = 0;
    if (settings) {
      const val = settings.bulk_credit_value_adj * 10;
      max = val;
      if (settings.bulk_dirty_adj && dirtyPreview) {
        max -= val * (1 - settings.bulk_dirty_adj / 100);
      }
      if (settings.bulk_overstock_adj && overstockPreview) {
        max -= val * (1 - settings.bulk_dirty_adj / 100);
      }
      if (settings.bulk_premium_adj && premiumPreview) {
        max -= val * (1 - settings.bulk_premium_adj / 100);
      }
      if (settings.bulk_minifig_adj && minifigPreview) {
        max -= val * (1 - settings.bulk_minifig_adj / 100);
      }
    }
    return max;
  }, [settings, dirtyPreview, minifigPreview, premiumPreview, overstockPreview]);

  const cash = React.useMemo((): number => {
    let max = 0;
    if (settings) {
      const val = settings.bulk_cash_value_adj * 10;
      max = val;
      if (settings.bulk_dirty_adj && dirtyPreview) {
        max -= val * (1 - settings.bulk_dirty_adj / 100);
      }
      if (settings.bulk_overstock_adj && overstockPreview) {
        max -= val * (1 - settings.bulk_dirty_adj / 100);
      }
      if (settings.bulk_premium_adj && premiumPreview) {
        max -= val * (1 - settings.bulk_premium_adj / 100);
      }
      if (settings.bulk_minifig_adj && minifigPreview) {
        max -= val * (1 - settings.bulk_minifig_adj / 100);
      }
    }
    return max;
  }, [settings, dirtyPreview, minifigPreview, premiumPreview, overstockPreview]);

  if (settings) {
    return (
      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="top" spacing={1}>
          {infoBar &&
            <Grid item xs={12} md={12}>
              <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
            </Grid>
          }
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={1.5}>
              <Grid item xs={0.30}>
                <Tooltip title={`The retail resale value for a gallon of bulk (equates roughly to a medium bag of bulk.)`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Retail value for 1 gallon of bulk</Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  label=""
                  fullWidth
                  value={settings.bulk_resale_value}
                  onChange={(e) => updateSetting('bulk_resale_value', e.target.value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Define the the amount you are willing to offer for credit and cash per gallon of bulk.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Amount to offer per gallon of bulk</Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  label="Credit"
                  fullWidth
                  value={settings.bulk_credit_value_adj}
                  onChange={(e) => updateSetting('bulk_credit_value_adj', e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyInput
                  label="Cash"
                  fullWidth
                  value={settings.bulk_cash_value_adj}
                  onChange={(e) => updateSetting('bulk_cash_value_adj', e.target.value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`Presets allows you to quickly add builk tubs to a buy, especially if you have specific sized tubs you use in store.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={11}>
                Presets
              </Grid>
              <Box width="100%" />
              {bulkPriceBands.map((priceBand) =>
                <BulkPriceBandEntry key={priceBand.id} priceBand={priceBand} updatePriceBand={(prop, value) => updatePriceBand(priceBand.id, prop, value)} />
              )}
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Typography variant="body2">
                  The following modifiers will alter the resale value, percentages greater than 100% will increase the resale value
                </Typography>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to boost if premium parts are found. Eg: raised baseplates, rare unique parts you can sell at a premium`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Premium Parts</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.bulk_premium_adj}
                  onChange={(value) => updateSetting('bulk_premium_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to boost when minifigs or minifig parts are found`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Minifigs/Minifig Parts</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.bulk_minifig_adj}
                  onChange={(value) => updateSetting('bulk_minifig_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Typography variant="body2">
                  The following modifiers will not modify the retail value, these will only alter the cash/credit offer, For example, entering 90% will take $10 off of a $100 offer.
                </Typography>
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The max percentage of the offer if cleaning is required.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Dirty</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.bulk_dirty_adj}
                  onChange={(value) => updateSetting('bulk_dirty_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The max percentage to offer for excess stock , enter 100% if you do not want to discount for overstock.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Overstock</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.bulk_overstock_adj}
                  onChange={(value) => updateSetting('bulk_overstock_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              {/* <Grid item xs={0.30}>
                <Tooltip title={`This adjustment does not impact the offer price. You can add an additional adjustment to the final cost of a set to account for labor, etc if you are importing into the POS `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Cost Modifier</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.bulk_additional_cost_adj}
                  onChange={(value) => updateSetting('bulk_additional_cost_adj', value)}
                />
              </Grid>
              <Box width="100%" /> */}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} sx={{ pl: 2 }}>
              <Grid item xs={12} md={12}>
                <Button variant="contained" fullWidth onClick={saveSettings}>Save</Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button fullWidth onClick={resetSettings}>Reset to default settings</Button>
                <ConfirmationDialog show={showResetConfirm} onClose={handleResetConfirm} message="Are you sure you want to reset your settings?" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ fontStyle: 'italic', px: 2, border: '1px solid #ccc' }}>
                  <h4>Test/Preview your settings</h4>
                  <p><Checkbox checked={minifigPreview} label="Minifig Parts" onChange={(e) => setMinifigPreview(e.target.checked)} /></p>
                  <p><Checkbox checked={premiumPreview} label="Premium Parts" onChange={(e) => setPremiumPreview(e.target.checked)} /></p>
                  <p><Checkbox checked={dirtyPreview} label="Dirty" onChange={(e) => setDirtyPreview(e.target.checked)} /></p>
                  <p><Checkbox checked={overstockPreview} label="Overstock" onChange={(e) => setOverstockPreview(e.target.checked)} /></p>
                  <strong>10 Gallons of Bulk</strong>
                  <p>Credit: {formatCurrency(Math.round(credit), false)}</p>
                  <p>Cash: {formatCurrency(Math.round(cash), false)}</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper >
    );
  } else {
    return (<></>);
  }
};
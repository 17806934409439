import * as React from 'react';
import { api } from 'lib';
import { StatusCodes } from 'http-status-codes';

interface State {
  status: string;
  message: string;
  image: string | null;
}

interface Props {
  status: string;
  message: string;
  image: string | null;
  updateImage: (url: string, minifigId: number, type: string) => void;
}

const defaultState = {
  status: api.idle,
  message: '',
  image: null,
};

export const useAddMinifigImage = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const add = React.useCallback(
    async (url: string, legoSetId: number, type: string) => {
      setState({ status: api.loading, message: '', image: null });
      const resp = await api.post(`/api/minifigs/${legoSetId}/images/upload`, {
        url: url,
        type: type,
      });
      if (resp.status === StatusCodes.OK) {
        setState({
          status: api.success,
          image: resp.body['rebrickable_image_url'],
          message: '',
        });
      } else {
        setState({ status: api.error, image: null, message: resp.body });
      }
    },
    []
  );

  return {
    status: state.status,
    image: state.image,
    message: state.message,
    updateImage: add,
  };
};

import * as React from 'react';
import { Grid, Tooltip } from '@mui/material';

import { LinkButton } from 'components/link_button';
import { AnimalModel } from 'model';

interface Props {
  animal: AnimalModel;
  displayDetails?: boolean;
}

export const AnimalLinks: React.FC<Props> = ({
  animal,
  displayDetails,
}: Props) => {
  return (
    <>
      <Grid container alignItems='center' sx={{ mt: 1, mb: 2 }} spacing={1}>
        {displayDetails && (
          <Grid item>
            <Tooltip title='Coming Soon!'>
              <div>
                <LinkButton
                  url={`/animals/${animal.bricklink_id}`}
                  target='_pa'
                  label='Details'
                  disabled
                />
              </div>
            </Tooltip>
          </Grid>
        )}
        {animal.bricklink_id && (
          <Grid item>
            <LinkButton
              url={`https://www.bricklink.com/v2/catalog/catalogitem.page?P=${animal.bricklink_id}`}
              target='_bl'
              label={`Bricklink: ${animal.bricklink_id}`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

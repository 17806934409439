import * as React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyToClipboard = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
    } catch (error) {
      setIsCopied(false);
    }
  };

  return { isCopied, copyToClipboard };
};

interface Props {
  content: string;
}

export const CopyToClipboardButton = ({ content }: Props) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard();

  return (
    <IconButton aria-label={isCopied ? 'Copied!' : 'Copy to Clipboard'} onClick={() => copyToClipboard(content)} >
      <ContentCopyIcon />
    </IconButton>
  );
};

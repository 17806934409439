import * as React from 'react';
import {
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import moment from 'moment';

import { formatCurrency } from 'lib';
import { SeriesMinifigModel } from 'model';
import { TCell } from 'components';

interface Props {
  minifig: SeriesMinifigModel;
  maxHeight?: number;
}

const heading = {
  fontWeight: 700,
  fontSize: '0.8em',
  background: '#eeeeee',
};

const condition = (value: string) => {
  switch (value) {
    case 'used':
      return 'Used';
    case 'damaged':
      return 'Damaged';
    case 'incomplete':
      return 'Incomplete';
    case 'new':
      return 'New';
    default:
      return 'Unknown';
  }
};

const buyType = (value: string) => {
  switch (value) {
    case 'trade':
      return 'Store Credit';
    case 'cash':
      return 'Cash';
    default:
      return 'Unknown';
  }
};

export const CMFRecentBuys: React.FC<Props> = ({
  minifig,
  maxHeight = 250,
}: Props) => {
  return (
    <>
      <Typography variant='body1' sx={{ mb: 2, fontSize: '0.9rem' }}>
        <strong>BAM Wide Recent Buys</strong>
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: maxHeight }}>
        <Table stickyHeader aria-label='Price Guide' size='small'>
          <TableHead>
            <TableRow>
              <TCell sx={heading} width={'18%'}>
                Date
              </TCell>
              <TCell sx={heading} width={'10%'}>
                Type
              </TCell>
              <TCell sx={heading} width={'17%'}>
                Cond
              </TCell>
              <TCell sx={heading} width={'35'}>
                Notes
              </TCell>
              <TCell sx={heading} width={'10%'} align='right'>
                Value
              </TCell>
              <TCell sx={heading} width={'10%'} align='right'>
                Adj. Value
              </TCell>
              <TCell sx={heading} width={'10%'} align='right'>
                Cost
              </TCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {minifig.recent_buys.map((line) => (
              <TableRow key={line.id}>
                <TCell>
                  {moment(line.created_at).startOf('day').fromNow()}
                </TCell>
                <TCell>{buyType(line.buy_type)}</TCell>
                <TCell>{condition(line.condition)}</TCell>
                <TCell>{line.notes}</TCell>
                <TCell align='right'>{formatCurrency(line.value)}</TCell>
                <TCell align='right'>
                  {formatCurrency(line.value - line.discount)}
                </TCell>
                <TCell align='right'>{formatCurrency(line.cost)}</TCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

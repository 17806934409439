import * as React from 'react';
import { Alert } from '@mui/material';

interface Props {
  error: any;
}

export const ErrorBar: React.FC<Props> = ({ error }: Props) => {

  const errorMessage = () => {
    if (Array.isArray(error)) {
      return (
        <>
          {error.map((e, i) =>
            <li key={i}>{e}</li>
          )}
        </>
      );
    } else {
      return (<div>{error}</div>);
    }
  };

  return (
    <Alert severity="error">
      {errorMessage()}
    </Alert>
  );
}
import * as React from 'react';
import { Box } from '@mui/material';

import { MinifigContext, TMinifigContext } from '../context';
import { MinifigMyStoreInfo } from 'components';

export const MyStoreInfo: React.FC = () => {
  const { minifig } = React.useContext(MinifigContext) as TMinifigContext;

  return (
    <>
      <Box sx={{ mb: 5, width: '80%', mx: 'auto', textAlign: 'left' }}>
        <MinifigMyStoreInfo minifig={minifig} />
      </Box>
    </>
  );
};

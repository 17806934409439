import * as React from 'react';

import { BuyModel } from 'model';
import { EditBuyModal } from './edit';

interface Props {
  buy: BuyModel;
  open: boolean;
  onClose: () => void;
}

export const EditBuy: React.FC<Props> = ({ open, onClose }: Props) => {
  const closeEdit = () => {
    if (onClose) {
      onClose();
    }
  };
  return <EditBuyModal open={open} onClose={closeEdit} />;
};

import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, Grid, LinearProgress, Link, TextField, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import RestorePageIcon from '@mui/icons-material/RestorePage';

import { Checkbox, InfoBar, NotFound, UserTypeSelector } from 'components';
import { SessionContext, TSessionContext, api } from 'lib';
import { InfoBarModel, UserModel } from 'model';
import { useGetUser, userUpdateActions, useUpdateUser } from 'api/user';
import useStyles from './styles';

interface FormProps {
  user: UserModel;
}

const UserForm: React.FC<FormProps> = (props: FormProps) => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const [user, setUser] = React.useState<UserModel>(props.user);
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const { updateUser, update, archive, unarchive } = useUpdateUser();
  const { state } = useLocation();

  const updateFirstName = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, first_name: event.target.value });
  const updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, last_name: event.target.value });
  const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, email: event.target.value });
  const updateUsername = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, username: event.target.value });
  const updateHidden = (event: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, hide_as_employee: event.target.checked });

  const hidden = React.useMemo(() => user.hide_as_employee ? user.hide_as_employee : false, [user]);

  const doUpdate = () => update(user);
  const clearInfoBar = () => setInfoBar(null);
  const reset = () => setUser(props.user);
  const archiveUser = () => archive(user);
  const unarchiveUser = () => unarchive(user);

  React.useEffect(() => {
    if (state && state.message) {
      setInfoBar({ status: state.type, message: state.message });
    } else if (updateUser.status === api.success) {
      if (updateUser.action === userUpdateActions.archive) {
        setUser({ ...user, status: "archived" })
      } else if (updateUser.action === userUpdateActions.unarchive) {
        setUser({ ...user, status: "active" })
      } else {
        setInfoBar({ status: "success", message: "User updated!" });
      }
    } else if (updateUser.status === api.error) {
      setInfoBar({ status: "error", message: updateUser.error });
    } else {
      setInfoBar(null);
    }
  }, [state, updateUser.error, updateUser.status]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          <Link href="/users">Users</Link> :: {user.first_name} {user.last_name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        {infoBar &&
          <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
        }
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid container spacing={2}>
          {user.status === "archived" ?
            <Grid item xs={12} md={12}>
              This user has been archived. To make changes or reactivate the user, please unarchive the user first.
            </Grid> :
            <>
              <Grid item xs={12} md={12}>
                {isAdmin() ?
                  <UserTypeSelector value={user.user_type} /> :
                  <TextField
                    id="user_type"
                    label="User Type"
                    variant="outlined"
                    InputProps={{ readOnly: true, }}
                    fullWidth
                    value={user.user_type}
                  />
                }
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField id="username" label="Username" variant="outlined" fullWidth value={user.username} onChange={updateUsername} />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField id="first_name" label="First Name" variant="outlined" fullWidth value={user.first_name} onChange={updateFirstName} />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField id="last_name" label="Last Name" variant="outlined" fullWidth value={user.last_name} onChange={updateLastName} />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={user.email}
                  onChange={updateEmail}
                  helperText="Leave blank, if you do not want the user to have their own login"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Checkbox label="Hide in Employee List" checked={hidden} onChange={updateHidden} />
              </Grid>
            </>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        {user.status !== "archived" ?
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Save Edits"
                startIcon={<SaveIcon />}
                onClick={doUpdate}
                fullWidth
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Reset"
                startIcon={<RestorePageIcon />}
                onClick={reset}
                fullWidth
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Cancel"
                startIcon={<CancelIcon />}
                href="/users"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="outlined"
                color="error"
                aria-label="Archive"
                startIcon={<ArchiveIcon />}
                onClick={archiveUser}
                fullWidth
              >
                Archive
              </Button>
            </Grid>
          </Grid>
          :
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              aria-label="UnArchive"
              startIcon={<UnarchiveIcon />}
              onClick={unarchiveUser}
              fullWidth
            >
              UnArchive
            </Button>
          </Grid>
        }
      </Grid>
    </Grid>
  );
};

export const UserShow: React.FC = () => {
  const { getUser, get } = useGetUser();
  const { user_id } = useParams();
  const classes = useStyles();

  React.useEffect(() => {
    if (user_id) {
      get(parseInt(user_id));
    }
  }, [user_id, get]);


  if (getUser.status === api.loading) {
    return (<div style={{ marginTop: 32, width: 800 }}><LinearProgress /></div>);
  } else if (getUser.status === api.success && getUser.user) {
    return (
      <Box sx={{ width: '95%', pt: 2, fontSize: '0.9rem' }}>
        <UserForm user={getUser.user} />
      </Box>
    );
  } else if (getUser.status === api.error) {
    return (<NotFound />);
  }
  return (<></>);
};

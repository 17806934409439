import * as React from 'react';

import { TextField } from '@mui/material';
import { debounce } from 'lodash';

interface Props {
  quantity: number;
  onChange: (value: number) => void;
  decimal?: boolean,
  width?: number;
  label?: string;
}

export const QuantityInput: React.FC<Props> = ({ quantity, onChange, decimal = false, width = 65, label = '' }: Props) => {

  const [value, setValue] = React.useState<number>(quantity);
  const doUpdate = React.useMemo(() => debounce(onChange, 500), [onChange]);

  const updateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = +e.target.value || 0;
    if (decimal) {
      newValue = Math.round((+e.target.value || 0) * 10) / 10;
    }
    setValue(newValue < 0 ? 0 : newValue);
    doUpdate(newValue);
  };

  return (
    <TextField
      value={value}
      type="number"
      sx={{ width: width }}
      onFocus={event => event.target.select()}
      inputProps={{ style: { textAlign: 'center' } }}
      size="small"
      onChange={updateValue}
    />
  );
};
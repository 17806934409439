import { Modal, Box } from '@mui/material';
import * as React from 'react';

interface Props {
  children: React.ReactNode
  onClose: () => void;
  open: boolean;
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 0,
  width: '97%',
  height: '80%',
  boxShadow: 24,
  overflow: 'scroll',
  p: 2,
};

export const CardModal: React.FC<Props> = (props: Props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {props.children}
      </Box>
    </Modal>
  );
};
import * as React from 'react';
import { FormLabel, RadioGroup, FormControlLabel, Radio, Grid, TextField } from '@mui/material';

import { CMFSearch, MinifigSearch, SetSearch, AnimalSearch } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SessionContext, TSessionContext } from "../../lib";

export const CatalogDashboard: React.FC = () => {
  const { getSetting, saveSetting } = React.useContext(SessionContext) as TSessionContext;
  const [search, setSearch] = React.useState("");
  const [searchType, setSearchType] = React.useState(getSetting('catalog_search_type') as string || "sets");
  const flags = useFlags();

  const updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = (event.target as HTMLInputElement).value;
    setSearchType(value);
    saveSetting('catalog_search_type', value);
  };

  return (
    <div style={{ width: "80%" }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md="auto">
          <FormLabel id="search-for-label">Search for: </FormLabel>
          <RadioGroup
            row
            aria-labelledby="search-for-label"
            defaultValue="sets"
            name="search-for"
            value={searchType}
            onChange={handleChange}
          >
            <FormControlLabel value="sets" control={<Radio />} label="Sets" />
            <FormControlLabel value="minifigs" control={<Radio />} label="Minifigs" />
            <FormControlLabel value="cmf" control={<Radio />} label="CMF" />
            {flags.animalCatalog &&
              <FormControlLabel value="animals" control={<Radio />} label="Animals" />
            }
          </RadioGroup>
        </Grid>
        <Grid item xs={8}>
          <TextField fullWidth onChange={updateSearch} />
        </Grid>
        <Grid item xs={12}>
          {searchType === 'cmf' && <CMFSearch query={search} />}
          {searchType === 'minifigs' && <MinifigSearch query={search} />}
          {searchType === 'sets' && <SetSearch query={search} />}
          {searchType === 'animals' && <AnimalSearch query={search} />}
        </Grid>
      </Grid>
    </div>
  );
};
import * as React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from '@mui/material';
import { formatCurrency, formatPercentage } from "lib";

export const NewSetBuyLinesColumns: GridColDef[] = [
  {
    field: 'buy_id',
    headerName: 'Buy',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`/buys/${params.row.buy_id}`}>
          {params.row.buy_id}
        </Link>
      </>
    )
  },
  {
    field: 'buy_type',
    headerName: 'Type',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.buy_type}
      </>
    )
  },
  {
    field: 'notes',
    headerName: 'Notes',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
  },
  {
    field: 'open',
    headerName: 'Open Box',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.open ? "yes" : "no"}
      </>
    )
  },
  {
    field: 'damaged',
    headerName: 'Box Damage',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.damaged ? "yes" : "no"}
      </>
    )
  },
  {
    field: 'overstock',
    headerName: 'Overstock',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.overstock ? "yes" : "no"}
      </>
    )
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    headerAlign: 'right',
    align: 'right',
    width: 50,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.quantity}
      </>
    )
  },
  {
    field: 'cost_final',
    headerName: 'Cost',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {formatCurrency(params.row.cost_final)}
      </>
    )
  },
  {
    field: 'value',
    headerName: 'Value',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {formatCurrency(params.row.value - params.row.value_discount)}
      </>
    )
  },
  {
    field: 'margin_final',
    headerName: 'Cost %',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {formatPercentage(params.row.cost_final / (params.row.value - params.row.value_discount))}
      </>
    )
  },
  {
    field: 'completed_at',
    headerName: 'Date',
    headerAlign: 'right',
    align: 'right',
    type: 'date',
    flex: 1,
    valueGetter: (params) => params.value && new Date(params.value),
  },
];
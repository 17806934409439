import * as React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props {
  value: string;
  onSelect?: (value: string) => void;
}

export const ConditionSelector: React.FC<Props> = ({ value, onSelect }: Props) => {

  const handleChange = (event: SelectChangeEvent) => {
    if (onSelect) {
      onSelect(event.target.value);
    }
  };

  return (
    <FormControl sx={{ minWidth: 130 }} size="small">
      <Select
        id="Condition"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value="new">New</MenuItem>
        <MenuItem value="used">Used</MenuItem>
        <MenuItem value="damaged">Damaged</MenuItem>
        <MenuItem value="incomplete">Incomplete</MenuItem>
      </Select>
    </FormControl>
  );
};

import * as React from 'react';
import { SessionContext, TSessionContext } from '../../lib';
import useStyles from './styles';
import { Button, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * ReportsMenu component extracted from StoreNav to use in both Admin and Store Navs
 * @constructor
 */
export const ReportsMenu: React.FC = () => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        className='menuItem'
        id='reports-button'
        aria-controls={open ? 'reports-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Reports <ExpandMoreIcon />
      </Button>
      <Menu
        id='reports-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        MenuListProps={{ 'aria-labelledby': 'reports-button' }}
      >
        <MenuItem component={'a'} href='/reports/buys' onClick={handleClose}>
          Daily Buys
        </MenuItem>
        {isAdmin() && (
          <MenuItem component={'a'} href='/reports/units' onClick={handleClose}>
            Units Report
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

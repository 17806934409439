import * as React from 'react';
import { api } from 'lib';
import { InventorySettingModel } from 'model';
import { StatusCodes } from 'http-status-codes';

interface State {
  status: string;
  message: string;
  inventorySetting: InventorySettingModel | null;
}

interface Props {
  getInventorySettings: State & { get: (heartlandId: number) => void };
}

const defaultState = {
  status: api.idle,
  message: "",
  inventorySetting: null,
}

export const useGetInventorySettings = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = async (heartlandId: number) => {
    setState({ status: api.loading, message: '', inventorySetting: null });
    const resp = await api.get(`/api/heartlands/${heartlandId}/inventory_settings`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, message: '', inventorySetting: resp.body });
    } else {
      setState({ status: api.error, message: resp.body, inventorySetting: null });
    }
  };

  return {
    getInventorySettings: {
      ...state,
      get,
    }
  };
};
import * as React from 'react';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { SeriesMinifigModel } from 'model';
import { CMFSearch } from 'components';

interface Props {
  onAdd: (minifig: SeriesMinifigModel) => void;
  onCancel: () => void;
}

type List = Record<any, SeriesMinifigModel | null>;

export const CMFSelector: React.FC<Props> = ({ onAdd, onCancel }: Props) => {
  const [search, setSearch] = React.useState<string>('');
  const [selectedMinifigs, setSelectedMinifigs] = React.useState<List>({});
  const searchRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    searchRef.current?.focus();
  }, []);

  const updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const selectMinifig = (minifig: SeriesMinifigModel) => {
    setSearch('');
    onAdd(minifig);
  };

  const handleCheck = (minifig: SeriesMinifigModel, checked: boolean) => {
    setSelectedMinifigs({
      ...selectedMinifigs,
      [minifig.id]: checked ? minifig : null,
    });
  };

  const hasSelected = React.useMemo(() => {
    return Object.keys(selectedMinifigs).some((id) => {
      if (selectedMinifigs[id]) {
        return true;
      }
    });
  }, [selectedMinifigs]);

  const addSelected = () => {
    const items = Object.keys(selectedMinifigs).forEach((id) => {
      if (selectedMinifigs[id]) {
        onAdd(selectedMinifigs[id] as SeriesMinifigModel);
      }
    });
  };

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item xs={12} md='auto'>
        <h4>Add New:</h4>
      </Grid>
      <Grid item xs={8} md={3}>
        <TextField
          inputRef={searchRef}
          label='Search'
          variant='outlined'
          size='small'
          fullWidth
          onChange={updateSearch}
          value={search}
        />
      </Grid>
      <Grid item xs={2} md='auto'>
        <Button onClick={addSelected} disabled={!hasSelected}>
          Add Selected
        </Button>
      </Grid>
      <Grid item xs={1} md={1}>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      {search && (
        <Grid item xs={12}>
          <CMFSearch
            query={search}
            onClick={selectMinifig}
            onCheck={handleCheck}
            maxResults={12}
            size={80}
          />
        </Grid>
      )}
    </Grid>
  );
};

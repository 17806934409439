import * as React from 'react';
import { Link } from "@mui/material";
import { LegoSetModel } from 'model';

interface Props {
  item: LegoSetModel;
  condition: string;
  text?: string;
}
export const EbaySetLink = ({ item, condition, text }: Props) => {

  const url = React.useMemo(() => {
    const search = encodeURIComponent(`lego ${item.set_number} ${item.name}`);
    const conditionParam = condition === "used" ? 3000 : 1000;
    return `https://www.ebay.com/sch/i.html?_nkw=${search}&LH_PrefLoc=3&LH_Sold=1&LH_Complete=1&LH_ItemCondition=${conditionParam}`;
  }, [item, condition]);

  const textStr = React.useMemo(() => {
    return text ? text : `eBay ${condition}`;
  }, [text, condition]);

  return (
    <Link target="_ebay" href={url}>{textStr}</Link>
  );
};
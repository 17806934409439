import * as React from 'react';
import { Link } from "@mui/material";
import { MinifigModel } from 'model';

interface Props {
  item: MinifigModel;
  condition?: string;
  text?: string;
}
export const EbayMinifigLink = ({ item, condition, text }: Props) => {

  const url = React.useMemo(() => {
    const search = encodeURIComponent(`lego ${item.name} ${item.bricklink_id}`);
    const conditionParam = condition === 'used' ? 3000 : 1000;
    return `https://www.ebay.com/sch/i.html?_nkw=${search}&LH_PrefLoc=3&LH_Sold=1&LH_ItemCondition=${conditionParam}`;
  }, [item]);

  const textStr = React.useMemo(() => {
    return text ? text : `eBay`;
  }, [text]);

  return (
    <Link target="_ebay" href={url}>{textStr}</Link>
  );
};
import * as React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';

import { formatCurrency } from 'lib';
import { LegoSetModel } from 'model';
import { PreviewCard, PreviewLayout, RebrickableImage } from 'components';
import { LegoSetSummary } from './lego_set_summary';
import { LegoSetLinks } from './lego_set_links';
import { LegoSetBricklinkPrices } from './lego_set_bricklink_prices';
import { LegoSetMyStoreInfo } from './lego_set_my_store_info';
import { LegoSetRecentBuys, SearchThumbnail } from 'components';
import { SessionContext, TSessionContext } from 'lib';

interface Props {
  legoSet: LegoSetModel | null;
}

export const LegoSetCard: React.FC<Props> = ({ legoSet }: Props) => {
  const { isHeartlandLive } = React.useContext(
    SessionContext
  ) as TSessionContext;

  if (!legoSet) {
    return <></>;
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5.5}>
          <Typography
            variant='h6'
            sx={{
              mb: 1,
              textAlign: 'center',
              fontWeight: 700,
            }}
          >
            <Link
              href={`/sets/${legoSet.bricklink_id}`}
              sx={{
                display: 'inline-block',
              }}
            >
              {legoSet.set_number} {legoSet.name}
            </Link>
          </Typography>
          <LegoSetSummary legoSet={legoSet} />
          <LegoSetLinks legoSet={legoSet} displayDetails />
          <RebrickableImage
            imageUrl={legoSet.rebrickable_image_url}
            imageAlt={legoSet.name}
          />
          {legoSet.minifigs.length > 0 && (
            <PreviewLayout headerText='Set Minifigs'>
              {legoSet.minifigs.map((inventory) => (
                <PreviewCard
                  cardTitle={`${inventory.minifig.bricklink_id} x${inventory.quantity}`}
                  pricing={`Avg: ${formatCurrency(
                    inventory.minifig.new_bricklink_prices.avg
                  )}; Min: ${formatCurrency(
                    inventory.minifig.used_bricklink_prices.min
                  )}; Max: ${formatCurrency(
                    inventory.minifig.used_bricklink_prices.max
                  )}`}
                  image={inventory.minifig.rebrickable_image_url}
                  imageAlt={inventory.minifig.name}
                  url={`/minifigs/${inventory.minifig.bricklink_id}`}
                  target='_page'
                />
              ))}
            </PreviewLayout>
          )}
        </Grid>
        <Grid item xs={6.5}>
          <LegoSetBricklinkPrices legoSet={legoSet} />
          {isHeartlandLive() && <LegoSetMyStoreInfo legoSet={legoSet} />}
          <LegoSetRecentBuys legoSet={legoSet} maxHeight={800} />
        </Grid>
      </Grid>
    </Box>
  );
};

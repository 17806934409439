import * as React from 'react';
import {
  Alert,
  Box,
  Collapse,
  Grid,
  IconButton,
  Link,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Outlet, useLocation } from 'react-router-dom';
import { SessionContext, TSessionContext } from 'lib';
import { isAdmin, models } from 'model';
import useStyles from './styles';
import { UserMenu } from './user_menu';
import { SettingsMenu } from './settings_menu';
import { ReportsMenu } from './reports_menu';
import { AuditsMenu } from './audits_menu';

const AdminNav: React.FC = () => {
  return (
    <>
      <Link href='/units' className='menuItem'>
        Units
      </Link>
      <Link href='/heartlands' className='menuItem'>
        Heartland Instances
      </Link>
      <Link href='/buys' className='menuItem'>
        Buys/Trades
      </Link>
      <Link href='/users' className='menuItem'>
        Users
      </Link>
      <Link href='/customers' className='menuItem'>
        Customers
      </Link>
      <Link href='/catalog' className='menuItem'>
        Catalog
      </Link>
      <ReportsMenu />
      <AuditsMenu />
    </>
  );
};

const StoreNav: React.FC = () => {
  const { isOwner, isAdmin } = React.useContext(
    SessionContext
  ) as TSessionContext;

  return (
    <>
      <MenuItem component={'a'} href='/customers' className='menuItem'>
        Customers
      </MenuItem>
      <MenuItem component={'a'} href='/buys' className='menuItem'>
        Buys/Trades
      </MenuItem>
      <MenuItem component={'a'} href='/buys/new' className='menuItem'>
        Buy Calculator
      </MenuItem>
      <MenuItem component={'a'} href='/catalog' className='menuItem'>
        Catalog
      </MenuItem>
      {isOwner() && (
        <>
          <ReportsMenu />
          <SettingsMenu />
        </>
      )}
    </>
  );
};

const NavSwitcher: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;

  if (currentUser) {
    if (models.user.isAdmin(currentUser)) {
      return <AdminNav />;
    } else {
      return <StoreNav />;
    }
  } else {
    return <></>;
  }
};

type Props = {
  children?: React.ReactNode;
};

export const Navigation: React.FC<Props> = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const { pathname } = useLocation();
  const pathRef = React.useRef<string>();

  React.useEffect(() => {
    if (pathRef.current !== pathname) {
      setOpen(true);
    }
    pathRef.current = pathname;
  }, [pathname]);

  return (
    <>
      <header className={classes.header}>
        <Link href='/'>
          <img src='/logo_straight.png' width={120} alt='Bricks & Minifigs' />
        </Link>
        <NavSwitcher />
        <div className='menuActions'>
          <Grid container alignContent={'center'} justifyContent={'center'}>
            {currentUser && (
              <Grid item>
                <Box sx={{ width: '100%', p: 2 }}>
                  <UserMenu />
                </Box>
              </Grid>
            )}
          </Grid>
        </div>
      </header>
      <div className='content'>
        {process.env.REACT_APP_ENV === 'staging' && (
          <Collapse in={open}>
            <Alert
              variant='filled'
              severity='error'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              You are accessing the staging version of Patron. This version is
              for In Person Training and Testing use only. Please access Patron{' '}
              <a href='https://patron.bricksandminifigs.com'>here</a>.
            </Alert>
          </Collapse>
        )}
        <Outlet />
      </div>
    </>
  );
};

import * as React from "react";
import { api } from "lib";
import { LegoSetModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  msg: string;
  legoSet: LegoSetModel | null;
}

interface Props {
  getLegoSetState: State;
  getLegoSet: (id: string) => void;
}

const defaultState = {
  status: api.idle,
  msg: '',
  legoSet: null,
}

export const useGetLegoSet = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = React.useCallback(async (id: string) => {
    setState({ status: api.loading, msg: '', legoSet: null });
    const resp = await api.get(`/api/lego_sets/${id}`);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, msg: '', legoSet: resp.body });
    } else {
      setState({ status: api.error, msg: resp.body, legoSet: null });
    }
  }, []);

  return {
    getLegoSetState: {
      ...state,
    },
    getLegoSet: get,
  };
};


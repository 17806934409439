import * as React from "react";
import { api } from "lib";
import { UserModel } from "model";
import { StatusCodes } from "http-status-codes";

export type UserInput = {
  first_name: string;
  last_name: string;
  username: string;
  email: string | null;
  unit_id?: number;
  user_type?: string;
  primary_heartland_id?: number;
};

interface State {
  status: string;
  error: string;
  user: UserModel | null;
}

interface Props {
  userAdd: State;
  add: (user: UserInput) => void;
}

const idleState = {
  status: api.idle,
  error: '',
  user: null,
};

const loadingState = {
  status: api.idle,
  error: '',
  user: null,
};

const errorState = (error: string) => ({
  status: api.error,
  error: error,
  user: null,
});

export const useAddUser = (): Props => {
  const [state, setState] = React.useState<State>(idleState);

  const add = React.useCallback(async (newUser: UserInput) => {
    setState(loadingState);
    const payload = {
      user: newUser,
    };
    const resp = await api.post(`/api/users/`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, user: resp.body, error: '' });
    } else {
      setState(errorState(resp.body.error));
    }
  }, []);

  return {
    userAdd: state,
    add,
  };
};

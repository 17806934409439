import * as React from 'react';
import { SessionContext, TSessionContext } from '../../lib';
import { useLogout } from '../logout';
import useStyles from './styles';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import ConstructionIcon from '@mui/icons-material/Construction';

const DisabledListHeader = ({ children }: { children: React.ReactNode }) => (
  <ListItem
    dense
    sx={{
      px: 2,
      py: 0.5,
      color: 'text.secondary',
      pointerEvents: 'none',
      fontSize: '0.812rem',
    }}
  >
    {children}
  </ListItem>
);

/**
 * UserMenu component
 * @constructor
 */
export const UserMenu: React.FC = () => {
  const { isAdmin, currentUser } = React.useContext(
    SessionContext
  ) as TSessionContext;
  const { logout } = useLogout();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        className='menuItem'
        id='user-button'
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {currentUser?.email} <ExpandMoreIcon />
      </Button>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        MenuListProps={{ 'aria-labelledby': 'user-button' }}
      >
        {isAdmin() && (
          <List dense>
            <DisabledListHeader>
              <ListItemIcon sx={{ p: 0, minWidth: '1.5rem' }}>
                <ConstructionIcon fontSize={'small'} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>Admin Tools</ListItemText>
            </DisabledListHeader>
            <ListItem>
              <MenuItem component={'a'} href='/onboard/add-location'>
                Onboard New Location
              </MenuItem>
            </ListItem>
          </List>
        )}
        <List dense>
          {isAdmin() && (
            <DisabledListHeader>
              <ListItemIcon sx={{ p: 0, minWidth: '1.5rem' }}>
                <SettingsIcon fontSize={'small'} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>Settings</ListItemText>
            </DisabledListHeader>
          )}
          <ListItem>
            <MenuItem component={'a'} href='/user/profile'>
              Custom Settings
            </MenuItem>
          </ListItem>
          <ListItem>
            <MenuItem component={'a'} href='/user/security'>
              Security Settings
            </MenuItem>
          </ListItem>
          <ListItem>
            <MenuItem component={'a'} className='menuItem' onClick={logout}>
              Logout
            </MenuItem>
          </ListItem>
        </List>
      </Menu>
    </>
  );
};

import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  TextField,
  Button,
} from '@mui/material';
import { debounce } from 'lodash';

import { useSaveBuy } from 'api';
import { BuyModel } from 'model';
import { BuyContext, TBuyContext } from 'contexts';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AddNote: React.FC<Props> = ({ open, onClose }) => {
  const { addNote } = React.useContext(BuyContext) as TBuyContext;
  const { editComplete } = useSaveBuy();

  const [note, setNote] = React.useState('');

  const updateComment = debounce((e) => setNote(e.target.value), 500);

  const closeModal = () => {
    setNote('');
    onClose();
  };

  const onSave = () => {
    addNote(note);
    closeModal();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      maxWidth={'sm'}
      fullWidth
      disableRestoreFocus
      scroll='paper'
      sx={{ minHeight: '800px' }}
    >
      <DialogTitle>Add Comment</DialogTitle>
      <DialogContent>
        <Grid container alignContent={'center'} justifyContent={'center'}>
          <TextField
            onChange={updateComment}
            sx={{ width: '100%' }}
            rows={5}
            multiline
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          disabled={note.length === 0}
          onClick={onSave}
        >
          Save
        </Button>
        <Button variant='contained' onClick={closeModal}>
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import * as React from "react";
import { api } from "lib";
import { RewardsSignupFormModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  rewardsSignupForm: RewardsSignupFormModel | null;
  get: (id: string) => void;
}

export const useGetRewardsSignupForm = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [rewardsSignupForm, setRewardsSignupForm] = React.useState<RewardsSignupFormModel | null>(null);

  const get = React.useCallback(async (id: string) => {
    setStatus(api.loading);
    const resp = await api.get(`/api/rewards_signup_forms/${id}`);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setRewardsSignupForm(resp.body);
    } else {
      setStatus(api.error);
      setError(resp.body);
    }
  }, []);

  return {
    status,
    error,
    rewardsSignupForm,
    get,
  };
};

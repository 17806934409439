import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { api } from 'lib';
import { useBuysList } from 'api';
import { InfoBar } from 'components';
import { DataGrid } from '@mui/x-data-grid';
import {
  NewSetBuyLinesColumns,
  UsedSetBuyLinesColumns,
} from 'components/table_columns';
import { LegoSetModel } from 'model';

interface Props {
  legoSet: LegoSetModel;
  type: string;
}

export const BuyHistory: React.FC<Props> = ({ legoSet, type }: Props) => {
  const { buysList, listBuysByNewSets, listBuysByUsedSets } = useBuysList();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  React.useEffect(() => {
    if (legoSet.id && type === 'New') {
      listBuysByNewSets(
        legoSet.id,
        paginationModel.page,
        paginationModel.pageSize
      );
    } else {
      listBuysByUsedSets(
        legoSet.id,
        paginationModel.page,
        paginationModel.pageSize
      );
    }
  }, [paginationModel, legoSet, type, listBuysByNewSets, listBuysByUsedSets]);

  return (
    <>
      {buysList.status === api.error && (
        <InfoBar status='error' message={buysList.error} />
      )}
      <Box sx={{ mt: 6 }}>
        <Typography variant='body1'>
          <strong>{type} Set Buy History</strong>
        </Typography>
        <DataGrid
          rows={buysList.buys}
          columns={
            type === 'New' ? NewSetBuyLinesColumns : UsedSetBuyLinesColumns
          }
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            filter: {
              filterModel: {
                items: [
                  {
                    field: 'buy_status',
                    operator: 'contains',
                    value: 'completed',
                  },
                ],
              },
            },
          }}
          loading={buysList.status === api.loading}
          rowCount={buysList.total}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          paginationMode='server'
        />
      </Box>
    </>
  );
};

import * as React from 'react';
import { Grid, TextField } from '@mui/material';

import { CountrySelector, StateSelector } from 'components';
import { Address, CustomerImportModel } from 'model';
import { BuyContext, TBuyContext } from 'contexts';

export const CustomerSection: React.FC = () => {
  const { buy, setCustomer } = React.useContext(BuyContext) as TBuyContext;

  const customer: CustomerImportModel | undefined = React.useMemo(
    () => buy.customer,
    [buy]
  );

  const handleUpdate = (key: string, value: string) => {
    if (customer && customer.id) {
      setCustomer({ ...customer, [key]: value });
    }
  };

  const updateAddress = (key: string, value: string) => {
    if (customer && customer.id) {
      const newAddress: Address = { ...customer.address, [key]: value };
      setCustomer({ ...customer, address: newAddress });
    }
  };

  if (customer) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            label='First Name'
            variant='outlined'
            fullWidth
            size='small'
            inputProps={{ readOnly: buy.status === 'completed' }}
            value={customer.first_name}
            onChange={(e) => handleUpdate('first_name', e.target.value)}
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            label='Last Name'
            variant='outlined'
            size='small'
            fullWidth
            inputProps={{ readOnly: buy.status === 'completed' }}
            value={customer.last_name}
            onChange={(e) => handleUpdate('last_name', e.target.value)}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label='Address'
            variant='outlined'
            size='small'
            fullWidth
            inputProps={{ readOnly: buy.status === 'completed' }}
            value={customer.address?.line_1}
            onChange={(e) => updateAddress('line_1', e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label='Apt/Suite/Unit'
            variant='outlined'
            fullWidth
            size='small'
            value={customer.address?.line_2}
            inputProps={{ readOnly: buy.status === 'completed' }}
            onChange={(e) => updateAddress('line_2', e.target.value)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label='City'
            variant='outlined'
            fullWidth
            size='small'
            value={customer.address?.city}
            inputProps={{ readOnly: buy.status === 'completed' }}
            onChange={(e) => updateAddress('city', e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <StateSelector
            value={customer.address?.state || ''}
            country={
              customer.address?.country ? customer.address.country : 'US'
            }
            onSelect={(v) => updateAddress('state', v)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label='Zip Code'
            variant='outlined'
            size='small'
            fullWidth
            value={customer.address?.postal_code}
            inputProps={{ readOnly: buy.status === 'completed' }}
            onChange={(e) => updateAddress('postal_code', e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <CountrySelector
            value={customer.address?.country ? customer.address.country : 'US'}
            onSelect={(v) => updateAddress('country', v)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Phone'
            variant='outlined'
            fullWidth
            size='small'
            value={customer.phone}
            inputProps={{ readOnly: buy.status === 'completed' }}
            onChange={(e) => handleUpdate('phone', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Email'
            variant='outlined'
            fullWidth
            size='small'
            value={customer.email}
            inputProps={{ readOnly: buy.status === 'completed' }}
            onChange={(e) => handleUpdate('email', e.target.value)}
          />
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
};

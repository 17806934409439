import * as React from 'react';
import { Box, LinearProgress } from '@mui/material';

import { RewardsFormTab } from 'components';
import { SessionContext, TSessionContext, api } from 'lib';

import { useGetHeartland } from 'api';
import { style } from './styles';

export const RewardsSignupFormSettings: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { getHeartland } = useGetHeartland();

  React.useEffect(() => {
    if (currentUser) {
      getHeartland.current();
    }
  }, [getHeartland.current, currentUser]);

  if (getHeartland.status === api.loading) {
    return <LinearProgress />;
  } else if (getHeartland.status === api.success && getHeartland.heartland) {
    return (
      <Box sx={style}>
        <h2>Rewards Signup Form Settings</h2>
        <RewardsFormTab
          rewardsSignupForm={getHeartland.heartland.rewards_signup_form}
          customFields={getHeartland.heartland.customer_custom_fields}
        />
      </Box>
    );
  } else {
    return <></>;
  }
};

import * as React from "react";
import { api } from "lib";
import { CustomerImportModel } from "model";
import { StatusCodes } from "http-status-codes";

export type CustomerInput = {
  first_name: string;
  last_name: string;
  phone: string | null;
  email: string | null;
  heartland_id?: number;
};

interface Props {
  status: string;
  error: string;
  customer: CustomerImportModel | null;
  add: (customer: CustomerInput) => void;
}

export const useAddCustomer = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [customer, setCustomer] = React.useState<CustomerImportModel | null>(null);

  const add = React.useCallback(async (newCustomer: CustomerInput) => {
    setStatus(api.loading);
    const payload = {
      customer: newCustomer,
    };
    const resp = await api.post(`/api/customers/`, payload);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setCustomer(resp.body);
    } else {
      setStatus(api.error);
      setCustomer(null);
      setError(resp.body.error);
    }
  }, []);

  return {
    status,
    error,
    customer,
    add,
  };
};

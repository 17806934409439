import * as React from 'react';
import { Box } from '@mui/material';
import { CMFContext, TCMFContext } from '../context';

import { CMFMyStoreInfo } from 'components';

export const MyStoreInfo: React.FC = () => {
  const { minifig } = React.useContext(CMFContext) as TCMFContext;

  return (
    <Box>
      <Box sx={{ mb: 5, width: '80%', mx: 'auto', textAlign: 'left' }}>
        <CMFMyStoreInfo minifig={minifig} />
      </Box>
    </Box>
  );
};

import * as React from 'react';
import { Chip, Grid } from '@mui/material';

import { MinifigModel } from 'model';

interface Props {
  minifig: MinifigModel;
}

export const MinifigSummary: React.FC<Props> = ({ minifig }: Props) => {
  return (
    <>
      <Grid container alignItems='center' sx={{ mt: 1 }} spacing={1}>
        {minifig.num_parts !== null && (
          <Grid item>
            <Chip
              color='primary'
              variant='outlined'
              label={`${minifig.num_parts} parts`}
            />
          </Grid>
        )}
        {minifig.sets !== null && (
          <Grid item>
            <Chip
              color='primary'
              variant='outlined'
              label={`${minifig.sets.length} set${
                minifig.sets.length > 1 ? 's' : ''
              }`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

import * as React from "react";
import { SessionContext, TSessionContext } from "../../lib";
import { Navigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { UpdateSettingsForm } from "./update_settings_form";

export const UserProfile: React.FC = () => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;

  if (!currentUser) {
    return <Navigate to="/login" />
  }
  return (
    <Grid container spacing={2} alignItems='center' sx={{ width: '90%' }}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 2, fontWeight: 700 }} variant="h6">
          Personal Settings: {currentUser.display_name} ({currentUser.email})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <UpdateSettingsForm />
      </Grid>
    </Grid>
  )
}

import React, { useContext, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormLabel, Box, Grid } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { api, SessionContext, TSessionContext } from "../../lib";
import { formRowStyle as rowStyle } from "./styles";
import { useUpdateUserSetting } from "../../api";


export const UpdateSettingsForm: React.FC = () => {
  const { updatedSetting, updateUserSetting } = useUpdateUserSetting();
  const { getSetting, setFlashMessage } = useContext(SessionContext) as TSessionContext;
  const flags = useFlags();
  const [catalogSearchType, setCatalogSearchType] = useState(getSetting('catalog_search_type') as string || 'sets');

  const handleChange = async (name:string, value:string) => {
    switch (name) {
      case 'catalog_search_type':
        await updateUserSetting(name, value);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (updatedSetting.status === api.success && updatedSetting.setting) {
      let message = '';
      switch (updatedSetting.setting.name) {
        case 'catalog_search_type':
          setCatalogSearchType(updatedSetting.setting.value as string);
          message = 'Catalog search type updated successfully';
          break;
        default:
          break;
      }
      setFlashMessage({
        status: 'info',
        message,
      })
    }
    if (updatedSetting.status === api.error) {
      setFlashMessage({
        message: 'Failed to update setting',
        status: 'error',
      })
    }
  }, [updatedSetting, setFlashMessage]);


  return (
    <Box component="form" sx={rowStyle}>
      <Grid container alignItems={'left'} spacing={2} direction={'row'}>
        <Grid item xs={12} md={3}>
          <FormLabel component="legend">Default catalog search:</FormLabel>
        </Grid>
        <Grid item xs={12} md={9} direction={'row'}>
          <RadioGroup
            aria-label="Catalog Search Type"
            name="catalog-search-type"
            value={catalogSearchType}
            onChange={(_, value) => handleChange('catalog_search_type', value)}
            row
          >
            <FormControlLabel value="minifigs" control={<Radio />} label="Minifigs" />
            <FormControlLabel value="cmf" control={<Radio />} label="CMF" />
            <FormControlLabel value="sets" control={<Radio />} label="Sets" />
            {flags.animalCatalog &&
              <FormControlLabel value="animals" control={<Radio />} label="Animals" />
            }
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

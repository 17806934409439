import * as React from 'react';
import { Button, ButtonGroup, Grid, IconButton, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { MinifigModel, MinifigPriceBand } from 'model';
import { MinifigSearch } from 'components';

interface Props {
  bands: MinifigPriceBand[];
  onAdd: (minifig: MinifigModel | null, band: MinifigPriceBand | null) => void;
  onCancel: () => void;
}

export const MinifigSelector: React.FC<Props> = ({ bands, onAdd, onCancel }: Props) => {

  const [search, setSearch] = React.useState<string>("");

  const updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const selectBand = (band: MinifigPriceBand) => {
    setSearch("");
    onAdd(null, band);
  };

  const selectMinifig = (minifig: MinifigModel) => {
    setSearch("");
    onAdd(minifig, null);
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12} md="auto">
        <h4>Add New:</h4>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField label="Search" variant="outlined" size="small" fullWidth onChange={updateSearch} value={search} />
      </Grid>
      <Grid item xs={12} md="auto">
        <ButtonGroup variant="contained" aria-label="minifig selector">
          {bands.map((band) => band.active ? <Button key={band.id} onClick={() => selectBand(band)}>{band.name}</Button> : <></>)}
        </ButtonGroup>
      </Grid>
      <Grid item xs={1} md={1}>
        <IconButton onClick={onCancel}>
          <CancelIcon />
        </IconButton>
      </Grid>
      {search &&
        <Grid item xs={12}>
          <MinifigSearch query={search} onClick={selectMinifig} maxResults={12} size={80} />
        </Grid>
      }
    </Grid>
  );
};
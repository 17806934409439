import React from "react";

import { Box, Link } from "@mui/material";
import { GridColDef, GridRenderCellParams, DataGrid } from "@mui/x-data-grid";
import LaunchIcon from '@mui/icons-material/Launch';

import { SessionContext, TSessionContext, api } from "lib";
import { HeartlandModel, UnitModel } from "model";

interface HeartlandCellProps {
  heartland: HeartlandModel;
}

const HeartlandCell: React.FC<HeartlandCellProps> = ({ heartland }: HeartlandCellProps) => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  return (
    <Box
      display="flex"
      alignItems="center"
    >
      {isAdmin() ?
        <Link href={`/heartlands/${heartland.id}`} className="externalLink">
          <span>{heartland.domain}</span>
        </Link>
        :
        <span>{heartland.domain}</span>
      }
      <Link href={`https://${heartland.domain}.retail.heartland.us`} target="_heartland" className="externalLink" sx={{ ml: 1 }}>
        <LaunchIcon />
      </Link>
    </Box>
  );
};

const columns: GridColDef[] = [
  {
    field: 'franchise_id',
    headerName: 'ID',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`/units/${params.row.id}`}>
          {params.row.franchise_id}
        </Link>
      </>
    )
  },
  {
    field: 'name',
    headerName: 'Unit Name',
    flex: 2,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`/units/${params.row.id}`}>
          {params.row.name}
        </Link>
      </>
    )
  },
  {
    field: 'heartland',
    headerName: 'Heartland',
    sortable: false,
    flex: 2,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.heartland ?
          <HeartlandCell heartland={params.row.heartland} />
          : "-"
        }
      </>
    )
  },
  {
    field: 'heartlandLocation',
    headerName: 'Primary Heartland Location',
    sortable: false,
    flex: 2.5,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.heartland_location_id}<br />{params.row.heartland_name}
      </>
    )
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1.5,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.city}
      </>
    )
  },
  {
    field: 'state',
    headerName: 'State',
    sortingOrder: ['desc', 'asc'],
    flex: 0.5,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.state}
      </>
    )
  },
  {
    field: 'phone',
    headerName: 'Phone',
    sortable: false,
    flex: 1.25,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.phone}
      </>
    )
  },
];

interface ListProps {
  units: UnitModel[];
}

export const UnitsList: React.FC<ListProps> = ({ units }: ListProps) => {

  return (
    <DataGrid
      rows={units}
      columns={columns}
      rowCount={units.length}
      disableRowSelectionOnClick
    />
  );
};
import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { AnimalModel } from 'model';
import { SessionContext, TSessionContext } from 'lib';
import { RebrickableImage } from 'components';
import { AnimalSummary } from './animal_summary';
import { AnimalLinks } from './animal_links';
import { AnimalBricklinkPrices } from './animal_bricklink_prices';
import { AnimalMyStoreInfo } from './animal_my_store_info';
import { AnimalRecentBuys } from './animal_recent_buys';

interface Props {
  animal: AnimalModel | null;
}

export const AnimalCard: React.FC<Props> = ({ animal }: Props) => {
  const { isHeartlandLive } = React.useContext(
    SessionContext
  ) as TSessionContext;

  if (!animal) {
    return <></>;
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5.5}>
          <Typography
            variant='h6'
            sx={{
              mb: 1,
              textAlign: 'center',
              fontWeight: 700,
            }}
          >
            {animal.bricklink_id} {animal.name}
            {/* <Link href={`/animals/${animal.bricklink_id}`}></Link> TODO: MAKE ANIMAL PAGE && LINK TO IT*/}
          </Typography>
          <AnimalSummary animal={animal} />
          <AnimalLinks animal={animal} displayDetails />
          <RebrickableImage
            imageUrl={animal.image_url}
            imageAlt={animal.name}
          />
        </Grid>
        <Grid item xs={6.5}>
          <AnimalBricklinkPrices animal={animal} />
          {isHeartlandLive() && <AnimalMyStoreInfo animal={animal} />}
          <AnimalRecentBuys animal={animal} />
        </Grid>
      </Grid>
    </Box>
  );
};

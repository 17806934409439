import * as React from 'react';
import { Box, Grid, Paper, Typography, TextField } from '@mui/material';

import { AuditModel, AuditCommentModel } from 'model';
import { formatAbbreviatedTimestamp } from 'lib';
import { useAuditComments } from 'api/audit/comments';

interface AuditCommentProps {
  comment: AuditCommentModel;
}

export const AuditComment: React.FC<AuditCommentProps> = ({ comment }) => {
  return (
    <Box sx={{ pt: 1 }}>
      <em>{formatAbbreviatedTimestamp(comment.created_at)}</em>
      <Box component='span' sx={{ fontWeight: 500, pl: 1 }}>
        {comment.user.first_name} {comment.user.last_name} -{' '}
        {comment.user.user_type}:
      </Box>
      <Box component='span' sx={{ pl: 1 }}>
        {comment.comment}
      </Box>
    </Box>
  );
};

interface Props {
  audit: AuditModel;
}

export const AuditComments: React.FC<Props> = ({ audit }) => {
  const { currentComments, newComment, setNewComment, submitComment } =
    useAuditComments(audit.id, audit.comments);

  const gridRef = React.useRef<HTMLDivElement | null>(null);
  const commentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollTop = gridRef.current.scrollHeight;
    }
  }, [currentComments]);

  React.useEffect(() => {
    commentRef.current?.click();
  }, [currentComments]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitComment();
    }
  };

  const handleSubmitComment = () => {
    submitComment(newComment);
  };

  return (
    <Grid item flex='4'>
      <Paper sx={{ p: 4 }}>
        <Grid>
          <Typography variant='h5' sx={{ mb: 2, fontWeight: 700 }}>
            Comments
          </Typography>
        </Grid>
        <Grid
          flexDirection='column'
          sx={{
            pl: 2,
            maxHeight: '200px',
            overflowY: 'auto',
          }}
          ref={gridRef}
        >
          {currentComments.map((comment: AuditCommentModel) => {
            return <AuditComment key={comment.id} comment={comment} />;
          })}
        </Grid>
        <Grid sx={{ mt: 2 }}>
          <TextField
            id='type-comment'
            label='Add Comment'
            variant='outlined'
            fullWidth
            onKeyDown={handleKeyDown}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            ref={commentRef}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

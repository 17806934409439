import * as React from "react";
import { api } from "lib";
import { UserModel } from "model";
import { StatusCodes } from "http-status-codes";
import { StateSelector } from "components";

interface State {
  status: string;
  error: string;
  users: UserModel[];
  total: number;
}

interface Props {
  usersList: State;
  listByType: (userType: string) => void;
  listArchived: () => void;
  list: (query: string, page: number, pageSize: number) => void;
}

const initialState = {
  status: api.idle,
  error: '',
  users: [],
  total: 0,
};

const loadingState = {
  status: api.idle,
  error: '',
  users: [],
  total: 0,
};

const errorState = (error: string) => (
  {
    status: api.error,
    error: error,
    users: [],
    total: 0,
  }
);

export const useUsersList = (): Props => {
  const [state, setState] = React.useState<State>(initialState);

  const listByType = React.useCallback(async (userType: string) => {
    setState(loadingState);
    const resp = await api.get('/api/users', { user_type: userType, page: "0", size: "0" });
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, users: resp.body.users, total: resp.body.total, error: '' })
    } else {
      setState(errorState(resp.body));
    }
  }, []);

  const listArchived = React.useCallback(async () => {
    setState(loadingState);
    const resp = await api.get('/api/users/archived', { page: "0", size: "0" });
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, users: resp.body.users, total: resp.body.total, error: '' })
    } else {
      setState(errorState(resp.body));
    }
  }, []);

  const list = React.useCallback(async (query: string, page: number, pageSize: number) => {
    setState(loadingState);
    const resp = await api.get('/api/users/search', { query: query, page: page.toString(), size: pageSize.toString() });
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, users: resp.body.users, total: resp.body.total, error: '' })
    } else {
      setState(errorState(resp.body));
    }
  }, []);

  return {
    usersList: state,
    listByType,
    listArchived,
    list,
  };
};


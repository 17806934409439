import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';
import { SessionContext, TSessionContext } from 'lib/session';
import { Box, Link } from '@mui/material';
import { ForgotPasswordModal } from './forgotpassword';
import { ForgotPasswordContext, ForgotPasswordProvider, ForgotPasswordState, TForgotPasswordModalContext } from './forgotpassword_context';
import { InfoBar } from 'components';
import {useStyles} from "./styles";

export const Login = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState<string | null | undefined>("");
  const { login } = React.useContext(SessionContext) as TSessionContext;
  const [authenticated, setAuthenticated] = React.useState(false);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const doLogin = async () => {
    const res = await login(email, password);
    if (res) {
      setAuthenticated(true);
      setErrorMessage(null);
    } else {
      setAuthenticated(false);
      setErrorMessage("Invalid Login, please try again.");
    }
  };

  const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
  const updatePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      doLogin();
    }
  };

  if (authenticated) {
    return (
      <Navigate replace to="/" />
    );
  }
  return (
    <ForgotPasswordProvider>
      <Box sx={{ width: 480 }}>
        <h1 style={{ textAlign: 'center' }}>Welcome to Patron</h1>
        <LoginMessage />
        <div className={classes.box}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={updateEmail}
            onKeyDown={keyPress}
          />
        </div>
        <div className={classes.box}>
          <TextField
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={updatePassword}
            onKeyDown={keyPress}
          />
        </div>
        <div className={classes.error}>
          {errorMessage}
        </div>
        <div className={classes.action}>
          <Button variant="contained" onClick={doLogin} name={"login-button"}>LOGIN</Button>
        </div>
        <ForgotPasswordLink />
        <ForgotPasswordModal />
      </Box>
    </ForgotPasswordProvider>
  );
};

const ForgotPasswordLink: React.FC = () => {
  const classes = useStyles();
  const { changeState } = React.useContext(ForgotPasswordContext) as TForgotPasswordModalContext;
  const showForgotPassword = () => {
    changeState(ForgotPasswordState.request);
  }
  return (
    <div className={classes.links}>
      <Link onClick={showForgotPassword} href="#">Forgot Password</Link>
    </div>
  );
};

const LoginMessage: React.FC = () => {
  const { message } = React.useContext(ForgotPasswordContext) as TForgotPasswordModalContext;

  if (message) {
    return (
      <InfoBar message={message} status="info" />
    )
  } else {
    return (<></>);
  }
};

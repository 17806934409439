import * as React from "react";
import { api } from "lib";
import { MinifigModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  minifigs: MinifigModel[];
  total: number;
  search: (query: string, page?: number, pageSize?: number) => void;
}

export const useMinifigsSearch = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState<string>('');
  const [total, setTotal] = React.useState<number>(0);
  const [minifigs, setMinifigs] = React.useState<MinifigModel[]>([]);

  const search = React.useCallback(async (query: string, page = 0, pageSize = 0) => {
    setStatus(api.loading);
    const resp = await api.get('/api/minifigs/search', { query: query, page: page.toString(), size: pageSize.toString() });
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setMinifigs(resp.body.minifigs);
      setTotal(resp.body.total);
    } else {
      setStatus(api.error);
      setError(resp.body);
    }
  }, []);

  return {
    status,
    error,
    minifigs,
    total,
    search,
  };
};


import * as React from 'react';
import { Chip, Grid } from '@mui/material';
import { AnimalModel } from 'model';

interface Props {
  animal: AnimalModel;
}

export const AnimalSummary: React.FC<Props> = ({ animal }: Props) => {
  return (
    <>
      <Grid container alignItems='center' sx={{ mt: 1 }} spacing={1}>
        {animal.category !== null && (
          <Grid item>
            <Chip
              color='primary'
              variant='outlined'
              label={`${animal.category}`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

import * as React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props {
  value: string;
  onSelect?: (value: string) => void;
}

export const DesirabilitySelector: React.FC<Props> = ({ value, onSelect }: Props) => {

  const handleChange = (event: SelectChangeEvent) => {
    if (onSelect) {
      onSelect(event.target.value);
    }
  };

  return (
    <FormControl sx={{ minWidth: 125 }} size="small">
      <Select
        id="desirability"
        value={value}
        onChange={handleChange}
        sx={{ padding: 0, margin: 0, fontSize: '0.80rem' }}
      >
        <MenuItem value="hot_seller">Hot/Best</MenuItem>
        <MenuItem value="good_seller">Default</MenuItem>
        <MenuItem value="mediocre_seller">Mediocre</MenuItem>
        <MenuItem value="slow_seller">Dust Collector</MenuItem>
        <MenuItem value="not_interested">Not Interested</MenuItem>
      </Select>
    </FormControl>
  );
};

import * as React from 'react';
import { Box, Grid, Switch, TextField } from '@mui/material';
import { CurrencyInput } from 'components';
import { MiscPriceBand } from 'model';

export interface MiscPriceBandEntryProps {
  priceBand: MiscPriceBand;
  updatePriceBand(property: string, value: number | string | boolean): void;
}

export const MiscPriceBandEntry: React.FC<MiscPriceBandEntryProps> = ({ priceBand, updatePriceBand }: MiscPriceBandEntryProps) => {
  const update = (prop: string, value: number | string | boolean) => {
    updatePriceBand(prop, value);
  };

  return (
    <>
      <Grid item xs={4}>
        <TextField
          size="small"
          fullWidth
          disabled={!priceBand.active}
          value={priceBand.name}
          onChange={(e) => update('name', e.target.value)} />
      </Grid>
      <Grid item xs={2}>
        <CurrencyInput
          label="Value"
          fullWidth
          disabled={!priceBand.active}
          value={priceBand.value}
          onChange={(e) => update('value', e.target.value)} />
      </Grid>
      {/* <Grid item xs={2}>
        <CurrencyInput
          label={`Credit (${Math.round(priceBand.credit / priceBand.value * 100)}%)`}
          fullWidth
          disabled={!priceBand.active}
          value={priceBand.credit}
          onChange={(e) => update('credit', e.target.value)} />
      </Grid>
      <Grid item xs={2}>
        <CurrencyInput
          label={`Cash (${Math.round(priceBand.cash / priceBand.value * 100)}%)`}
          fullWidth
          disabled={!priceBand.active}
          value={priceBand.cash}
          onChange={(e) => update('cash', e.target.value)} />

      </Grid> */}
      <Grid item xs="auto">
        <Switch
          checked={priceBand.active}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => update('active', e.target.checked)}
        />
      </Grid>
      <Box sx={{ width: "100%" }} />
    </>
  );
}; 

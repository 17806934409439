import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { useHeartlandsList } from 'api';

interface Props {
  value: number | null;
  onSelect?: (heartland_id: number | null) => void;
}

type HeartlandPreview = {
  id: number;
  domain: string;
};

export const HeartlandAutocomplete: React.FC<Props> = ({ value, onSelect }) => {
  const [heartland, setHeartland] = React.useState<HeartlandPreview | null>(
    null
  );

  const { heartlands, list } = useHeartlandsList();

  React.useEffect(() => {
    list();
  }, [list]);

  const entries = React.useMemo(
    () =>
      heartlands.map((heartland) => ({
        id: heartland.id,
        domain: heartland.domain,
      })),
    [heartlands]
  );

  React.useEffect(() => {
    if (value != null) {
      const selectedHeartland = entries.find((h) => h.id === value);
      if (selectedHeartland) {
        setHeartland(selectedHeartland);
      }
    }
  }, [value, entries]);

  const selectHeartland = (event: any, newValue: HeartlandPreview | null) => {
    setHeartland(newValue);
    if (onSelect) {
      onSelect(newValue ? newValue.id : newValue);
    }
  };

  return (
    <Autocomplete
      options={entries}
      getOptionLabel={(entry) => entry.domain}
      onChange={selectHeartland}
      value={heartland}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label='Heartland' />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

import { Grid } from '@mui/material';
import { BarChart, LineChart } from '@mui/x-charts';
import { useMonthlyBuysList } from 'api';
import { UnitModel } from 'model';
import moment from 'moment';
import * as React from 'react';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

const dateFormatter = (date: Date) => moment(date).format('MMM');

interface Props {
  unit: UnitModel | null;
}

export const MonthlyReport: React.FC<Props> = ({ unit }) => {
  const { monthlyBuysList, getMonthlyBuysList } = useMonthlyBuysList();

  React.useEffect(() => {
    getMonthlyBuysList(unit);
  }, [getMonthlyBuysList, unit]);

  const countsSeries = [
    { dataKey: 'credit', label: '# Store Credit' },
    { dataKey: 'cash', label: '# Cash' },
    { dataKey: 'cancelled', label: 'Cancelled' },
  ];

  const buysCountData = React.useMemo(() => {
    const totals: any[] = [];
    monthlyBuysList.buys.forEach((buy) => {
      totals.push({
        month: buy.month,
        credit: buy.credit_count,
        cash: buy.cash_count,
        cancelled: buy.cancelled,
      });
    });
    return totals;
  }, [monthlyBuysList.buys]);

  const buysPaidData = React.useMemo(() => {
    const months: Date[] = [];
    const credit: number[] = [];
    const cash: number[] = [];
    monthlyBuysList.buys.forEach((buy) => {
      months.push(new Date(buy.year, buy.month - 1, 1));
      credit.push(buy.credit_paid + 0);
      cash.push(buy.cash_paid + 0);
    });
    const data = {
      months,
      series: [
        { label: 'Store Credit', data: credit, showMark: true },
        { label: 'Cash', data: cash, showMark: true },
      ],
    };
    return data;
  }, [monthlyBuysList.buys]);

  console.log(monthlyBuysList.buys);

  return (
    <>
      {monthlyBuysList && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BarChart
              dataset={buysCountData}
              xAxis={[
                {
                  data: buysPaidData.months,
                  scaleType: 'band',
                  valueFormatter: dateFormatter,
                },
              ]}
              series={countsSeries}
              height={300}
            />
          </Grid>
          <Grid item xs={6}>
            <LineChart
              xAxis={[
                {
                  data: buysPaidData.months,
                  scaleType: 'time',
                  valueFormatter: dateFormatter,
                },
              ]}
              series={buysPaidData.series.map((series) => ({
                ...series,
                valueFormatter: (v) => (v === null ? '' : currencyFormatter(v)),
              }))}
              height={300}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

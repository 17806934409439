import * as React from "react";
import { SessionContext, TSessionContext } from "../../lib";
import useStyles from "./styles";
import { Button, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * SettingsMenu component
 * @constructor
 */
export const SettingsMenu: React.FC = () => {
  const { isHeartlandLive } = React.useContext(SessionContext) as TSessionContext;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const heartlandItems = isHeartlandLive()
    ? [
      { label: 'Rewards Signup', path: '/rewards_settings' },
      { label: 'Inventory', path: '/inventory_settings' },
      { label: 'Locations', path: '/units' }
    ]
    : [];

  const menuItems = [
    { label: 'Buy Calculator', path: '/buy_settings/minifigs' },
    ...heartlandItems,
    { label: 'Users', path: '/users' }
  ];
  return (
    <>
      <Button
        className="menuItem"
        id="settings-button"
        aria-controls={open ? 'settings-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Settings <ExpandMoreIcon />
      </Button>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        MenuListProps={{ 'aria-labelledby': 'settings-button' }}
      >
        {menuItems.map((item) =>
          <MenuItem component={"a"} key={item.label} href={item.path} onClick={handleClose}>{item.label}</MenuItem>
        )}
      </Menu>
    </>
  );
}
import * as React from 'react';
import { debounce } from 'lodash';
import { Grid, LinearProgress } from '@mui/material';
import { useAnimalsSearch } from 'api';
import { AnimalCard, CardModal, Pagination, SearchThumbnail } from 'components';
import { api } from 'lib';
import { AnimalModel } from 'model';

interface Props {
  query: string;
  onClick?: (minifig: AnimalModel) => void;
  size?: number;
  maxResults?: number;
}

export const AnimalSearch: React.FC<Props> = (props: Props) => {
  const { animalSearch, doAnimalSearch } = useAnimalsSearch();
  const [page, setPage] = React.useState({ index: 0, total: 0 });
  const [selected, setSelected] = React.useState<AnimalModel | null>(null);
  const handleOpen = (minifig: AnimalModel) => setSelected(minifig);
  const handleClose = () => setSelected(null);
  const maxResults = React.useMemo(
    () => (props.maxResults ? props.maxResults : 18),
    [props.maxResults]
  );

  const doSearch = React.useMemo(
    () => debounce(doAnimalSearch, 500),
    [doAnimalSearch]
  );

  React.useEffect(() => {
    setPage((prevPage) => ({ ...prevPage, index: 0 }));
  }, [props.query]);

  React.useEffect(() => {
    if (props.query.length > 2 && props.query.indexOf(',') < 0) {
      doSearch(props.query, page.index, maxResults);

    }
  }, [props.query, page.index, doSearch, maxResults]);

  // Only update page total when total or maxResults changes
  React.useEffect(() => {
    setPage((prevPage) => ({ ...prevPage, total: Math.ceil(animalSearch.total / maxResults) }));
  }, [animalSearch.total, maxResults]);

  const nextPage = () => {
    const newIndex = page.index + 1;
    setPage({
      index: newIndex > page.total - 1 ? page.total - 1 : newIndex,
      total: page.total,
    });
  };
  const prevPage = () => {
    const newIndex = page.index - 1;
    setPage({ index: newIndex < 0 ? 0 : newIndex, total: page.total });
  };

  if (animalSearch.status === api.loading) {
    return (
      <div style={{ margin: '32px 0' }}>
        <LinearProgress />
      </div>
    );
  } else if (animalSearch.status === api.success) {
    return (
      <>
        <Grid container alignItems='center' spacing={1}>
          <Pagination
            page={page}
            total={animalSearch.total}
            prevPage={prevPage}
            nextPage={nextPage}
            showTotal={true}
          />
          {animalSearch.animals &&
            animalSearch.animals.map((animal) => (
              <Grid item xs={12} md={2} key={animal.id}>
                <div onClick={() => handleOpen(animal)}>
                  <SearchThumbnail
                    imageUrl={animal.image_url}
                    imageTitle={animal.name}
                    payload={animal}
                    onClick={props.onClick}
                    height={props.size ? props.size : 120}
                    width={props.size ? props.size : 120}
                  >
                    {animal.bricklink_id} - {animal.name}
                  </SearchThumbnail>
                </div>
              </Grid>
            ))}
        </Grid>
        <Grid container alignItems='center' spacing={1} sx={{ marginTop: 1 }}>
          <Pagination
            page={page}
            total={animalSearch.total}
            prevPage={prevPage}
            nextPage={nextPage}
            showTotal={true}
          />
        </Grid>
        <CardModal open={selected !== null} onClose={handleClose}>
          <AnimalCard animal={selected} />
        </CardModal>
      </>
    );
  } else if (animalSearch.status === api.error) {
    return <>{animalSearch.error}</>;
  } else {
    return <></>;
  }
};

import { Typography, Grid } from '@mui/material';
import * as React from 'react';

interface Props {
  headerText: string;
  children: React.ReactNode;
}

export const PreviewLayout: React.FC<Props> = ({ headerText, children }) => {
  return (
    <>
      <Typography sx={{ fontWeight: 700, mb: 3 }}>{headerText}</Typography>
      <Grid
        container
        spacing={2}
        sx={{
          maxHeight: 310,
          overflowY: 'auto',
          p: 1,
        }}
      >
        {children}
      </Grid>
    </>
  );
};

import * as React from 'react';

import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TextField } from '@mui/material';

import { SessionContext, TSessionContext } from 'lib';
import { models } from 'model';

interface Props {
  value?: string;
  onSelect?: (value: string) => void;
}

export const UserTypeSelector: React.FC<Props> = ({ value, onSelect }: Props) => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;

  const [selectedUserType, setSelectedUserType] = React.useState<string>(value ? value : 'user');

  const handleChange = (event: SelectChangeEvent) => setSelectedUserType(event.target.value);

  React.useEffect(() => {
    if (onSelect && selectedUserType) {
      onSelect(selectedUserType);
    }
  }, [selectedUserType, onSelect]);

  if (selectedUserType === "Super Admin") {
    return (
      <TextField id="id" label="ID" variant="outlined" fullWidth value="Super Admin" inputProps={{ readOnly: true }} />
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="user-type-select-label">User Type</InputLabel>
      <Select
        labelId="user-type-select-label"
        value={selectedUserType}
        label="User Type"
        onChange={handleChange}
      >
        <MenuItem value="owner">Owner / Franchisee</MenuItem>
        <MenuItem value="employee">Store Employee</MenuItem>
        {currentUser && models.user.isAdmin(currentUser) &&
          <MenuItem value="admin">Admin / BAM Franchising Staff</MenuItem>
        }
      </Select>
    </FormControl>
  );
};
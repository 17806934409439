import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    margin: 0,
    padding: theme.spacing(1, 3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundColor: '#ffcd00',

    '& .menuActions': {
      marginLeft: 'auto',
    },

    '& .menuInfo': {
      marginLeft: theme.spacing(3),
      fontWeight: 500,
      fontSize: '0.90rem',
      textDecoration: 'none',
      color: '#333333',
    },
    '& .menuItem': {
      marginLeft: theme.spacing(3),
      padding: 0,
      fontWeight: 700,
      fontSize: '0.90rem',
      textDecoration: 'none',
      textTransform: 'none',
      color: '#000000',

      '&:hover': {
        color: '#00a3da',
        textDecoration: 'underline',
      },
      '& .MuiSvgIcon-root': {
        color: '#00a3da',
        marginLeft: theme.spacing(1),
      }

    }
  },
  menu: {
    '& .MuiPaper-root': {
      backgroundColor: '#ffcd00',
    },
  }
}));

export default useStyles;
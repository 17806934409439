import * as React from 'react';

import { useAddCMFImage, useUpdateCMF } from 'api';
import { api } from 'lib';
import { SeriesMinifigModel } from 'model';

interface State {
  minifig: SeriesMinifigModel;
  updateStatus: { status: string; msg: string };
}

export type TCMFContext = State & {
  update: (key: string, value: string | number | boolean) => void;
  save: () => void;
  imgUpdate: (url: string, minifigId: number, type: string) => void;
};

interface CMFContextProps {
  children: React.ReactNode;
  minifig: SeriesMinifigModel;
  onSave?: (success: boolean, msg: string) => void;
}

export const CMFContext = React.createContext<TCMFContext | null>(null);

export const CMFProvider: React.FC<CMFContextProps> = (props) => {
  const [state, setState] = React.useState<State>({
    minifig: props.minifig,
    updateStatus: { status: api.idle, msg: '' },
  });
  const {
    status: updateStatus,
    minifig: updatedMinifig,
    message: updateMessage,
    updateCMF,
  } = useUpdateCMF();

  const {
    status: imgStatus,
    image: newImageUrl,
    updateImage,
  } = useAddCMFImage();

  const update = (key: string, value: string | number | boolean) => {
    setState({ ...state, minifig: { ...state.minifig, [key]: value } });
  };

  const save = () => {
    updateCMF(state.minifig);
  };

  React.useEffect(() => {
    if (updateStatus === api.success && updatedMinifig) {
      setState({
        updateStatus: { status: api.success, msg: '' },
        minifig: updatedMinifig,
      });
      if (props.onSave) {
        props.onSave(true, 'Saved');
      }
    } else if (updateStatus === api.error) {
      setState({
        ...state,
        updateStatus: { status: api.error, msg: updateMessage },
      });
      if (props.onSave) {
        props.onSave(false, updateMessage);
      }
    }
  }, [updatedMinifig]);

  const imgUpdate = (newImageUrl: string, id: number, type: string) => {
    setState({
      updateStatus: state.updateStatus,
      minifig: { ...state.minifig, rebrickable_image_url: newImageUrl },
    });

    updateImage(newImageUrl, id, type);
  };

  React.useEffect(() => {
    if (imgStatus === api.success && newImageUrl) {
      setState({
        updateStatus: { status: api.success, msg: '' },
        minifig: { ...state.minifig, rebrickable_image_url: newImageUrl },
      });
      if (props.onSave) {
        props.onSave(true, 'Saved');
      }
    } else if (updateStatus === api.error) {
      setState({
        ...state,
        updateStatus: { status: api.error, msg: updateMessage },
      });
      if (props.onSave) {
        props.onSave(false, updateMessage);
      }
    }
  }, [newImageUrl]);

  return (
    <CMFContext.Provider
      value={{
        minifig: state.minifig,
        updateStatus: state.updateStatus,
        update,
        save,
        imgUpdate,
      }}
    >
      {props.children}
    </CMFContext.Provider>
  );
};

import * as React from 'react';

import { Grid, CircularProgress, Typography, Paper, Table, TableBody, TableContainer, TableRow, TableHead, Box, Button, Link, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { HeartlandModel, LegoSetModel } from 'model';
import { useHeartlandItems } from 'api';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { ImportSetDialog } from 'pages/departments/lego_set/components/import_set_dialog';
import { TCell } from 'components';

interface Props {
  legoSet: LegoSetModel;
  showImport?: boolean;
}

const heartlandItemUrl = (heartland:HeartlandModel, itemId:string):string => {
  return `https://${heartland.domain}.retail.heartland.us/#items/edit/${itemId}`;
};

export const LegoSetMyStoreInfo: React.FC<Props> = (props: Props) => {
  const { currentHeartland, isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const { heartlandItems } = useHeartlandItems();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleCloseImport = (reload: boolean) => {
    setOpen(false);
    if (reload && props.legoSet && currentHeartland) {
      heartlandItems.getItem(currentHeartland.id, props.legoSet);
    }
  };

  React.useEffect(() => {
    if (props.legoSet && currentHeartland) {
      heartlandItems.getItem(currentHeartland.id, props.legoSet);
    }
  }, [props.legoSet, heartlandItems.getItem, currentHeartland]);


  if (!isAdmin() && currentHeartland) {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 2 }}><strong>My Store</strong></Typography>
        {heartlandItems.status === api.loading &&
          <Box sx={{ p: 4 }}><CircularProgress /> Loading Data</Box >
        }
        {heartlandItems.status === api.success &&
          <TableContainer component={Paper} >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TCell>Item #</TCell>
                  <TCell>Description</TCell>
                  <TCell>Department</TCell>
                  <TCell>Category</TCell>
                  <TCell>Def Cost</TCell>
                  <TCell>Current</TCell>
                  <TCell>Avail / On PO</TCell>
                </TableRow>

              </TableHead>
              <TableBody>
                {heartlandItems.items.map((item) => (<TableRow key={item.id}>
                  <TCell><Link target="_blank" href={heartlandItemUrl(currentHeartland, item.id)}>{item.public_id}</Link></TCell>
                  <TCell>{item.description}</TCell>
                  <TCell>{item.custom.department}</TCell>
                  <TCell>{item.custom.category}</TCell>
                  <TCell>{formatCurrency(item.cost)}</TCell>
                  <TCell>{formatCurrency(item.price)}</TCell>
                  <TCell>
                    <Tooltip arrow title={<Box>
                      {item.inventory.map((inv: any) => (
                        <Box key={inv.location_id}>{inv.location_name} - {inv.qty_available} / {inv.qty_on_po}</Box>
                      ))}
                    </Box>
                    }>
                      <Box>{item.total_available} / {item.total_on_po}</Box>
                    </Tooltip>
                  </TCell>
                </TableRow>))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {heartlandItems.status === api.success && heartlandItems.total === 0 &&
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="body2" sx={{ p: 2 }}>
              If you already have this item in Heartland, you can see Heartland Item data here if you add <span style={{ color: '#FF8C00' }}>{props.legoSet.bricklink_id}</span> to the custom field <span style={{ color: '#FF8C00' }}>bricklink_id</span> .
            </Typography>
          </Grid>
        }
        {props.showImport &&
          <Box sx={{ mt: 2 }}>
            <Box sx={{ width: "100%", textAlign: "right", display: open ? 'none' : 'block' }}>
              <Button aria-label="Add" variant="contained" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                Add New Item to Heartland
              </Button>
            </Box>
            <Box sx={{ display: open ? 'block' : 'none' }}>
              <ImportSetDialog legoSet={props.legoSet} onClose={handleCloseImport} />
            </Box>
          </Box>
        }
      </Box>
    );
  } else {
    return (<></>);
  }
};
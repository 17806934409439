import * as React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  value: string;
  label?: string;
  onSelect?: (value: string) => void;
}

export const MinifigConditionSelector: React.FC<Props> = ({
  value,
  onSelect,
  label = '',
}: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    if (onSelect) {
      onSelect(event.target.value);
    }
  };

  return (
    <FormControl fullWidth>
      {label !== '' && (
        <InputLabel id='default-price-select-label'>{label}</InputLabel>
      )}
      <Select
        labelId='default-condition-minifig'
        id='default-condition-minifig'
        value={value}
        onChange={handleChange}
      >
        <MenuItem value='new'>New</MenuItem>
        <MenuItem value='used'>Used</MenuItem>
      </Select>
    </FormControl>
  );
};

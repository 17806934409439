import * as React from "react";
import { api } from "lib";
import { AnimalModel, LegoSetModel, MinifigModel, SeriesMinifigModel } from "model";
import { StatusCodes } from "http-status-codes";

interface State {
  status: string;
  error: string;
  items: Record<string, any[]>;
}

interface Props {
  multipleHeartlandItems: (State & 
    {
      get: (heartland_id: number, item: (LegoSetModel | AnimalModel | MinifigModel | SeriesMinifigModel)[]) => void; 
    }
  )
}

const defaultState = {
  status: api.idle,
  error: '',
  items: {},
};

export const useMultipleHeartlandItems = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const get = React.useCallback(async (heartlandId: number, items: (AnimalModel | LegoSetModel | MinifigModel | SeriesMinifigModel)[]) => {
    setState({ status: api.loading, items: {}, error: ''});
    const bricklinkIds = items.map((item) => item.bricklink_id);
    const payload = { heartland_id: heartlandId.toString(), items: bricklinkIds};
    const resp = await api.post('/api/lego_sets/heartland_items', payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, error: '', items: resp.body});
    } else {
      setState({ status: api.error, error: resp.body, items: {} });
    }
  }, []);

  return {
    multipleHeartlandItems: {
      ...state,
      get,
    }
  };
};


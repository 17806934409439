import * as React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Alert, Box, CircularProgress, Grid, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useCustomersList, useSyncCustomer } from 'api/customer';
import { InfoBar } from 'components';
import { api, SessionContext, TSessionContext } from 'lib';
import { CustomerModel } from 'model';

interface ExteralIdProps {
  customer: CustomerModel;
}
const ExternalId: React.FC<ExteralIdProps> = (props: ExteralIdProps) => {
  const [id, setId] = React.useState(props.customer.external_id);
  const { doSyncCustomer, resetSyncCustomer, syncCustomer } = useSyncCustomer();
  const { isHeartlandLive } = React.useContext(SessionContext) as TSessionContext;

  const handleClick = () => {
    if (props.customer.id) {
      doSyncCustomer(props.customer.id);
    }
  };

  React.useEffect(() => {
    if (syncCustomer.status === api.success && syncCustomer.customer) {
      setId(syncCustomer.customer.external_id);
      resetSyncCustomer();
    }
  }, [syncCustomer]);

  if (syncCustomer.status === api.loading) {
    return (
      <CircularProgress />
    );
  } else if (id) {
    return (
      <>{id}</>
    );
  } else if (isHeartlandLive()) {
    return (
      <Tooltip title="Sync with Heartland">
        <IconButton onClick={handleClick} aria-label="Sync with Heartland">
          <SyncIcon />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (<>N/A</>);
  }
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`customers/${params.row.id}`}>
          {params.row.id}
        </Link>
      </>
    )
  },
  {
    field: 'external_id',
    headerName: 'Heartland ID',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <ExternalId customer={params.row} />
      </>
    )
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`customers/${params.row.id}`}>
          {params.row.first_name}
        </Link>
      </>
    )
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`customers/${params.row.id}`}>
          {params.row.last_name}
        </Link>
      </>
    )
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`customers/${params.row.id}`}>
          {params.row.email}
        </Link>
      </>
    )
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`customers/${params.row.id}`}>
          {params.row.phone}
        </Link>
      </>
    )
  },
];

interface ListProps {
  query: string;
}

const List: React.FC<ListProps> = ({ query }: ListProps) => {
  const { customers, status, error, total, list } = useCustomersList();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  React.useEffect(() => {
    list(query, paginationModel.page, paginationModel.pageSize);
  }, [paginationModel, list, query]);

  if (status === api.error) {
    return (<InfoBar status="error" message={error} />);
  }
  return (
    <DataGrid
      rows={customers}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      loading={status === api.loading}
      rowCount={total}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      paginationMode="server"
    />
  );
};

export const CustomerList: React.FC = () => {
  const [query, setQuery] = React.useState<string>("");

  return (
    <Box sx={{ width: '80%', pt: 2 }}>
      <Typography variant="h5">Customers</Typography>
      <Alert severity="info" sx={{my: 2}}>
        Note: Patron currently does not show all of your Customers here, only ones you've done a buy transaction with or anyone who has signed up via the Rewards Sign up Form
      </Alert>
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <TextField name="query" label="Search" fullWidth onChange={(e) => setQuery(e.target.value)} sx={{mt:1}} />
        </Grid>
      </Grid>
      <List query={query} />
    </Box>
  );
};
import * as React from "react";
import { api } from "lib";
import { UnitModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  unit: UnitModel | null;
  update: (unit: UnitModel) => void;
}

export const useUpdateUnit = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [unit, setUnit] = React.useState<UnitModel | null>(null);

  const update = React.useCallback(async (updatedUnit: UnitModel) => {
    const { id, heartland, owner, ...unitProps } = updatedUnit;
    const payload = {
      unit: { ...unitProps, owner_id: owner.id, heartland_id: heartland ? heartland.id : null },
    };
    const resp = await api.put(`/api/units/${updatedUnit.id}`, payload);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setUnit(resp.body);
    } else {
      setStatus(api.error);
      setUnit(null);
      setError(resp.body.error);
    }
  }, []);

  return {
    status,
    error,
    unit,
    update,
  };
};

import * as React from 'react';
import { Typography, Paper, Table, TableContainer, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { formatCurrency } from 'lib';
import moment from 'moment';

import { LegoSetModel } from 'model';
import { TCell } from 'components';

interface Props {
  legoSet: LegoSetModel;
  maxHeight?: number;
}

const heading = {
  fontWeight: 700,
  fontSize: "0.8em",
  background: '#eeeeee',
};

const condition = (value: string) => {
  switch (value) {
    case "default":
      return "Default";
    case "ready_to_sell":
      return "Ready to sell";
    case "minor_work_needed":
      return "Minor Work Needed";
    case "partial_build":
      return "Partial Build";
    case "unbuilt":
      return "Unbuilt";
    default:
      return "Unknown";
  }
};

export const LegoSetRecentBuys: React.FC<Props> = ({ legoSet, maxHeight = 250 }: Props) => {
  return (
    <>
      <Typography variant="body1" sx={{ mb: 2, fontSize: '0.9rem' }}><strong>BAM Wide Recent Buys</strong></Typography>
      <TableContainer component={Paper} sx={{ maxHeight: maxHeight }}>
        <Table stickyHeader aria-label="Price Guide" size="small">
          <TableHead>
            <TableRow>
              <TCell sx={heading} width={"18%"}>Date</TCell>
              <TCell sx={heading} width={"10%"}>Type</TCell>
              <TCell sx={heading} width={"17%"}>Cond</TCell>
              <TCell sx={heading} width={"35"}>Notes</TCell>
              <TCell sx={heading} width={"10%"} align="right">Value</TCell>
              <TCell sx={heading} width={"10%"} align="right">Adj. Value</TCell>
              <TCell sx={heading} width={"10%"} align="right">Cost</TCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={7} sx={heading}>New In Box</TableCell>
            </TableRow>
            {legoSet.recent_nib_buys.map((line) => (
              <TableRow key={line.id}>
                <TCell>{moment(line.created_at).startOf('day').fromNow()}</TCell>
                <TCell>{line.buy_type}</TCell>
                <TCell>
                  {line.opened ? "open box" : ""}
                  {line.damaged ? "box damage" : ""}
                  {!line.damaged && !line.opened ? "new" : ""}
                </TCell>
                <TCell>
                  {line.notes}
                </TCell>
                <TCell align="right">{formatCurrency(line.value)}</TCell>
                <TCell align="right">{formatCurrency(line.value - line.discount)}</TCell>
                <TCell align="right">{formatCurrency(line.cost)}</TCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={7} sx={heading}>Used Sets</TableCell>
            </TableRow>
            {legoSet.recent_used_buys.map((line) => (
              <TableRow key={line.id}>
                <TCell>{moment(line.created_at).startOf('day').fromNow()}</TCell>
                <TCell>{line.buy_type}</TCell>
                <TCell>{condition(line.complete)}</TCell>
                <TCell>{line.notes}</TCell>
                <TCell align="right">{formatCurrency(line.value)}</TCell>
                <TCell align="right">{formatCurrency(line.value - line.discount)}</TCell>
                <TCell align="right">{formatCurrency(line.cost)}</TCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
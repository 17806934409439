import * as React from "react";
import { api } from "lib";
import { UnitModel } from "model";
import { StatusCodes } from 'http-status-codes';

interface Props {
  status: string;
  error: string;
  units: UnitModel[];
  total: number;
  list: (query?: string, page?: number, pageSize?: number, sort?: string, direction?: string) => void;
}

export const useUnitsList = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');
  const [total, setTotal] = React.useState(0);
  const [units, setUnits] = React.useState<UnitModel[]>([]);

  const list = React.useCallback(async (query = "", page = 0, pageSize = 0, sort = 'name', direction = 'asc') => {
    setStatus(api.loading);
    const options = {
      query: query,
      page: page.toString(),
      size: pageSize.toString(),
      sort: sort,
      direction: direction,
    }
    const resp = await api.get('/api/units/search', options);
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setUnits(resp.body.units);
      setTotal(resp.body.total);
    } else {
      setStatus(api.error);
      setError(resp.body);
    }
  }, []);

  return {
    status,
    error,
    units,
    total,
    list,
  };
};


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    textAlign: 'right',
    margin: theme.spacing(1, 0),
  },
  list: {
    margin: theme.spacing(2, 0),
    textAlign: 'left',
  },
  show: {
    margin: theme.spacing(2, 0),
    textAlign: 'left',
    width: '80%',
  },
  add: {
    margin: theme.spacing(2, 0),
    textAlign: 'left',
    width: '80%',
  },
}));

export default useStyles;
import * as React from "react";
import { api, SessionContext, TSessionContext } from "lib";
import { useNavigate } from "react-router-dom";

interface Props {
  status: string;
  logout: () => void;
}

export const useLogout = (): Props => {
  const navigate = useNavigate();
  const { clearSession } = React.useContext(SessionContext) as TSessionContext;
  const [status, setStatus] = React.useState(api.idle);
  const logout = async () => {
    setStatus(api.loading);
    const resp = await api.logout();
    if (resp) {
      setStatus(api.success);
    } else {
      setStatus(api.error);
    }
    clearSession();
    navigate("/login");
  };

  return {
    status,
    logout,
  };
};

import * as React from "react";
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material"

import { formatCurrency } from "lib";
import { BulkBuyLineModel, BuyModel, MinifigBuyLineModel, SeriesMinifigBuyLineModel, NewSetBuyLineModel, UsedSetBuyLineModel, MiscBuyLineModel, AnimalBuyLineModel } from "model"

interface BuyLineSectionProps {
  buyLineType: string;
  diffLines: [string, number, number][]
}

const BuyLineSection: React.FC<BuyLineSectionProps> = ({ buyLineType, diffLines }) => {
  let prevSectionCost: number = 0;
  let newSectionCost: number = 0;
  let formattedDif: React.ReactNode[] = [];

  diffLines.forEach(([name, prevCost, newCost]) => {
    prevSectionCost += prevCost;
    newSectionCost += newCost
    formattedDif.push(
      <>
        <TableRow>
          <TableCell align="left">
            {name}
          </TableCell>
          <TableCell align="right">
            {formatCurrency(prevCost, true)}
          </TableCell>
          <TableCell align="right">
            {formatCurrency(newCost, true)}
          </TableCell>
        </TableRow>
      </>
    )
  })

  return (
    <Grid item>
      <Paper>
        <Grid
          container
          direction="column"
          justifyContent="center"
        >
          <Grid item sx={{p: 2, fontWeight:700}}>
            {buyLineType}
          </Grid>
          <Grid item>
            <Table
              sx={{tableLayout: "fixed"}}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left"> Name </TableCell>
                  <TableCell align="right"> Previous Cost </TableCell>
                  <TableCell align="right"> New Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formattedDif}
                <TableCell />
                <TableCell align="right" sx={{ fontWeight: 600 }}>
                  {formatCurrency(prevSectionCost, true)}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: 600 }}>
                  {formatCurrency(newSectionCost, true)}
                </TableCell>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

type BuyLine = MinifigBuyLineModel | BulkBuyLineModel | MiscBuyLineModel | SeriesMinifigBuyLineModel | NewSetBuyLineModel | UsedSetBuyLineModel | AnimalBuyLineModel

interface BuyLinesDiffProps {
  prevBuy: BuyModel
  editedBuy: BuyModel
}

export const BuyLinesDiff: React.FC<BuyLinesDiffProps> = ({prevBuy, editedBuy}: BuyLinesDiffProps) => {
  const createDescrip = ( buyLine: BuyLine, buyLineType: string): string => {
    if(buyLineType === "minifig" && "minifig" in buyLine && buyLine.minifig !== undefined) {
      return `${buyLine.minifig.bricklink_id} - ${buyLine.minifig.name}` ;
    }
    else if (buyLineType === "cmf" && "series_minifig" in buyLine) {
      return `${buyLine.series_minifig.name} - ${buyLine.series_minifig.bricklink_id}`;
    }
    else if ((buyLineType === "new-set" || buyLineType === "used-set") && "lego_set" in buyLine){
      return `${buyLine.lego_set.set_number} - ${buyLine.lego_set.name}`;
    }

    if ("description" in buyLine) {
      return buyLine.description;
    }

    return "";
  }

  const createDiff = ( prevBuyLines: BuyLine[], changedBuyLines: BuyLine[], buyLineType: string ): [string, number, number][] => {
    let buyLineDiffs: [string, number, number][] = [];

    for(let i = 0; i < prevBuyLines.length; ++i) {
      buyLineDiffs.push([createDescrip(prevBuyLines[i], buyLineType), prevBuyLines[i].cost_final, changedBuyLines[i].cost_final]);
    }

    return buyLineDiffs;
  }

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={4}
      >
        {
          prevBuy.minifig_buy_lines.length > 0 && 
          <BuyLineSection
            buyLineType="Minifigs"
            diffLines={createDiff(prevBuy.minifig_buy_lines, editedBuy.minifig_buy_lines, "minifigs")}
          />
        }
        {
          prevBuy.series_minifig_buy_lines.length > 0 && 
          <BuyLineSection
            buyLineType="CMF"
            diffLines={createDiff(prevBuy.series_minifig_buy_lines, editedBuy.series_minifig_buy_lines, "cmf")}
          />
        }
        {
          prevBuy.new_set_buy_lines.length > 0 && 
          <BuyLineSection
            buyLineType="New In Box"
            diffLines={createDiff(prevBuy.new_set_buy_lines, editedBuy.new_set_buy_lines, "new-set")}
          />
        }
        {
          prevBuy.used_set_buy_lines.length > 0 && 
          <BuyLineSection
            buyLineType="Used Sets"
            diffLines={createDiff(prevBuy.used_set_buy_lines, editedBuy.used_set_buy_lines, "used-set")}
          />
        }
        {
          prevBuy.animal_buy_lines.length > 0 && 
          <BuyLineSection
            buyLineType="Animals"
            diffLines={createDiff(prevBuy.animal_buy_lines, editedBuy.animal_buy_lines, "animal")}
          />
        }
        {
          prevBuy.bulk_buy_lines.length > 0 && 
          <BuyLineSection
            buyLineType="Bulk"
            diffLines={createDiff(prevBuy.bulk_buy_lines, editedBuy.bulk_buy_lines, "bulk")}
          />
        }
        {
          prevBuy.misc_buy_lines.length > 0 && 
          <BuyLineSection
            buyLineType="Miscellaneous"
            diffLines={createDiff(prevBuy.misc_buy_lines, editedBuy.misc_buy_lines, "misc")}
          />
        }
      </Grid>
    </>
  )
}
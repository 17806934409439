import * as React from 'react';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

import { DailyBuy } from 'model';
import { Button } from '@mui/material';
import { formatCurrency } from 'lib';

const header = [
  "Date",
  "Completed",
  "Cancelled",
  "Cash",
  "Cash Paid",
  "Credit",
  "Credit Paid",
  "New In Box",
  "Used Sets",
  "Minifigs",
  "Bulk",
  "Misc",
];

interface Props {
  list: DailyBuy[];
}

export const BuysCsv: React.FC<Props> = ({ list }: Props) => {
  const csvLink = React.useRef<CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }>(null);

  const importData = React.useMemo(() => {
    const csvData = [];
    csvData.push(header);
    list.forEach((row) => {
      csvData.push([
        `"${row.year}-${row.month}-${row.day}"`,
        row.completed,
        row.cancelled,
        row.cash_count,
        row.cash_paid,
        row.credit_count,
        row.credit_paid,
        row.new_sets,
        row.used_sets,
        row.minifigs,
        row.bulk,
        row.misc,
      ]);
    });
    return csvData;
  }, [list]);

  const doDownload = () => {
    csvLink?.current?.link?.click();
  };

  return (
    <>
      <Button variant="contained" color="primary" startIcon={<DownloadIcon />} onClick={doDownload}>Download CSV</Button>
      <CSVLink ref={csvLink} data={importData} enclosingCharacter="" filename={`patron_daily_buys.csv`} target="_blank" />
    </>
  );

};
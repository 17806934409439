import * as React from 'react';
import { Chip, Grid } from '@mui/material';
import { LegoSetModel } from 'model';

interface Props {
  legoSet: LegoSetModel;
}

export const LegoSetSummary: React.FC<Props> = ({ legoSet }: Props) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{mt:1}}
        spacing={1}
      >
        {legoSet.category !== null && <Grid item>
          <Chip color="primary" variant="outlined" label={`${legoSet.category}`} />
        </Grid>}
        {legoSet.num_parts !== null && <Grid item>
          <Chip color="info" variant="outlined" label={`${legoSet.num_parts} Parts `} />
        </Grid>}
        {legoSet.num_minifigs !== null && <Grid item>
          <Chip color="info" variant="outlined" label={`${legoSet.num_minifigs} Minifigs`} />
        </Grid>}
        {legoSet.release_year !== null && <Grid item>
          <Chip color="info" variant="outlined" label={`Released: ${legoSet.release_year}`} />
        </Grid>}
        {
          legoSet.retired &&
          <Grid item>
            <Chip
              color="warning"
              variant='outlined'
              label={`Retired ${legoSet.retirement_year ? legoSet.retirement_year : ""}`}
            />
          </Grid>
        }
      </Grid>
    </>
  );
};
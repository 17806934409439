import * as React from 'react';

import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Box,
  // Button,
} from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';

import { AnimalModel } from 'model';
import { useHeartlandItems } from 'api';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { TCell } from 'components';

interface Props {
  animal: AnimalModel;
  showImport?: boolean;
}

export const AnimalMyStoreInfo: React.FC<Props> = (props: Props) => {
  const { currentHeartland, isAdmin } = React.useContext(
    SessionContext
  ) as TSessionContext;
  const { heartlandItems } = useHeartlandItems();

  React.useEffect(() => {
    if (props.animal && currentHeartland) {
      heartlandItems.getItem(currentHeartland.id, props.animal);
    }
  }, [props.animal, currentHeartland, heartlandItems.getItem]);

  if (!isAdmin()) {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant='body1' sx={{ mb: 2 }}>
          <strong>My Store</strong>
        </Typography>
        {heartlandItems.status === api.loading && (
          <Box sx={{ p: 4 }}>
            <CircularProgress /> Loading Data
          </Box>
        )}
        {heartlandItems.status === api.success && (
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TCell>Item #</TCell>
                  <TCell>Department</TCell>
                  <TCell>Category</TCell>
                  <TCell>Description</TCell>
                  <TCell>Def Cost</TCell>
                  <TCell>Current</TCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {heartlandItems.items.map((item) => (
                  <TableRow key={item.id}>
                    <TCell>{item.public_id}</TCell>
                    <TCell>{item.custom.department}</TCell>
                    <TCell>{item.custom.category}</TCell>
                    <TCell> {item.description}</TCell>
                    <TCell>{formatCurrency(item.cost)}</TCell>
                    <TCell>{formatCurrency(item.price)}</TCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {heartlandItems.status === api.success && heartlandItems.items.length === 0 && (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant='body2' sx={{ p: 2 }}>
              If you already have this item in Heartland, you can see Heartland
              Item data here if you add{' '}
              <span style={{ color: '#FF8C00' }}>
                {props.animal.bricklink_id}
              </span>{' '}
              to the custom field{' '}
              <span style={{ color: '#FF8C00' }}>bricklink_id</span> .
            </Typography>
          </Grid>
        )}
        {/* {props.showImport && (
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                width: '100%',
                textAlign: 'right',
                display: open ? 'none' : 'block',
              }}
            >
              <Button
                aria-label='Add'
                variant='contained'
                startIcon={<AddIcon />}
                onClick={() => setOpen(true)}
              >
                Add New Item to Heartland
              </Button>
            </Box>
          </Box>
        )} */}
      </Box>
    );
  } else {
    return <></>;
  }
};

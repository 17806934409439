import * as React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';

import { MinifigModel } from 'model';
import { SessionContext, TSessionContext } from 'lib';
import { RebrickableImage, PreviewCard, PreviewLayout } from 'components';
import { formatCurrency } from 'lib';
import { MinifigSummary } from './minifig_summary';
import { MinifigLinks } from './minifig_links';
import { MinifigBricklinkPrices } from './minifig_bricklink_prices';
import { MinifigMyStoreInfo } from './minifig_my_store_info';
import { MinifigRecentBuys } from './minifig_recent_buys';

interface Props {
  minifig: MinifigModel | null;
  detailView?: boolean;
}

export const MinifigCard: React.FC<Props> = ({
  minifig,
  detailView = false,
}: Props) => {
  const { isHeartlandLive } = React.useContext(
    SessionContext
  ) as TSessionContext;
  if (!minifig) {
    return <></>;
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={5.5}>
          <Typography
            variant='h6'
            sx={{
              mb: 1,
              textAlign: 'center',
              fontWeight: 700,
            }}
          >
            <Link
              href={`/minifigs/${minifig.bricklink_id}`}
              sx={{
                display: 'inline-block',
              }}
            >
              {minifig.bricklink_id} {minifig.name}
            </Link>
          </Typography>
          <MinifigSummary minifig={minifig} />
          <MinifigLinks minifig={minifig} displayDetails />
          <RebrickableImage
            imageUrl={minifig.rebrickable_image_url}
            imageAlt={minifig.name}
          />
          {minifig.sets.length > 0 && (
            <PreviewLayout headerText='Associated Sets'>
              {minifig.sets.map((set) => (
                <PreviewCard
                  cardTitle={set.bricklink_id}
                  pricing={`Avg: ${formatCurrency(
                    set.used_bricklink_prices.avg
                  )}; Min: ${formatCurrency(
                    set.used_bricklink_prices.min
                  )}; Max: ${formatCurrency(set.used_bricklink_prices.max)}`}
                  image={set.rebrickable_image_url}
                  imageAlt={set.name}
                  url={`/sets/${set.bricklink_id}`}
                  target='_page'
                />
              ))}
            </PreviewLayout>
          )}
        </Grid>
        <Grid item xs={6.5}>
          <MinifigBricklinkPrices minifig={minifig} />
          {isHeartlandLive() && <MinifigMyStoreInfo minifig={minifig} />}
          <MinifigRecentBuys minifig={minifig} />
        </Grid>
      </Grid>
    </Box>
  );
};

import * as React from 'react';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useUsersList } from "api";
import { UserModel } from 'model';
import { api } from 'lib';

interface Props {
  size?: "small" | "medium" | undefined;
  type?: string;
  value?: UserModel;
  label?: string | boolean;
  variant?: "outlined" | "standard" | "filled" | undefined;
  onSelect?: (user: UserModel) => void;
};

export const UserSelector: React.FC<Props> = ({ type = "owner", size = "medium", variant = undefined, label = "User", value, onSelect }: Props) => {
  const { usersList, listByType } = useUsersList();
  const [selectedUser, setSelectedUser] = React.useState<UserModel | null>(null);

  const handleChange = (event: SelectChangeEvent) => {
    const userId: number = +event.target.value;
    const user = usersList.users.find(s => s.id === userId);
    if (user) {
      setSelectedUser(user);
      if (onSelect) {
        onSelect(user);
      }
    }
  };

  React.useEffect(() => {
    listByType(type);
  }, [listByType, type]);

  React.useEffect(() => {
    if (value) {
      setSelectedUser(value);
    }
  }, [value, setSelectedUser]);

  if (usersList.status === api.error) {
    return (<div>Unable to load user list</div>);
  } else if (usersList.status === api.loading || usersList.status === api.idle) {
    return (<div><CircularProgress /></div>);
  } else if (usersList.status === api.success && usersList.users) {
    return (
      <FormControl fullWidth variant={variant}>
        {label && <InputLabel id="user-select-label">User</InputLabel>}
        <Select
          labelId="user-select-label"
          id="user"
          value={`${selectedUser ? selectedUser.id : 0}`}
          onChange={handleChange}
          size={size}
          label={label ? label : null}
        >
          <MenuItem value={0}>------------------</MenuItem>
          {usersList.users.map((user) =>
            <MenuItem key={user.id} value={`${user.id}`}>{user.display_name}</MenuItem>
          )}
        </Select>
      </FormControl >
    );
  } else {
    return (<></>);
  }
};

import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { AuditType, AuditStatus } from 'model';
import {
  AuditSearchHeader,
  AuditSearchTable,
  AuditSearchParamType,
  AuditSearchParamInputType,
  AuditSearchFilter,
} from './utils';

interface Props {
  resultCount: number;
  inputHeartlandId?: number;
}

const useDefaultSearchState = (
  searchParams: URLSearchParams,
  inputHeartlandId?: number
): AuditSearchFilter => {
  const getNumberParam = (key: string): number | null => {
    const value = searchParams.get(key);
    return value !== null ? Number(value) : null;
  };

  const getEnumParam = <T extends string>(
    key: string,
    defaultValue: T | null = null
  ): T | null => {
    const value = searchParams.get(key);
    return (value as T | null) ?? defaultValue;
  };

  let heartlandId: number | null = getNumberParam('heartland_id');

  return {
    heartland_id: inputHeartlandId
      ? inputHeartlandId
      : heartlandId
        ? heartlandId
        : null,
    audit_type: getEnumParam<AuditType>('audit_type', 'any'),
    audit_status: getEnumParam<AuditStatus>('audit_status', 'unresolved'),
  };
};

export const AuditSearchList: React.FC<Props> = ({
  resultCount,
  inputHeartlandId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFilters, setSearchFilters] = React.useState<AuditSearchFilter>(
    useDefaultSearchState(searchParams, inputHeartlandId)
  );

  const setFilterParams = (
    paramKey: AuditSearchParamType,
    paramValue: AuditSearchParamInputType
  ) => {
    if (paramValue) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(paramKey, String(paramValue));
      newSearchParams.set('pageNum', String(0));
      setSearchParams(newSearchParams);
    } else {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(paramKey);
      newSearchParams.set('pageNum', String(0));
      setSearchParams(newSearchParams);
    }

    setSearchFilters({ ...searchFilters, [paramKey]: paramValue });
  };

  React.useEffect(() => {
    const type = searchParams.get('audit_status');
    if (!type) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('audit_status', 'unresolved');
      setSearchParams(newSearchParams);
      setFilterParams('audit_status', 'unresolved');
    }
  }, []);

  return (
    <Grid container gap={2}>
      <AuditSearchHeader
        displayHeartlandFilter={!inputHeartlandId} // If a heartland id is provided by parent, don't display heartland filter
        filters={searchFilters}
        updateFilter={setFilterParams}
      />
      <AuditSearchTable
        {...searchFilters}
        resultCount={resultCount}
        displayHeartland={!inputHeartlandId}
      />
    </Grid>
  );
};

import * as React from "react";
import { api } from "lib";
import { LegoSetModel } from "model";
import { StatusCodes } from "http-status-codes";

interface Props {
  status: string;
  error: string;
  sets: LegoSetModel[];
  total: number;
  multisearch: (query: string) => void;
}

export const useLegoSetsMultiSearch = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState<string>('');
  const [total, setTotal] = React.useState<number>(0);
  const [sets, setSets] = React.useState<LegoSetModel[]>([]);

  const multisearch = React.useCallback(async (query: string) => {
    setStatus(api.loading);
    const resp = await api.get('/api/lego_sets/multi_search', { query: query });
    if (resp.status === StatusCodes.OK) {
      setStatus(api.success);
      setSets(resp.body.lego_sets);
      setTotal(resp.body.total);
    } else {
      setStatus(api.error);
      setError(resp.body);
    }
  }, []);

  return {
    status,
    error,
    sets,
    total,
    multisearch,
  };
};


import * as React from 'react';

import { Link, Button, Grid, TextField, Typography, Box } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LaunchIcon from '@mui/icons-material/Launch';

import { SessionContext, TSessionContext, api } from 'lib';
import { HeartlandModel } from 'model';
import { HeartlandLocationsList, InfoBar } from 'components';

import useStyles from './styles';
import { useUnitsList } from 'api';

interface HeartlandCellProps {
  heartland: HeartlandModel;
}

const HeartlandCell: React.FC<HeartlandCellProps> = ({ heartland }: HeartlandCellProps) => {
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  return (
    <Box
      display="flex"
      alignItems="center"
    >
      {isAdmin() ?
        <Link href={`/heartlands/${heartland.id}`} className="externalLink">
          <span>{heartland.domain}</span>
        </Link>
        :
        <span>{heartland.domain}</span>
      }
      <Link href={`https://${heartland.domain}.retail.heartland.us`} target="_heartland" className="externalLink" sx={{ ml: 1 }}>
        <LaunchIcon />
      </Link>
    </Box>
  );
};

const columns: GridColDef[] = [
  {
    field: 'franchise_id',
    headerName: 'ID',
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`units/${params.row.id}`}>
          {params.row.franchise_id}
        </Link>
      </>
    )
  },
  {
    field: 'name',
    headerName: 'Unit Name',
    flex: 2,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`units/${params.row.id}`}>
          {params.row.name}
        </Link>
      </>
    )
  },
  {
    field: 'heartland',
    headerName: 'Heartland',
    sortable: false,
    flex: 2,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.heartland ?
          <HeartlandCell heartland={params.row.heartland} />
          : "-"
        }
      </>
    )
  },
  {
    field: 'heartlandLocation',
    headerName: 'Primary Heartland Location',
    sortable: false,
    flex: 2.5,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.heartland_location_id}<br />{params.row.heartland_name}
      </>
    )
  },
  {
    field: 'owner',
    headerName: 'Owner',
    sortable: false,
    flex: 2,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Link href={`users/${params.row.owner.id}`}>
          {params.row.owner.display_name}
        </Link>
      </>
    )
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1.5,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.city}
      </>
    )
  },
  {
    field: 'state',
    headerName: 'State',
    sortingOrder: ['desc', 'asc'],
    flex: 0.5,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.state}
      </>
    )
  },
  {
    field: 'phone',
    headerName: 'Phone',
    sortable: false,
    flex: 1.25,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        {params.row.phone}
      </>
    )
  },
];

interface ListProps {
  query: string;
}

const List: React.FC<ListProps> = ({ query }: ListProps) => {
  const { units, status, total, error, list } = useUnitsList();
  const [sortOptions, setSortOptions] = React.useState<GridSortModel>([
    {
      field: "name",
      sort: "asc" as GridSortDirection,
    },
  ]);
  const [paginationOptions, setPaginationOptions] = React.useState({
    pageSize: 10,
    page: 0,
  });

  React.useEffect(() => {
    if (sortOptions.length !== 1) {
      return;
    }
    list(query, paginationOptions.page, paginationOptions.pageSize, sortOptions[0].field, sortOptions[0].sort?.toString());
  }, [paginationOptions, sortOptions, list, query]);

  if (status === api.error) {
    return (<InfoBar status="error" message={error} />);
  }
  return (
    <DataGrid
      rows={units}
      columns={columns}
      initialState={{
        pagination: { paginationModel: paginationOptions },
        sorting: { sortModel: sortOptions },
      }}
      loading={status !== api.success}
      rowCount={total}
      paginationModel={paginationOptions}
      disableRowSelectionOnClick
      paginationMode="server"
      onPaginationModelChange={setPaginationOptions}
      sortingMode="server"
      onSortModelChange={setSortOptions}
    />
  );
};

export const UnitList: React.FC = () => {
  const { isAdmin, isOwner } = React.useContext(SessionContext) as TSessionContext;
  const classes = useStyles();
  const [query, setQuery] = React.useState<string>("");

  return (
    <Box sx={{ width: '80%' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>{isAdmin() ? 'Units' : 'Patron Locations'}</Typography>
      {isOwner() &&
        <Typography variant="body2" sx={{ mb: 1 }}>
          This is a list of all your physical store locations. If you are a multi-unit owner, you should see all of your locations/units listed here.
        </Typography>
      }
      {isAdmin() &&
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: 1 }}>
          <Grid item xs={10}>
            <TextField name="query" label="Search" fullWidth onChange={(e) => setQuery(e.target.value)} />
          </Grid>
          <Grid item xs={2}>
            <div className={classes.actionBar}>
              <Button variant="contained" startIcon={<AddBusinessIcon />} href="/units/new">New Unit</Button>
            </div>
          </Grid>
        </Grid>
      }
      <List query={query} />
      {isOwner() &&
        <HeartlandLocationsList />
      }
    </Box>
  );
};
import * as React from 'react';
import { Backdrop, Button, CircularProgress, Grid, Link, TextField, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from 'react-router-dom';
import { SessionContext, TSessionContext, api } from 'lib';
import { UserModel } from 'model';
import { ErrorBar, UserSelector } from 'components';

import { HeartlandInput, RewardsSignupFormInput, useAddHeartland } from '../../api/heartland/add';
import useStyles from './styles';

const initialHeartland = {
  domain: '',
  api_key: '',
  user: {
    id: 0,
    first_name: '',
    last_name: '',
    display_name: '',
    user_type: 'employee',
    username: '',
    hide_as_employee: false,
    email: '',
    settings: [],
  },
};

const initialRewardsSignupFor = {
  short_code: '',
  message: '',
};

export const HeartlandAdd: React.FC = () => {
  const navigate = useNavigate();
  const { isAdmin } = React.useContext(SessionContext) as TSessionContext;
  const { heartland: newHeartland, status, error, add } = useAddHeartland();
  const [heartland, setHeartland] = React.useState<HeartlandInput>(initialHeartland);
  const [rewardsSignupForm, setRewaradsSignupForm] = React.useState<RewardsSignupFormInput>(initialRewardsSignupFor);
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const updateDomain = (event: React.ChangeEvent<HTMLInputElement>) => setHeartland({ ...heartland, domain: event.target.value });
  const updateApiKey = (event: React.ChangeEvent<HTMLInputElement>) => setHeartland({ ...heartland, api_key: event.target.value });
  const updateRewardsSignupForm = (prop: string, value: string) => setRewaradsSignupForm({ ...rewardsSignupForm, [prop]: value });
  const updateUser = (value: UserModel) => setHeartland({ ...heartland, user: value });

  const doAdd = () => {
    setOpen(true);
    add(heartland, rewardsSignupForm);
  };

  React.useEffect(() => {
    if (status !== api.loading) {
      setOpen(false);
    }
  }, [status]);

  if (status === api.success && newHeartland) {
    navigate(`/heartlands/${newHeartland.id}`, { state: { status: 'info', message: 'Heartland Instance sucessfully created.' } });
  }
  return (
    <div className={classes.add}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h2> <Link href="/heartlands" className="nav">Heartlands</Link> :: New Heartland</h2>
        </Grid>
        <Grid item xs={12} md={12}>
          {status === api.error && <ErrorBar error={error} />}
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={12}>
              {isAdmin() &&
                <>
                  <div className="input">
                    <UserSelector onSelect={updateUser} />
                  </div>
                </>
              }
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="domain"
                label="Domain"
                variant="outlined"
                fullWidth
                value={heartland ? heartland.domain : ""}
                onChange={updateDomain}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="api_key"
                label="API Key"
                variant="outlined"
                fullWidth
                value={heartland ? heartland.api_key : ""}
                onChange={updateApiKey}
              />
            </Grid>
            <Grid item xs="auto">
              <h4>
                Rewards Signup Form
              </h4>
            </Grid>
            <Grid item>
              <Grid item xs={1} md={1}>
                <Tooltip title="Enter a unique short code for this instance's customizable rewards sign up page">
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="rewards_short_code"
                label="Short Code"
                variant="outlined"
                fullWidth
                value={rewardsSignupForm.short_code}
                onChange={(e) => updateRewardsSignupForm('short_code', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Add Instance"
                startIcon={<LocalOfferIcon />}
                onClick={doAdd}
                fullWidth
              >
                Add Instance
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                aria-label="Cancel"
                startIcon={<CancelIcon />}
                href="/heartlands"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

import * as React from 'react';
import { Box } from '@mui/material';

import { MinifigBricklinkPrices, MinifigRecentBuys } from 'components';
import { MinifigContext, TMinifigContext } from '../context';

export const PriceGuide: React.FC = () => {
  const { minifig } = React.useContext(MinifigContext) as TMinifigContext;

  return (
    <Box>
      <Box sx={{ mb: 4, width: '80%', mx: 'auto', textAlign: 'left' }}>
        <MinifigBricklinkPrices minifig={minifig} />
      </Box>
      <Box sx={{ mb: 4, width: '80%', mx: 'auto', textAlign: 'left' }}>
        <MinifigRecentBuys minifig={minifig} />
      </Box>
    </Box>
  );
};

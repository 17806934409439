import * as React from 'react'

import { api } from 'lib'
import { SeriesMinifigModel } from "model"
import { StatusCodes } from 'http-status-codes';

interface Props {
  status: string;
  error: string;
  minifig: SeriesMinifigModel | null;
  getCMF: (id: string) => void;
}

const defaultState = {
  status: api.idle,
  error: "",
  minifig: null
}

export const useGetCMF = (): Props => {
  const [state, setState] = React.useState(defaultState);

  const get = React.useCallback(async (bricklinkId: string) => {
    setState({status: api.loading, error: '', minifig: null});
    const resp = await api.get(`/api/series_minifigs/${bricklinkId}`);
    if(resp.status === StatusCodes.OK) {
      setState({ status: api.success, error: '', minifig: resp.body})
    } else {
      setState({ status: api.error, error: resp.body, minifig: null})
    }
  }, []);

  return {
    status: state.status,
    error: state.error,
    minifig: state.minifig,
    getCMF: get,
  }
}
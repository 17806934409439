import * as React from 'react';
import { api } from 'lib';
import { StatusCodes } from 'http-status-codes';

import { Address } from 'model';

export const initialCustomer = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  email_marketing: false,
  birth_month: '',
  address: {
    line_1: '',
    line_2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
  },
};

export const initialAddress: Address = {
  line_1: '',
  line_2: '',
  city: '',
  state: '',
  postal_code: '',
  country: 'US',
};

export type CustomerInput = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  email_marketing: boolean;
  birth_month: string;
  address: Address;
};

interface Props {
  status: string;
  error: string;
  signup: (customer: CustomerInput, short_code: string) => void;
}

export const useSignup = (): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState('');

  const signup = React.useCallback(
    async (customer: CustomerInput, short_code: string) => {
      setStatus(api.loading);
      const payload = {
        customer: customer,
      };
      const resp = await api.post(`/api/rewards/${short_code}/signup`, payload);
      if (resp.status === StatusCodes.OK) {
        setStatus(api.success);
      } else {
        setStatus(api.error);
        setError(resp.body.message);
      }
    },
    []
  );

  return {
    status,
    error,
    signup,
  };
};

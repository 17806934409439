import * as React from 'react';
import { Box, Button, Grid, Paper, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useLoadBuySettings } from 'api';
import { SessionContext, TSessionContext, api, formatCurrency } from 'lib';
import { Checkbox, InfoBar, PercentageInput } from 'components';
import { BuySettingModel, InfoBarModel, MiscPriceBand } from 'model';
import { useSaveBuySettings } from 'api/buy/save_settings';
import { MiscPriceBandEntry } from './misc_price_band_entry';

export const MiscBuySettingsTab: React.FC = () => {
  const { currentUnit, currentUser } = React.useContext(SessionContext) as TSessionContext;
  const { load, settings: s, status } = useLoadBuySettings();
  const [miscPriceBands, setMiscPriceBands] = React.useState<MiscPriceBand[]>([]);
  const { saveBuySettings } = useSaveBuySettings();
  const [settings, setSettings] = React.useState<BuySettingModel | undefined>();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const [overstockPreview, setOverstockPreview] = React.useState<boolean>(false);
  const clearInfoBar = () => setInfoBar(null);

  React.useEffect(() => {
    if (currentUnit && currentUser) {
      load(currentUnit.id);
    }
  }, [currentUnit, currentUser, load]);

  React.useEffect(() => {
    if (status === api.success && s?.settings) {
      setSettings(s.settings);
      setMiscPriceBands(s.misc_price_bands);
    }
  }, [s, status]);

  React.useEffect(() => {
    if (saveBuySettings.status === api.success && saveBuySettings.settings?.settings) {
      setSettings(saveBuySettings.settings.settings);
    }
  }, [saveBuySettings]);

  const updatePriceBand = (id: number, prop: string, value: string | number | boolean) => {
    const updatedBands = miscPriceBands.map((b) => {
      if (b.id === id) {
        return { ...b, [prop]: value };
      } else {
        return b;
      }
    });
    setMiscPriceBands(updatedBands);
  };

  const updateSetting = (key: string, value: string | number) => {
    if (settings) {
      const newSettings = { ...settings, [key]: +value };
      setSettings(newSettings);
    }
  };

  React.useEffect(() => {
    if (saveBuySettings.status === api.success) {
      setInfoBar({ status: "success", message: 'Settings saved' });
    } else if (saveBuySettings.status === api.error) {
      setInfoBar({ status: "error", message: saveBuySettings.msg });
    } else {
      setInfoBar(null);
    }
  }, [saveBuySettings.status, saveBuySettings.msg, setInfoBar]);

  const resetSettings = () => {
    if (currentUnit) {
      saveBuySettings.resetSettings(currentUnit.id, 'misc');
    }
  };

  const saveSettings = () => {
    if (settings) {
      saveBuySettings.save({ ...s, settings: settings, misc_price_bands: miscPriceBands });
    }
  };

  const offerValues = React.useMemo(() => {
    const value = 50;
    const offer = {
      credit: { min: value, max: value },
      cash: { min: value, max: value },
    };
    if (settings) {
      let offerValue = value;
      if (overstockPreview) {
        offerValue = value * (settings.misc_overstock_adj / 100);
      }
      offer.credit.min = offerValue * settings.misc_credit_minimum_adj / 100;
      offer.credit.max = offerValue * settings.misc_credit_value_adj / 100;
      offer.cash.min = offerValue * settings.misc_cash_minimum_adj / 100;
      offer.cash.max = offerValue * settings.misc_cash_value_adj / 100;
    }
    return offer;
  }, [settings, overstockPreview]);

  if (settings) {
    return (
      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="top" spacing={1}>
          {infoBar &&
            <Grid item xs={12} md={12}>
              <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
            </Grid>
          }
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={1.5}>
              <Grid item xs={0.30}>
                <Tooltip title={`Enter the the default amount you are willing to offer for credit and cash for a miscellaneous item.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Maximum percentage to offer</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Credit"
                  fullWidth
                  value={settings.misc_credit_value_adj}
                  onChange={(value) => updateSetting('misc_credit_value_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Cash"
                  fullWidth
                  value={settings.misc_cash_value_adj}
                  onChange={(value) => updateSetting('misc_cash_value_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount from the maximum credit offered, for example, if $2 is being offered for a $10 item, a 50% discount would mean the minimum credit offer would be $1. `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Starting percentage to offer</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Credit"
                  fullWidth
                  value={settings.misc_credit_minimum_adj}
                  onChange={(value) => updateSetting('misc_credit_minimum_adj', value)}
                />
              </Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label="Cash"
                  fullWidth
                  value={settings.misc_cash_minimum_adj}
                  onChange={(value) => updateSetting('misc_cash_minimum_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              <Grid item xs={10} sx={{ background: "#eeeeee", p: 1, mt: 2 }}>
                <Tooltip title={`Create pre-defined price bands for "Allowance" (ie: $5, $10, $15 sets), it's resale value, and the default maximum credit and cash you are willing to offer.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip> Pre-Defined Price Bands
              </Grid>
              <Box width="100%" />
              {miscPriceBands.map((priceBand) =>
                <MiscPriceBandEntry priceBand={priceBand} updatePriceBand={(prop, value) => updatePriceBand(priceBand.id, prop, value)} />
              )}
              <Box width="100%" />
              <Grid item xs={0.30}>
                <Tooltip title={`The percentage to discount for excess stock , enter 0% if you do not want to discount for overstock.`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Overstock</Grid>
              <Grid item xs={2}>
                <PercentageInput
                  label=""
                  fullWidth
                  value={settings.misc_overstock_adj}
                  onChange={(value) => updateSetting('misc_overstock_adj', value)}
                />
              </Grid>
              <Box width="100%" />
              {/* <Grid item xs={0.30}>
                <Tooltip title={`This adjustment does not impact the offer price. You can add an additional adjustment to the final cost set to account for labor, etc if you are importing into the POS `}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>Cost Modifier</Grid>
              <Grid item xs="auto">
                <PercentageInput
                  label="% Adjustment"
                  value={settings.misc_additional_cost_adj}
                  onChange={(value) => updateSetting('misc_additional_cost_adj', value)}
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2} sx={{ pl: 2 }}>
              <Grid item xs={12} md={12}>
                <Button variant="contained" fullWidth onClick={saveSettings}>Save</Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button fullWidth onClick={resetSettings}>Reset to default settings</Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ ml: 4, fontStyle: 'italic' }}>
                  <h4>Assigned Value Preview:</h4>
                  <p><Checkbox checked={overstockPreview} label="Overstock" onChange={(e) => setOverstockPreview(e.target.checked)} /></p>
                  <p>Resale Value: $50</p>
                  <p>Credit: {formatCurrency(offerValues.credit.min, false)} - {formatCurrency(offerValues.credit.max, false)}</p>
                  <p>Cash: {formatCurrency(offerValues.cash.max, false)} - {formatCurrency(offerValues.cash.max, false)}</p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper >
    );
  } else {
    return (<></>);
  }
};
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { SessionProvider } from 'lib';
import {
  CatalogDashboard,
  CustomerAdd,
  CustomerImport,
  CustomerList,
  CustomerShow,
  Dashboard,
  Login,
  NewBuy,
  ShowBuy,
  UnitAdd,
  UnitList,
  UnitShow,
  UserAdd,
  UserList,
  UserShow,
  BuysList,
  AddLocation,
} from 'pages';
import './App.css';
import theme from 'theme';
import { Navigation, ProtectedRoute } from 'components';
import { HeartlandAdd, HeartlandList, HeartlandShow } from 'pages/heartland';
import {
  RewardsSignupFormSettings,
  MinifigBuySettingsTab,
  NibBuySettingsTab,
  UsedBuySettingsTab,
  BulkBuySettingsTab,
  MiscBuySettingsTab,
  GeneralBuySettingsTab,
} from 'pages/settings';
import { RewardsSignup, RedirectToStoreSignup } from 'pages/rewards_signup';
import { LegoSetShow, MinifigShow, CMFShow } from 'pages/departments';
import { BuySettingsTabs } from 'pages/settings/buy_settings_tabs';
import { MonthlyReportsByUnits } from 'pages/reports/monthly_report_by_units';
import { InventorySettings } from 'pages/settings/inventory_settings';
import { BuysReport } from 'pages/reports/buys/buys';
import { UserProfile, UserSecurity } from 'pages/user';
import { FlashMessage } from './components';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AnimalBuySettingsTab } from 'pages/settings/animal_buy_settings_tab';
import { Audit, Audits } from 'pages/audit';

const PatronRoutes = () => {
  return (
    <>
      <Route element={<Navigation />}>
        <Route
          index
          element={
            <ProtectedRoute level='unit'>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path='reports/units'
          element={
            <ProtectedRoute level='admin'>
              <MonthlyReportsByUnits />
            </ProtectedRoute>
          }
        />
        <Route
          path='reports/buys'
          element={
            <ProtectedRoute level='owner'>
              <BuysReport />
            </ProtectedRoute>
          }
        />
        <Route
          path='units'
          element={
            <ProtectedRoute level='owner'>
              <UnitList />
            </ProtectedRoute>
          }
        />
        <Route
          path='units/:unit_id'
          element={
            <ProtectedRoute level='owner'>
              <UnitShow />
            </ProtectedRoute>
          }
        />
        <Route
          path='units/new'
          element={
            <ProtectedRoute level='admin'>
              <UnitAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path='customers'
          element={
            <ProtectedRoute level='user'>
              <CustomerList />
            </ProtectedRoute>
          }
        />
        <Route
          path='customers/:customer_id'
          element={
            <ProtectedRoute level='user'>
              <CustomerShow />
            </ProtectedRoute>
          }
        />
        <Route
          path='customers/import'
          element={
            <ProtectedRoute level='user'>
              <CustomerImport />
            </ProtectedRoute>
          }
        />
        <Route
          path='customers/new'
          element={
            <ProtectedRoute level='user'>
              <CustomerAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path='heartlands/new'
          element={
            <ProtectedRoute level='admin'>
              <HeartlandAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path='heartlands'
          element={
            <ProtectedRoute level='admin'>
              <HeartlandList />
            </ProtectedRoute>
          }
        />
        <Route
          path='heartlands/:heartland_id'
          element={
            <ProtectedRoute level='admin'>
              <HeartlandShow />
            </ProtectedRoute>
          }
        />
        <Route
          path='sets/:bricklink_id'
          element={
            <ProtectedRoute level='user'>
              <LegoSetShow />
            </ProtectedRoute>
          }
        />
        <Route
          path='minifigs/:bricklink_id'
          element={
            <ProtectedRoute level='user'>
              <MinifigShow />
            </ProtectedRoute>
          }
        />
        <Route
          path='cmf/:bricklink_id'
          element={
            <ProtectedRoute level='user'>
              <CMFShow />
            </ProtectedRoute>
          }
        />
        <Route path='login' element={<Login />} />
        <Route
          path='buy_settings/general'
          element={
            <ProtectedRoute level='owner'>
              <BuySettingsTabs>
                <GeneralBuySettingsTab />
              </BuySettingsTabs>
            </ProtectedRoute>
          }
        />
        <Route
          path='buy_settings/minifigs'
          element={
            <ProtectedRoute level='owner'>
              <BuySettingsTabs>
                <MinifigBuySettingsTab />
              </BuySettingsTabs>
            </ProtectedRoute>
          }
        />
        <Route
          path='buy_settings/nib'
          element={
            <ProtectedRoute level='owner'>
              <BuySettingsTabs>
                <NibBuySettingsTab />
              </BuySettingsTabs>
            </ProtectedRoute>
          }
        />
        <Route
          path='buy_settings/used'
          element={
            <ProtectedRoute level='owner'>
              <BuySettingsTabs>
                <UsedBuySettingsTab />
              </BuySettingsTabs>
            </ProtectedRoute>
          }
        />
        <Route
          path='buy_settings/animals'
          element={
            <ProtectedRoute level='owner'>
              <BuySettingsTabs>
                <AnimalBuySettingsTab />
              </BuySettingsTabs>
            </ProtectedRoute>
          }
        />
        <Route
          path='buy_settings/bulk'
          element={
            <ProtectedRoute level='owner'>
              <BuySettingsTabs>
                <BulkBuySettingsTab />
              </BuySettingsTabs>
            </ProtectedRoute>
          }
        />
        <Route
          path='buy_settings/misc'
          element={
            <ProtectedRoute level='owner'>
              <BuySettingsTabs>
                <MiscBuySettingsTab />
              </BuySettingsTabs>
            </ProtectedRoute>
          }
        />
        <Route
          path='inventory_settings'
          element={
            <ProtectedRoute level='owner'>
              <InventorySettings />
            </ProtectedRoute>
          }
        />
        <Route
          path='rewards_settings'
          element={
            <ProtectedRoute level='owner'>
              <RewardsSignupFormSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path='buys/new'
          element={
            <ProtectedRoute level='user'>
              <NewBuy />
            </ProtectedRoute>
          }
        />
        <Route
          path='buys/:buy_id'
          element={
            <ProtectedRoute level='user'>
              <ShowBuy />
            </ProtectedRoute>
          }
        />
        <Route
          path='buys'
          element={
            <ProtectedRoute level='user'>
              <BuysList />
            </ProtectedRoute>
          }
        />
        <Route
          path='catalog'
          element={
            <ProtectedRoute level='user'>
              <CatalogDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path='users'
          element={
            <ProtectedRoute level='user'>
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path='users/new'
          element={
            <ProtectedRoute level='owner'>
              <UserAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path='users/archived'
          element={
            <ProtectedRoute level='owner'>
              <UserList archived />
            </ProtectedRoute>
          }
        />
        <Route
          path='users/:user_id'
          element={
            <ProtectedRoute level='user'>
              <UserShow />
            </ProtectedRoute>
          }
        />
        <Route
          path='user/profile'
          element={
            <ProtectedRoute level='user'>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path='user/security'
          element={
            <ProtectedRoute level='user'>
              <UserSecurity />
            </ProtectedRoute>
          }
        />
        <Route
          path='audits/:id'
          element={
            <ProtectedRoute level='owner'>
              <Audit />
            </ProtectedRoute>
          }
        />
        <Route
          path='audits'
          element={
            <ProtectedRoute level='admin'>
              <Audits />
            </ProtectedRoute>
          }
        />
        <Route
          path='onboard/add-location'
          element={
            <ProtectedRoute level='admin'>
              <AddLocation />
            </ProtectedRoute>
          }
        />
      </Route>
    </>
  );
};

const RedirectToBam = () => {
  window.location.replace('https://bricksandminifigs.com');
  return <></>;
};

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SessionProvider>
          <FlashMessage />
          <Routes>
            <Route path='rewards' element={<RedirectToStoreSignup />} />
            <Route path='rewards/:id' element={<RewardsSignup />} />
            {process.env.REACT_APP_MODE === 'consumer' ? (
              <Route path='*' element={<RedirectToBam />} />
            ) : (
              PatronRoutes()
            )}
          </Routes>
        </SessionProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_ID || '',
})(App);

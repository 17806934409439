import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { SessionContext, TSessionContext } from 'lib/session';
import { models } from 'model';

interface Props {
  children: React.ReactElement;
  level: string;
}
export const ProtectedRoute: React.FC<Props> = ({ children, level }: Props) => {
  const { currentUser } = React.useContext(SessionContext) as TSessionContext;

  if (!currentUser) {
    return (<Navigate to="/login" />);
  }
  else if (level === "admin" && !models.user.isAdmin(currentUser)) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};
import * as React from 'react'
import { Button } from '@mui/material';

interface ELCProps {
  label: string;
  url: string;
  target: string;
  disabled?: boolean;
}

export const LinkButton: React.FC<ELCProps> = (props) => {
  return (
    <Button
    variant='contained'
    color='primary'
    sx={{
      textTransform: 'none'
    }}
    component="a"
    href={props.url}
    target={props.target}
    disabled={props.disabled}
    >
      {props.label}
    </Button>
    )
}
import * as React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Checkbox,
} from '@mui/material';

import { formatCurrency } from 'lib';
import { BulkBuyLineModel } from 'model';
import { BuyContext, TBuyContext } from 'contexts';

export const BulkSection: React.FC = () => {
  const { buy, selectAllLines, selectBuyLine } = React.useContext(
    BuyContext
  ) as TBuyContext;

  const paid = React.useMemo(
    () =>
      buy.buy_type === 'cash' ? buy.bulk_cash_paid : buy.bulk_credit_paid,
    [buy]
  );
  const margin = React.useMemo(() => paid / buy.total_bulk_value, [buy, paid]);

  const getRows: BulkBuyLineModel[] = React.useMemo(() => {
    return buy.bulk_buy_lines.filter((row) => !row.deleted);
  }, [buy.bulk_buy_lines]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Box sx={{ p: 2, fontWeight: 700, bgcolor: '#f5f5f5' }}>Bulk</Box>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='Bulk'>
        <TableHead>
          <TableRow sx={{ bgcolor: '#f5f5f5' }}>
            <TableCell width={10} sx={{ p: 0 }} align='center'>
              <Checkbox
                checked={buy.all_bulk_selected}
                onChange={() => selectAllLines('bulk')}
              />
            </TableCell>
            <TableCell>Description</TableCell>
            <TableCell align='right'>Location</TableCell>
            <TableCell width={85} align='right'>
              Qty
            </TableCell>
            <TableCell width={90} align='right'>
              Cost
            </TableCell>
            <TableCell width={90} align='right'>
              Value
            </TableCell>
            <TableCell width={90} align='right'>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell sx={{ p: 0.25 }}>
                  <Checkbox
                    checked={row.selected}
                    onChange={() => selectBuyLine(row.id, 'bulk')}
                  />
                </TableCell>
                <TableCell>
                  {row.description}
                  <br />
                  {row.notes}
                </TableCell>
                <TableCell align='right'>
                  {row.location ? row.location : 'N/A'}
                </TableCell>
                <TableCell align='right'>{row.quantity} gal</TableCell>
                <TableCell align='right'>
                  {formatCurrency(row.cost_final)}
                </TableCell>
                <TableCell align='right'>{formatCurrency(row.value)}</TableCell>
                <TableCell align='right'>{formatCurrency(row.value)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align='right' colSpan={3} />
            <TableCell align='right' sx={{ fontWeight: 700 }}>
              {buy.total_bulk_qty} gal
            </TableCell>
            <TableCell align='right' sx={{ fontWeight: 700 }}>
              {formatCurrency(paid)}
            </TableCell>
            <TableCell align='right' />
            <TableCell align='right' sx={{ fontWeight: 700 }}>
              {formatCurrency(buy.total_bulk_value)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

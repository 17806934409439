import * as React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Link,
  Checkbox,
} from '@mui/material';

import { formatCurrency } from 'lib';
import { LegoSetModel, NewSetBuyLineModel } from 'model';
import { CardModal, LegoSetCard } from 'components';
import { BuyContext, TBuyContext } from 'contexts';

export const NewSetSection: React.FC = () => {
  const { buy, selectAllLines, selectBuyLine } = React.useContext(
    BuyContext
  ) as TBuyContext;

  const [selectedSet, setSelectedSet] = React.useState<LegoSetModel | null>(
    null
  );
  const handleCloseCard = () => setSelectedSet(null);
  let total = 0;

  const getRows: NewSetBuyLineModel[] = React.useMemo(() => {
    return buy.new_set_buy_lines
      .filter((row) => !row.deleted)
      .sort((a, b) => (a.lego_set.set_number > b.lego_set.set_number ? 1 : -1));
  }, [buy.new_set_buy_lines]);

  return (
    <TableContainer component={Paper}>
      <Box sx={{ p: 2, fontWeight: 700, bgcolor: '#f5f5f5' }}>New In Box</Box>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='NewSetetures'>
        <TableHead>
          <TableRow sx={{ bgcolor: '#f5f5f5' }}>
            <TableCell width={10} sx={{ p: 0 }} align='center'>
              <Checkbox
                checked={buy.all_new_set_selected}
                onChange={() => selectAllLines('new_set')}
              />
            </TableCell>
            <TableCell width={'40%'}>Description</TableCell>
            <TableCell align='right'>Damaged</TableCell>
            <TableCell align='right'>Opened</TableCell>
            <TableCell width={120} align='right'>
              Category
            </TableCell>
            <TableCell width={100} align='right'>
              Location
            </TableCell>
            <TableCell width={85} align='right'>
              Qty
            </TableCell>
            <TableCell width={90} align='right'>
              Cost
            </TableCell>
            <TableCell width={90} align='right'>
              Value
            </TableCell>
            <TableCell width={90} align='right'>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => {
            const adjValue = row.value - row.value_discount;
            total += row.cost_final * row.quantity;
            return (
              <TableRow key={row.id}>
                <TableCell sx={{ p: 0.25 }}>
                  <Checkbox
                    checked={row.selected}
                    onChange={() => selectBuyLine(row.id, 'new_set')}
                  />
                </TableCell>
                <TableCell>
                  <Link
                    onClick={() => setSelectedSet(row.lego_set)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {row.lego_set.set_number} {row.lego_set.name}
                  </Link>
                  <br />
                  {row.notes}
                </TableCell>
                <TableCell align='right'>
                  {row.damaged ? 'yes' : 'no'}
                </TableCell>
                <TableCell align='right'>{row.opened ? 'yes' : 'no'}</TableCell>
                <TableCell align='right'>{row.lego_set.category}</TableCell>
                <TableCell align='right'>
                  {row.location ? row.location : 'N/A'}
                </TableCell>
                <TableCell align='right'>{row.quantity}</TableCell>
                <TableCell align='right'>
                  {formatCurrency(row.cost_final)}
                </TableCell>
                <TableCell align='right'>{formatCurrency(adjValue)}</TableCell>
                <TableCell align='right'>
                  {formatCurrency(row.quantity * adjValue)}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align='right' colSpan={6}></TableCell>
            <TableCell align='right' sx={{ fontWeight: 700 }}>
              {buy.total_nib_qty}
            </TableCell>
            <TableCell align='right' sx={{ fontWeight: 700 }}>
              {formatCurrency(total)}
            </TableCell>
            <TableCell align='right'></TableCell>
            <TableCell align='right' sx={{ fontWeight: 700 }}>
              {formatCurrency(buy.total_nib_value)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardModal open={selectedSet !== null} onClose={handleCloseCard}>
        <LegoSetCard legoSet={selectedSet} />
      </CardModal>
    </TableContainer>
  );
};

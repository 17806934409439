import * as React from 'react';
import { api } from 'lib';
import { InventorySettingModel } from 'model';
import { StatusCodes } from 'http-status-codes';

interface State {
  status: string;
  message: string;
  inventorySetting: InventorySettingModel | null;
}

interface Props {
  saveInventorySettings: State & { save: (inventorySetting: InventorySettingModel) => void };
}

const defaultState = {
  status: api.idle,
  message: "",
  inventorySetting: null,
}

export const useSaveInventorySettings = (): Props => {
  const [state, setState] = React.useState<State>(defaultState);

  const save = async (inventorySetting: InventorySettingModel) => {
    setState({ status: api.loading, message: '', inventorySetting: null });
    const payload = {
      inventory_settings: {
        buy_trade_vendor_id: inventorySetting.buy_trade_vendor_id,
        buy_trade_vendor_name: inventorySetting.buy_trade_vendor_name,
        open_box_postfix: inventorySetting.open_box_postfix,
        certified_set_postfix: inventorySetting.certified_set_postfix,
        build_only_set_postfix: inventorySetting.build_only_set_postfix,
        complete_set_postfix: inventorySetting.complete_set_postfix,
        project_set_postfix: inventorySetting.project_set_postfix,
        retired_set_postfix: inventorySetting.retired_set_postfix,
        auto_set_retired: inventorySetting.auto_set_retired,
      }
    };
    const resp = await api.post(`/api/heartlands/${inventorySetting.heartland_id}/inventory_settings`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, message: '', inventorySetting: resp.body });
    } else {
      setState({ status: api.error, message: resp.body, inventorySetting: null });
    }
  };

  return {
    saveInventorySettings: {
      ...state,
      save,
    }
  };
};
import * as React from 'react';
import { Grid } from '@mui/material';

import { LegoSetModel } from 'model';
import {LinkButton} from 'components';

interface Props {
  legoSet: LegoSetModel;
  displayDetails?: boolean;
}

export const LegoSetLinks: React.FC<Props> = ({ legoSet, displayDetails }: Props) => {
  return (
    <>
    <Grid
        container
        alignItems="center"
        sx={{mt:1, mb:2}}
        spacing={1}
      >
      {displayDetails && 
      <Grid item>
        <LinkButton
          url={`/sets/${legoSet.bricklink_id}`}
          target='_pa'
          label='Details'
        />
      </Grid>
      }
      {legoSet.set_number && <Grid item>
        <LinkButton
          url={`https://www.lego.com/en-us/search?q=${legoSet.set_number}`}
          target='_lego'
          label={`Lego: ${legoSet.set_number}`}
        />
      </Grid>}
      {legoSet.bricklink_id && <Grid item>
        <LinkButton
          url={`https://www.bricklink.com/v2/catalog/catalogitem.page?S=${legoSet.bricklink_id}`}
          target='_bl'
          label={`Bricklink: ${legoSet.bricklink_id}`}
        />
      </Grid>}
      {legoSet.rebrickable_id && <Grid item>
        <LinkButton
          url={`https://rebrickable.com/sets/${legoSet.rebrickable_id}`}
          target='_rb'
          label={`Rebrickable: ${legoSet.rebrickable_id}`}
        />
      </Grid>}
      {legoSet.brickowl_id && <Grid item>
        <LinkButton
          url={`https://brickowl.com/catalog/${legoSet.brickowl_id}`}
          target="_bo"
          label={`Brick Owl: ${legoSet.brickowl_id}`}
        />
      </Grid>}
      {legoSet.brickset_id && <Grid item>
        <LinkButton
          url={`https://brickset.com/sets/${legoSet.brickset_id}`}
          target="_bs"
          label={`Brickset: ${legoSet.brickset_id}`}
        />
      </Grid>}
    </Grid>
    </>
  );
};
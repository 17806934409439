import { StatusCodes } from 'http-status-codes';
import { api } from 'lib';
import * as React from 'react';

interface Props {
  status: string;
  message: string;
  requestReset: (email: string) => void;
  resetStatus: () => void;
  setOk: () => void;
}

interface State {
  status: string;
  message: string;
}

export const useRequestReset = (): Props => {
  const [state, setState] = React.useState<State>({ status: api.idle, message: '' });

  const reset = React.useCallback(async (email: string) => {
    const payload = { email: email };
    setState({ status: api.loading, message: '' });
    const resp = await api.post(`/api/auth/forgot`, payload);
    if (resp.status === StatusCodes.OK) {
      setState({ status: api.success, message: '' });
    } else {
      setState({ status: api.error, message: resp.body.error });
    }
  }, []);

  const resetStatus = React.useCallback(() => { setState({ status: api.idle, message: '' }) }, []);
  const setOk = React.useCallback(() => { setState({ status: api.success, message: '' }) }, []);

  return {
    status: state.status,
    message: state.message,
    requestReset: reset,
    resetStatus: resetStatus,
    setOk: setOk,
  };
};